import React, {
  useEffect,
  useState,
  useRef,
} from "react";
import { useRouteMatch, Prompt } from "react-router-dom";

import version from "../../../version/version.json";

import Modal from "../../components/modal";

import activebuildconfig from "../../../configs/activebuildconfig.json";
const { APP_POINTER } = activebuildconfig;

import { useRealTimeDatabase } from "../../hooks/useRealTimeDatabase";

import { asyncGetLessonByIdHash, asyncGetWordBundleIdByModuleId, startLesson } from "../../services/datastore";
import { getCurrentUser } from "../../services/user";

import { useHolodeck } from "./Holodeck";
import { playAllAudioElements } from "./Holodeck/audioHelpers";
import Viewscreen from "./Viewscreen";
import Communicator from "./Communicator";
import CommandStation from "./CommandStation";
import DeflectorShield from "./DeflectorShield/DeflectorShield";
import Engineering from "./Engineering/Engineering";
import WaitingRoom from "./WaitingRoom";
import PresentParticipantList from "./PresentParticipantList";

import "./teach.scss";

/**
 * Parent component for teaching page. Fetches lesson if not passed in, creates
 * Daily room if not there, and teaching page components.
 * 
 * @returns markup for teaching page
 */
const Teach = () =>  {
  // Extract needed Daily functions and values from context
  const {
    asyncJoinCall,
    checkRoomAvailability,
    enableBroadcastForAllParticipants,
    isMuted,
    roomWindowEnded,
    roomWindowStarted,
    setHolodeckLessonId,
    setHolodeckModuleId,
    userWasEjected,
  } = useHolodeck();

  // Ref to use when requesting fullscreen
  const pageRef = useRef(null);

  /*
   * STATE
   */

  // Loading flag
  const [loading, setLoading] = useState(true);

  // Check if page is in fullscreen mode
  const [isFullscreen, setIsFullscreen] = useState(false);
  
  // Lesson object for drill to teach -- either passed from lesson.js, or fetched via lessonId
  const [lesson, setLesson] = useState(null);

  // Module info from lesson. Extracted to not trigger re-join when changed
  const [module, setModule] = useState(null);

  // WordBundle info for module
  const [wordBundleId, setWordBundleId] = useState(null);

  // Reminder Modal for Talkkaround
  const [showReminderModal, setShowReminderModal] = useState(false);
  
  // Store slides.com iframe's concept of what slide we're on
  // Set by listener in Slides, consumed by CommandStation
  const [slideFrameIndex, setSlideFrameIndex] = useState(0);

  // "Engineering" menu visibility (auto-open when instructor first joins)
  const [callScotty, setCallScotty] = useState( true );

  /*
   * HOOKS & CONTEXT
   */
  
  // Get match from router path
  const match = useRouteMatch("/teach/:lessonIdHash");

  // Info for logged-in user
  const userInfo = getCurrentUser();

  // Calling this hook initializes persistent websocket connection checker
  const {
    connectionLost,
    hideConnectionWarning,
  } = useRealTimeDatabase({ lesson });

  /*
   * USE EFFECT
   */

  // On load, fetch and set lesson info
  useEffect(() => {
    // Attempt to fetch lesson using idHash from match.params
    asyncFetchLessonByIdHash(match?.params.lessonIdHash);
  }, []);

  // Once the lesson is loaded, join the room and do setup things
  useEffect(() => {
    // If no lesson, we can't do anything!
    if (!lesson) {
      return;
    }

    // Save lessonId in Holodeck
    setHolodeckLessonId( lesson.id );

    // Get roomInfo from lesson
    const { roomInfo } = lesson;

    // Fire and forget async function to spin up room
    joinCallAndEnableBroadcast(roomInfo, userInfo);
  }, [ lesson ]);

  // Whenever module changes, update moduleId in Holodeck
  useEffect(() => {
    setHolodeckModuleId(module?.id);

    asyncFetchWordBundleByModuleId(module?.id);

    // start a timer for the end of class
    if (lesson) {
      // Compute time between now and the scheduled end of the lesson
      const lessonFinishedMillis = ( (new Date(lesson.lessonScheduledEndUTC)).getTime() );

      // Get current moment in milliseconds
      const nowMillis = (new Date()).getTime();

      // If we're at or past lessonFinishedMillis, turn on the overaly
      if ( nowMillis >= lessonFinishedMillis ) {
        setShowReminderModal( true );
      }
      // If we're before lessonFinishedMillis, set a timer to turn on the overlay when we get to that point in time
      else {
      // Create the timer and remember its id
        const lessonFinishedTimer = setTimeout(
          () => setShowReminderModal( true ),
          lessonFinishedMillis - nowMillis,
        );

        // Return a cleanup function to clear the timer on component unmount
        return function clearFinishedTimer() {
          clearTimeout( lessonFinishedTimer );
        };
      }
    }
  }, [ module ]);

  // When fullscreen state is toggled, adjust appropriately
  useEffect(() => {
    // Helper to check if there is a fullscreen element on the page
    // Checks for standard value, or prefixed values
    const doesPageHaveFullscreenElement = () => (
      !!(document.fullscreenElement
      || document.mozFullScreenElement
      || document.msFullscreenElement
      || document.webkitFullscreenElement)
    );

    // If fullscreen is true, enable fullscreen for page container with ref
    if (isFullscreen) {
      // Confirm that ref attached to containing div of page has a value and can request fullscreen
      if (pageRef.current?.requestFullscreen) {
        pageRef.current.requestFullscreen();
      }
    }
    // If fullscreen is false, exit full screen mode
    else {
      // Check that we have a fullscreen element and an exit fullscreen method, and if so, do it!
      if (doesPageHaveFullscreenElement() && document.exitFullscreen) {
        document.exitFullscreen();
      }
    }

    // Listener for fullscreen change - Keeps state in sync if user exits fullscreen with Escape key or similar
    const keepFullscreenAccurate = () => {
      // Check for a mismatch
      if (isFullscreen !== doesPageHaveFullscreenElement()) {
        // If mismatch is present, fix it!
        setIsFullscreen(doesPageHaveFullscreenElement());
      }
    };

    window.addEventListener("fullscreenchange", keepFullscreenAccurate);

    return function cleanup() {
      window.removeEventListener("fullscreenchange", keepFullscreenAccurate);
    };
  }, [ isFullscreen ]);

  /*
   * HELPERS
   */

  // Fetch lesson information from cloud functions and store in state
  const asyncFetchLessonByIdHash =  async (lessonIdHash) => {
    // Check for existence of lessonIdHash (may be undefined)
    if (!lessonIdHash) {
      // Clear lesson in state
      setLesson(null);
      // And that's it!
      return;
    }

    // If we have a lessonIdHash, attempt to fetch using datastore function
    const fetchedLesson = await asyncGetLessonByIdHash(lessonIdHash);

    const moduleToSet = fetchedLesson.module;

    // To avoid duplicate data & competing sources of truth, remove module info from lesson to set in state
    delete fetchedLesson.module;

    // Set lesson in state
    setLesson(fetchedLesson);

    // Set module in state (NOTE: this must happen after setting lesson in state)
    setModule(moduleToSet);

    // Set lesson start time on database, if not already present
    if (!fetchedLesson?.lessonStartedAtUTC) {
      startLesson(fetchedLesson.id);
    }

    // Check if Daily room is available (between start and end times)
    checkRoomAvailability(fetchedLesson.roomInfo);

    setLoading(false);
  };

  // Fetch wordBundle information from cloud functions and store in state
  const asyncFetchWordBundleByModuleId =  async (moduleId) => {
    // Check for existence of moduleId (may be undefined)
    if (!moduleId) {
      // Clear wordBundleId in state
      setWordBundleId(null);
      // And that's it!
      return;
    }

    // If we have a moduleId, attempt to fetch using datastore function
    const fetchedWordBundle = await asyncGetWordBundleIdByModuleId(moduleId);
    
    // Set wordBundleId in state
    setWordBundleId(fetchedWordBundle?.wordBundleId);
  };

  // Join room, then turn on broadcasting for all students
  const joinCallAndEnableBroadcast = async (roomInfo, userInfo) => {
    // Join the call, then enable broadcast for all students
    await asyncJoinCall({ roomInfo, userInfo });

    // Set all Speaker registrants as broadcast-enabled
    enableBroadcastForAllParticipants();
  };

  // Flip between fullscreen and non-fullscreen states
  const toggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
  };

  // Open and close menu
  const toggleMenu = () => {
    setCallScotty(!callScotty);
  };

  // If the instructor tries to leave page before lesson end time, ask for confirmation
  const confirmLeaveLesson = () => {
    // If user was ejected, let 'em leave without confirmation
    if ( userWasEjected ) return true;
    
    // Get ISO string for this instant 
    const now = new Date().toISOString();

    // Compare to lesson end time
    if (now < lesson.lessonScheduledEndUTC) {
      // If before end time, ask for confirmation
      return "Are you sure you want to leave the Lesson?";
    }

    // If after end time, allow navigation without confirmation
    return true;
  };

  /*
   * RENDER
   */

  // Generate string with version and environment info
  const versionString = `Teach ${version.version}${APP_POINTER !== "PROD" ? ` - ${APP_POINTER}` : ""}`;

  // If loading, render loading component
  if (loading) {
    return (
      <div>Loading...</div>
    );
  }

  // Default return
  return (
    // Confirm that we're within the room window
    (!roomWindowStarted || roomWindowEnded)
      ? (
        <DeflectorShield lessonId={lesson.id} />
      )
    // If loaded and Daily room is available, do the lesson!
      : (
        <div
          className={`teachContainer${isMuted ? " muted" : ""}`}
          onClick={ playAllAudioElements }
          ref={pageRef}
        >
          <div className="teach__port">
            <Communicator
              lesson={lesson}
              toggleMenu={toggleMenu}
            />
            <Viewscreen
              slidesURL={module?.slidesDotComURL}
              setSlideFrameIndex={setSlideFrameIndex}
            />
            <PresentParticipantList />
          </div>
          <div className="teach__starboard">
            <CommandStation
              lesson={lesson}
              module={module}
              slideFrameIndex={slideFrameIndex}
            />
          </div>
          <Engineering
            lesson={lesson}
            module={module}
            setModule={setModule}
            isVisible={callScotty}
            toggleVisibility={toggleMenu}
            isFullscreen={isFullscreen}
            toggleFullscreen={toggleFullscreen}
          />
          <div className="teach__version">
            {versionString}
          </div>
          <Prompt message={confirmLeaveLesson} />
          <WaitingRoom />
          <Modal
            showModal={wordBundleId && showReminderModal}
            modalTextArr={[
              <>
                <strong>Please Read:</strong>
                <br />
                Please play Talkkaround to review what you just learned. Click the orange "Play Talkkaround" button to start. Free students can join too.
              </>,
            ]}
            hideButton={true}
            onXOutFunction={() => {
              setShowReminderModal(false);
            }}
          />
          <Modal
            showModal={ connectionLost }
            modalTextArr={[
              "¡El internet se fue!",
              "Your app can't connect to our servers - check your connection, or try another device",
            ]}
            textColor="red"
            hideButton
            onXOutFunction={ hideConnectionWarning }
          />
        </div>
      )
  );
};

export default Teach;
