import React, {
  useEffect,
  useState,
} from "react";

import { getOneWordBundle } from "../../../../services/datastore";

import "./editWordBundle.css";
import WordBundleEditForm from "./WordBundleEditForm";

/**
 * Fetches bundle with given id, and displays a form to edit it.
 *
 * @param { String } bundleId - of bundle to edit
 */
const EditWordBundle = ({ bundleId }) => {

  /*
   * STATE
   */

  const [ loading, setLoading ] = useState( false );

  const [ bundleInfo, setBundleInfo ] = useState( null );

  /*
   * USE EFFECT
   */

  // On bundleId change, fetch the associated bundle
  useEffect(() => {
    // Fire and forget async function
    getAndSetBundleInfo();
  }, [ bundleId ]);

  /*
   * HELPERS
   */

  // Fetch bundle info when bundleId changes
  const getAndSetBundleInfo = async () => {
    setLoading( true );
    if ( bundleId ) {
      const bundle = await getOneWordBundle( bundleId );
      setBundleInfo( bundle );
    }
    else {
      setBundleInfo( null );
    }
    setLoading( false );
  };

  /*
   * RENDER
   */

  return (
    <section className="manageWordBundles__editWordBundle">
      {// Render loading screen, or bundle edit form
        loading
          ? <div>Loading...</div>
          : (
            <WordBundleEditForm
              bundleInfo={ bundleInfo }
              setBundleInfo={ setBundleInfo }
            />
          )
      }
    </section>
  );
};

export default EditWordBundle;
