import React from "react";
import activebuildconfig from "../../../../configs/activebuildconfig.json";
const { TARGET_ATTENDING_COUNT, TARGET_ATTENDING_MIN } = activebuildconfig;
/**
 * Component to display statistics about the day's previous lessons
 * @param {Array} props.lessons - lesson objects
 */
const PreviousLessonsStats = ({ lessons }) => {
  const numLessons = lessons.length;

  // Count students
  let numAttendingStudents = 0;
  let numAttendingVIPs = 0;
  let numCancellations = 0;
  let numAbsent = 0;
  let numLeftEarly = 0;
  let numDrillsUnderTargetAttendingMin = 0;
  
  lessons.forEach((lesson) => { // Iterate over the lessons in the group
    let numAttendingInLesson = 0;

    // stats for each student
    lesson.students.forEach((student) => { // Iterate over the bookings in each lesson
      if (student.attendance_status.toLowerCase() === "attending") {
        numAttendingStudents += 1;
        numAttendingInLesson += 1;

        if (student.student_type !== "ACTIVE" && student.student_type !== "GUESTVIP") {
          numAttendingVIPs += 1;
        }
      }
      else if (student.attendance_status.toLowerCase() === "cancelled") {
        numCancellations += 1;
      }
      else if (student.attendance_status.toLowerCase() === "absent") {
        numAbsent += 1;
      }
      else if (student.attendance_status.toLowerCase() === "left early") {
        numLeftEarly += 1;
      }
    });

    // stats for each lesson
    if (numAttendingInLesson < TARGET_ATTENDING_MIN) {
      numDrillsUnderTargetAttendingMin += 1;
    } 
  });

  // Compute target attending students
  const targetAttendingStudents = numLessons * TARGET_ATTENDING_COUNT;
  const percentUtilization = targetAttendingStudents ? ((numAttendingStudents / targetAttendingStudents) * 100).toFixed(1) : "-";
  const avgStudentsADrill = (numAttendingStudents / numLessons).toFixed(2);
  
  return (
    <div className="previousLessonsStats">
      <div className="statsItem">{`# Drills: ${numLessons}`}</div>
      <div className="statsItem">{`Avg # Students A Drill: ${avgStudentsADrill}`}</div>
      <div className="statsItem">{`# Drills w/ ${TARGET_ATTENDING_MIN}+ Students: ${numLessons - numDrillsUnderTargetAttendingMin}`}</div>
      <div className="statsItem">{`# Drills w/ <${TARGET_ATTENDING_MIN} Students: ${numDrillsUnderTargetAttendingMin}`}</div>
      <div className="statsItem">{`Drill Utilization: ${percentUtilization}%`}</div>
      <div className="statsItem">{`Target # Attending: ${targetAttendingStudents}`}</div>
      <div className="statsItem">{`Actual # Attending: ${numAttendingStudents}`}</div>
      <div className="statsItem">{`Actual # VIP Attending: ${numAttendingVIPs}`}</div>
      <div className="statsItem">{`Absent: ${numAbsent}`}</div>
      <div className="statsItem">{`Left Early: ${numLeftEarly}`}</div>
      <div className="statsItem">{`Cancelled: ${numCancellations}`}</div>
    </div>
  );
};

export default PreviousLessonsStats;
