import React, { useEffect, useState } from "react";

import { playAllAudioElements } from "../Holodeck/audioHelpers";
import AllAudio from "./Audio";
import MuteButton from "./MuteButton";
import JoinSoundButton from "./joinSoundButton/joinSoundButton"; 
import "./communicator.scss";

/**
 * Renders audio, handles messaging via Daily
 * 
 * @param { Object } lesson - lesson information from database
 * @param { Function } toggleMenu - Setter to toggle visibility of Engineering
 * @returns Visible indicators of lesson status, and audio elements
 */
const Communicator = ({ lesson, toggleMenu }) => {
  
  /*
   * CONSTANT
   */

  // Calculate JS epoch milliseconds for lesson end time
  const lessonEndMillis = lesson?.lessonScheduledEndUTC
    ? new Date(lesson.lessonScheduledEndUTC).getTime()
    : null;
  
  // Global var to hold interval ref, so can cancel from multiple spots
  let tick;

  /*
   * STATE
   */ 
  
  // State for mins and secs left in class
  const [ minutes, setMinutes ] = useState("--");
  const [ seconds, setSeconds ] = useState("--");

  /*
   * USE EFFECT
   */

  // When in the lesson, we want to force users to confirm before reloading page
  useEffect(() => {

    // Attach playAllAudioElements listener to global navigator
    navigator.mediaDevices.addEventListener("devicechange", playAllAudioElements);

    // Start countdown clock to lessonScheduledEndUTC
    tick = setInterval(ticker, 1000);

    // Remove listeners & interval on unmount (when Drill is over)
    return function cleanup() {
      // clearInterval fails silently if tick is already cleared
      clearInterval(tick);
      navigator.mediaDevices.removeEventListener("devicechange", playAllAudioElements);
    };
  }, []);

  /*
   * HELPERS
   */

  // Function fired by tick interval, runs countdown clock
  const ticker = () => {
    // Can't count down if we have nothing to count down to!
    if (!lessonEndMillis) return;

    // This instant, in milliseconds
    const nowMillis = new Date().getTime();

    // How long until lesson end time, in millis
    const remainderMillis = lessonEndMillis - nowMillis;

    // Check if we're past lesson end time
    if (remainderMillis <= 0) {
      // If so, just display 0's
      setMinutes("00");
      setSeconds("00");

      // Clear the interval to stop ticking
      clearInterval(tick);
    
      // Stop execution
      return;
    }

    // If we're before lesson end time, calculate how much before!
    const secsRemaining = Math.floor(remainderMillis / 1000) % 60;
    const minsRemaining = Math.floor(remainderMillis / (1000 * 60));

    // Set two-digit string for seconds remaining in state
    setSeconds(secsRemaining < 10 ? `0${secsRemaining}` : `${secsRemaining}`);

    // Set two-digit string for minutes remaining in state
    setMinutes(minsRemaining < 10 ? `0${minsRemaining}` : `${minsRemaining}`);
  };

  /*
   * RENDER
   */

  return (
    <div className="communicator">

      <AllAudio />
      <div
        className="communicator__menuButton"
        onClick={toggleMenu}
        role="button"
      >
        Menu
      </div>
      <div className={`communicator__clock ${minutes < 5 ? "fiveMinWarning" : ""}`}>
        {minutes}:{seconds}
      </div>
      <JoinSoundButton />
      <MuteButton />
    </div>
  );
};

export default Communicator;
