import React, { Component } from "react";
import TextareaAutosize from "react-textarea-autosize";

import {
  getLessonReportInitialState,
  submitDrillLessonReport,
} from "../../../services/datastore";

import AsyncButton from "../../../components/AsyncButton";

import ClassStudentList from "./ClassStudentList";

import "./classReport.scss";

/**
 * Component to display & submit lesson reports
 * @prop {*} lesson the lesson to submit a report for
 */
class ClassReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true, // This will tell `render()` that we haven't yet loaded data
      loadingError: false,
      inputErrors: [],
      serverError: false,

      // Below is all data needed for the actual lesson report
      lessonId: props.lesson.id,
      // `feedback` section
      comments: "",
      isTechnologyProblem: false,
      isModuleIssue: false,
      isStudentFollowupNeeded: false,
      // Lesson file students array
      lessonStudentsArray: props.lesson.students,
      // Module
      module: null,
      lessonEventTypeCategory: props.lesson.lesson_event_type_category,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleValueChange = this.handleValueChange.bind(this);
    this.submit = this.submit.bind(this);
  }

  async componentDidMount() {
    // Get initial lesson report data from the server
    const data = await getLessonReportInitialState(this.state.lessonId);

    // Explicitly check for failure (not sufficient to just do `if (data.success)`)
    if (data.success !== false) {
      // Update state with the report data
      this.setState(() => {
        return {
          lessonStudentsArray: data.participants,
          module: data.module,
          loading: false, // This will tell `render()` that we're done loading data
          loadingError: false,
        };
      });
    }
    else {
      this.setState({ loading: false, loadingError: true });
    }
  }

  /**
   * Change a field in state that corresponds to a form input field for lesson report contents
   * @param {Event} event - React form input change event with target `name` and `value`
   */
  handleValueChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  /**
   * Called by the AsyncButton used for lesson report submission
   * @returns Promise wrapping the `submit_webdrill_lesson_report` API call
   */
  async submit() {
    // Set server error to false before we submit the report
    this.setState({
      serverError: false,
    });

    // Save input errors here if any are encountered
    const errors = [];

    // Save errors that occurred
    this.setState({
      inputErrors: errors,
    });

    // Throw an error so that the input error list is displayed and the teacher must submit again
    if (errors.length !== 0) {
      throw new Error();
    }

    // Build `feedback` Object
    const feedback = {
      comments: this.state.comments,
      techIssues: "No",
      techIssuesText: "",
      moduleIssues: "No",
      moduleIssuesText: "",
      studentFollowupNeeded: "No",
      studentFollowupNeededText: "",
    };

    if (this.state.isTechnologyProblem) {
      feedback.techIssues = "Yes";
      feedback.techIssuesText = this.state.comments;
    }

    if (this.state.isModuleIssue) {
      feedback.moduleIssues = "Yes";
      feedback.moduleIssuesText = this.state.comments;
    }

    if (this.state.isStudentFollowupNeeded) {
      feedback.studentFollowupNeeded = "Yes";
      feedback.studentFollowupNeededText = this.state.comments;
    }

    // Submit the report and check for server error
    const res = await submitDrillLessonReport(
      this.state.lessonId,
      feedback,
    );

    if (!res.success) {
      this.setState({
        serverError: true,
      });
      throw new Error();
    }

    // Return the result so `AsyncButton` can check for success
    return res;
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  render() {
    return (
      <div className="lessonReportWebDrillModal">
        <div
          className="lessonReportWebDrill__x-out"
          role="button"
          tabIndex={0}
          onClick={() => {
            this.props.exit(); 
          }}
        ><i className="fas fa-times" />
        </div>
        <div className="lessonReportWebDrillModalBody">
          <div className="lessonReportWebDrillModalHeader">Post-Drill Notes</div>
          {this.state.loading && <div>Loading...</div>}
          {!this.state.loading && this.state.loadingError && <div className="lessonReportWebDrillLoadingError">An error occurred, please contact your administrator</div>}
          {!this.state.loading && !this.state.module && !this.state.loadingError && <div className="lessonReportWebDrillNoModule">Please click the &apos;X&apos; in the upper-left corner and select a module</div> }
          {!this.state.loading && (this.state.lessonEventTypeCategory === "General" || this.state.lessonEventTypeCategory === "") && !this.state.loadingError && <div className="lessonReportWebDrillNoModule">Post Drill Notes disabled for Zoom Drills</div> }
          {this.state.loading || !this.state.module || this.state.loadingError || (this.state.lessonEventTypeCategory === "General" || this.state.lessonEventTypeCategory === "") ? null
            : (
              <>
                <div className="lessonReportWebDrillModalSection">
                  <div>
                    <div className="lessonReportWebDrillCheckbox">
                      <input
                        type="checkbox"
                        name="isTechnologyProblem"
                        checked={this.state.isTechnologyProblem}
                        onChange={this.handleInputChange}
                      />Technology Problem
                    </div>
                    <div className="lessonReportWebDrillCheckbox">
                      <input
                        type="checkbox"
                        name="isModuleIssue"
                        checked={this.state.isModuleIssue}
                        onChange={this.handleInputChange}
                      />Module Issue
                    </div>
                    <div className="lessonReportWebDrillCheckbox">
                      <input
                        type="checkbox"
                        name="isStudentFollowupNeeded"
                        checked={this.state.isStudentFollowupNeeded}
                        onChange={this.handleInputChange}
                      />Student needs follow-up
                    </div>
                  </div>
                  <div className="lessonReportWebDrillModalSectionHeader">
                    <div>Comments (check a box above to activate)</div>
                  </div>
                  <TextareaAutosize name="comments" minRows="3" className={`text-area-autosize-WebDrill ${this.state.isTechnologyProblem || this.state.isModuleIssue || this.state.isStudentFollowupNeeded ? "" : "hidden"}`}  type="text" value={this.state.comments} onChange={this.handleValueChange} />
                </div>
                <div className="lessonReportWebDrillStudentList__container">
                  <ClassStudentList
                    students={this.state.lessonStudentsArray}
                  />
                </div>
                <div>
                  {this.state.serverError && <div className="lessonReportWebDrillServerError">An error occurred, please contact your administrator</div>}
                  {this.state.inputErrors.map((error) => {
                    return <div className="lessonReportWebDrillInputError"> {error} </div>;
                  })}
                </div>
                <AsyncButton
                  className="lessonReportWebDrillSubmitButton"
                  initialText="Submit"
                  loadingText="Processing..."
                  completeText="Submitted"
                  disableOnComplete
                  function={this.submit}
                  completeFunction={() => {
                    this.props.history.push("/");
                  }}
                />
              </>
            )}
        </div>
      </div>
    );
  }
}

export default ClassReport;
