import React from "react";

/**
 * Renders fancy hamburger button for menu in mobile view (700px breakpoint)
 *
 * @param {*} props destructured into: open, state var from nav.js that indicates whether the mobile nav is open or closed. toggleMenu, setter to toggle open
 * @returns markup for hamburger
 */
const Hamburger = ({ open, toggleMenu }) => (
  <button type="button" onClick={toggleMenu} className="nav__burger">
    <div className={`${open ? "open" : ""}`} />
    <div className={`${open ? "open" : ""}`} />
    <div className={`${open ? "open" : ""}`} />
  </button>
);

export default Hamburger;
