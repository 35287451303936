import React, { Component } from "react";

/**
 * @param { Function } onXOutFunction - Function to execute when user attempts
 * to close modal
 * @param { Boolean } showModal - Indicating whether or not modal should be
 * visible
 * @param { Array } modalTextArr - Array of paragraphs to display in modal
 * @param { String } textColor [optional] - Color for text in modalTextArr
 * @param { Function } onSubmitFunction - To execute when user clicks modal
 * button
 * @param { Boolean } hideButton - True to hide the button
 * @param { String } modalButtonText - Text to display on button
 */
class Modal extends Component {
  componentDidMount = () => {
    document.addEventListener("keydown", this.keyPressFunc, false);
  };

  keyPressFunc = (event) => {
    if (event.key === "Escape") {
      this.props.onXOutFunction();
    }
  };

  renderModalText = (modalTextArr, textColor) => {
    const modalTextDivArr = [];
    for (let i = 0; i < modalTextArr.length; i += 1) {
      modalTextDivArr.push((
        <div key={i} style={{ color: textColor }}>
          {modalTextArr[i]}
        </div>
      ));
    }
    return modalTextDivArr;
  };

  render() {
    if (this.props.showModal) {
      return (
        <div className="modal"
          onClick={() => {
            this.props.onXOutFunction(); 
          }}
        >
          <div className="modal-body"
            onClick={(event) => {
              event.stopPropagation(); 
            }}
          >
            <div className="x-out"
              onClick={() => {
                this.props.onXOutFunction(); 
              }}
            ><i className="fas fa-times" />
            </div>
            <div className="modal-text">
              <div className="modal-text-body">{this.renderModalText(this.props.modalTextArr, this.props.textColor ? this.props.textColor : "black")}</div>
              {this.props.children}
              {!this.props.hideButton && <button className="submit-button"
                type="submit"
                onClick={() => {
                  this.props.onSubmitFunction(); 
                }}
              > {this.props.modalButtonText}
              </button>}
            </div>
          </div>
        </div>
      );
    }
    else {
      return <div />;
    }
  }
}

export default Modal;
