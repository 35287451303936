import { useEffect, useState } from "react";

/**
 * Adds global listeners for gesture (swipe) events
 * 
 * Adds a basic swiped event, as well as directional events swiped-left,
 * swiped-right, swiped-up, and swiped-down
 * 
 * Based on swiped-events package: https://github.com/john-doherty/swiped-events/
 */
export const enableSwipedEvents = () => {

  const [ xDown, setXDown ] = useState( null );
  const [ yDown, setYDown ] = useState( null );
  const [ xDiff, setXDiff ] = useState( null );
  const [ yDiff, setYDiff ] = useState( null );
  const [ timeDown, setTimeDown ] = useState( null );
  const [ startEl, setStartEl ] = useState( null );

  // On load, polyfill CustomEvent for IE & Chrome and make sure we have handlers attached
  // All state variables are in useEffect to ensure that handlers attached here always have access to the most updated state
  useEffect(() => {

    // If we don't have CustomEvent support, polyfill it
    if (typeof window.CustomEvent !== "function") {

      window.CustomEvent = function (event, params) {

        params = { bubbles: false, cancelable: false, detail: undefined };

        const evt = document.createEvent("CustomEvent");
        // VSCode complains about initCustomEvent, but that's ok -- this polyfill is only executed in environments where this will work
        evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
        return evt;
      };

      window.CustomEvent.prototype = window.Event.prototype;
    }

    // Attach event listeners used to generate custom swipe events
    document.addEventListener("touchstart", handleTouchStart);
    document.addEventListener("touchmove", handleTouchMove);
    document.addEventListener("touchend", handleTouchEnd);

    // Remove listeners on unmount
    return function swipeEventCleanup() {
      document.removeEventListener("touchstart", handleTouchStart);
      document.removeEventListener("touchmove", handleTouchMove);
      document.removeEventListener("touchend", handleTouchEnd);
    };

  }, [
    xDown,
    yDown,
    xDiff,
    yDiff,
    timeDown,
    startEl,
  ]);

  /**
   * Records current location on touchstart event
   * @param {object} e - browser event object
   * @returns {void}
   */
  const handleTouchStart = (e) => {

    // if the element has data-swipe-ignore="true", we stop listening for swipe events
    if (e.target.getAttribute("data-swipe-ignore") === "true") return;

    // At start of touch, set all initial values in state
    setStartEl( e.target );
    setTimeDown( Date.now() );
    setXDown( e.touches[0].clientX );
    setYDown( e.touches[0].clientY );
    setXDiff( 0 );
    setYDiff( 0 );
  };

  /**
   * Records location diff in px on touchmove event
   * @param {object} e - browser event object
   * @returns {void}
   */
  const handleTouchMove = (e) => {

    // If we don't have start values for the swipe, we can't do anything!
    if (!xDown || !yDown) return;

    // Compute difference between start position of swipe and current position
    const xUp = e.touches[0].clientX;
    const yUp = e.touches[0].clientY;

    // Set state with how far we've moved so far
    setXDiff( xDown - xUp );
    setYDiff( yDown - yUp );
  };

  /**
   * Fires swiped event if swipe detected on touchend
   * @param {object} e - browser event object
   * @returns {void}
   */
  const handleTouchEnd = (e) => {

    // If our concept of starting element for the swipe doesn't match the browser's concept of the element related to this event, cancel
    if (startEl !== e.target) return;

    // Check for custom attribute labels that control swipe behavior. See https://github.com/john-doherty/swiped-events/ for more info
    const swipeThreshold = parseInt(getNearestAttribute(startEl, "data-swipe-threshold", "20"), 10); // default 20px
    const swipeTimeout = parseInt(getNearestAttribute(startEl, "data-swipe-timeout", "500"), 10);    // default 500ms

    // How long has the swipe taken?
    const timeDiff = Date.now() - timeDown;

    // What type of swipe is it?
    let eventType = "";

    // Get all individual touch events contained within this swipe
    const changedTouches = e.changedTouches || e.touches || [];

    // Determine direction of swipe to populate eventType
    if (Math.abs(xDiff) > Math.abs(yDiff)) { // most significant
      if (Math.abs(xDiff) > swipeThreshold && timeDiff < swipeTimeout) {
        if (xDiff > 0) {
          eventType = "swiped-left";
        }
        else {
          eventType = "swiped-right";
        }
      }
    }
    else if (Math.abs(yDiff) > swipeThreshold && timeDiff < swipeTimeout) {
      if (yDiff > 0) {
        eventType = "swiped-up";
      }
      else {
        eventType = "swiped-down";
      }
    }

    // If we found a direction for the swipe...
    if (eventType !== "") {

      // Create custom object with event data to append to custom event
      const eventData = {
        dir: eventType.replace(/swiped-/, ""),
        touchType: (changedTouches[0] || {}).touchType || "direct",
        xStart: parseInt(xDown, 10),
        xEnd: parseInt((changedTouches[0] || {}).clientX || -1, 10),
        yStart: parseInt(yDown, 10),
        yEnd: parseInt((changedTouches[0] || {}).clientY || -1, 10),
      };

      // fire `swiped` event event on the element that started the swipe
      startEl.dispatchEvent(new CustomEvent("swiped", { bubbles: true, cancelable: true, detail: eventData }));

      // fire `swiped-dir` event on the element that started the swipe
      startEl.dispatchEvent(new CustomEvent(eventType, { bubbles: true, cancelable: true, detail: eventData }));
    }

    // Reset all state values to be ready for next swipe
    setXDown( null );
    setYDown( null );
    setTimeDown( null );
    setXDiff( null );
    setYDiff( null );
    setStartEl( null );
  };

  /**
   * Gets attribute off HTML element or nearest parent
   * @param {object} el - HTML element to retrieve attribute from
   * @param {string} attributeName - name of the attribute
   * @param {any} defaultValue - default value to return if no match found
   * @returns {any} attribute value or defaultValue
   */
  const getNearestAttribute = (el, attributeName, defaultValue) => {

    // Walk up the DOM tree looking for attributeName
    while (el && el !== document.documentElement) {

      const attributeValue = el.getAttribute(attributeName);

      // If we found the attribute, return its value
      if (attributeValue) {
        return attributeValue;
      }

      // If we didn't find the attribute, keep walking up the DOM
      el = el.parentNode;
    }

    // If we walked the DOM and didn't find an element with the target attribute, return the default value
    return defaultValue;
  };
};
