import React, { useRef, useEffect, useState } from "react";

import { useHolodeck } from "../Holodeck";
import { unPauseOneAudioElement } from "../Holodeck/audioHelpers";

/**
 * Render one audio object that streams tracks for all room participants, except
 * for local user.
 * 
 * Logic for combining tracks into one stream based on Daily demo: https://github.com/daily-demos/examples/blob/main/custom/shared/components/Audio/CombinedAudioTrack.js
 * 
 * @returns markup to play all participant audio
 */
const AllAudio = () => {

  // Ref to point to audio object
  const audioRef = useRef(null);

  /*
   * CONTEXT
   */

  // Get Daily Participant Objects for all Speakers in room
  const {
    allSpeakMode,
    allParticipants,
    broadcastingStudentIds,
  } = useHolodeck();

  /*
   * STATE
   */

  // Array to hold audio tracks for broadcasting participants
  const [
    broadcastingParticipantTracks,
    setBroadcastingParticipantTracks,
  ] = useState([]);

  /*
   * USE EFFECT
   */

  // On load, add a stream as the source for the audio element
  useEffect(() => {

    // Protect against no value in ref
    if ( !audioRef?.current ) {
      return;
    }

    // Create new MediaStream and assign it as the source for the audio element
    audioRef.current.srcObject = new MediaStream();

  }, []);

  // On participant changes, update tracks!
  useEffect(() => {
 
    // Get participant objects for all broadcasting students, but not the teacher
    const broadcastingParticipants = allSpeakMode
      ? allParticipants?.filter((p) => !p.local)
      // Otherwise, only instructor-specified students can broadcast
      : allParticipants?.filter((p) => (
        !p.local && broadcastingStudentIds?.includes( p.user_id )
      ));

    // Combine all participant audio tracks into one array and set in state
    setBroadcastingParticipantTracks(() => (
      broadcastingParticipants?.map(p => p.tracks?.audio?.persistentTrack)
    ));

  }, [
    allSpeakMode,
    allParticipants,
    broadcastingStudentIds,
  ]);

  // When tracks are updated, combine the active ones into a stream for the audio element to play
  useEffect(() => {

    // If we don't have any tracks, don't do anything!
    if ( !broadcastingParticipantTracks?.length ) {
      return;
    }

    // Grab the audio element
    const audioElement = audioRef?.current;

    // Extract MediaStream that is source for audio
    const stream = audioElement?.srcObject;

    // If we don't have the audio, or the audio has no source, don't do anything
    if ( !stream ) {
      return;
    }

    // Attach all participant tracks to the stream
    broadcastingParticipantTracks.forEach((track) => {

      // A track could be undefined. Protect against that!
      if ( track ) {

        // Add listener to remove track when it ends (participant is no longer sending audio over it)
        track.addEventListener(
          "ended",
          (event) => stream.removeTrack(event.target),
          // Only remove the track one time!
          { once: true },
        );

        // Add the track to the stream
        stream.addTrack(track);
      }
    });

    // Reset the audio element and get it ready to play
    audioElement.load();

    // Use async helper to play the audio stream
    // Helper is async, but in useEffect, we fire and forget
    playStream(audioElement, stream);

  }, [ broadcastingParticipantTracks ]);

  /*
   * HELPERS
   */

  // Helper to play MediaStream that is source of audio element
  const playStream = async (audioElement, stream) => {
    // If tracks change super-fast (e.g. two participants muting at almost the exact same time), a play call could be interrupted by the next load call (called in useEffect before playStream). To keep that error from causing a WSOD, catch and log this benign error.
    try {
      if (
        // If at least one track is playable ...
        stream.getAudioTracks().some((track) => (
          track.enabled
          && track.readyState === "live"
        ))
        // ... and the audio is paused ...
        && audioElement.paused
      ) {
        // ... then play the audio!
        await audioElement.play();
      }
    }
    // If we got an error, log it
    catch (error) {
      console.error("Could not play audio stream for participants", error);
    }
  };

  /*
   * RENDER
   */

  return (
    <audio
      autoPlay
      onPause={ (event) => unPauseOneAudioElement(event.target) }
      playsInline
      ref={audioRef}
    />
  );
};

export default AllAudio;
