import React, { Component } from "react";
import { withRouter, Redirect } from "react-router-dom";
import AsyncButton from "./AsyncButton";

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      errorMessage: "",
    };
    // use a ref so the email and password inputs are sensitive to key presses
    this.submitRef = React.createRef();
  }

  // update username when being typed in
  updateEmail = (event) => {
    this.setState({ email: event.target.value });
  };

  // update password when being typed in
  updatePassword = (event) => {
    this.setState({ password: event.target.value });
  };

  // handle submission/account creation
  onSubmit = () => {
    return new Promise((resolve) => {
      this.props.onSubmit(this.state.email, this.state.password, this.props.history, (success, error) => {
        resolve({ success, error });
      });
    });
  };


  resetPassword = () => {
    const { history } = this.props;
    history.push("reset-password");
  };

  renderErrorMessage = () => {
    if (this.state.errorMessage !== "") {
      return (
        <div className="incomplete-message">{this.state.errorMessage}</div>
      );
    }
    else {
      return (
        <div />
      );
    }
  };

  render() {
    if (this.props.userInfo.currentUser) {
      return <Redirect to="/" />;
    }
    else {
      return (
        <div>
          <div className="SignIn">
            <div className="signin-header"> Sign in </div>
            <div className="signin-section">
              <div>Email</div>
              <input className="form"
                type="text"
                value={this.state.email}
                onChange={this.updateEmail}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    // submit the sign in form if the user presses enter when focused on email input
                    this.submitRef.current.focus();
                  }
                }}
              />
            </div>
            <div className="signin-section">
              <div>Password</div>
              <input className="form"
                type="password"
                value={this.state.password}
                onChange={this.updatePassword}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    // submit the sign in form if the user presses enter when focused on email input
                    this.submitRef.current.focus();
                  }
                }}
              />
            </div>
            <div className="forgot-message">
              Forgot your password? Click <span className="here" onClick={this.resetPassword}>here</span> to reset
            </div>
            <div>
              {this.renderErrorMessage()}
            </div>
            <AsyncButton
              ref={this.submitRef}
              className="submit-button"
              initialText="Sign in"
              loadingText="Signing in..."
              timeout={10000}
              completeFunction={() => {
                this.props.history.push("/");
              }}
              errorFunction={() => {
                console.log("error in sign-in");
                this.setState({ errorMessage: "UH OH! Username or password is incorrect. Please try again." });
              }}
              function={() => this.onSubmit()}
            />
          </div>
        </div>
      );
    }
  }
}

export default withRouter(SignIn);
