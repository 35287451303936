import React, {
  useEffect,
  useState,
} from "react";
import {
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";

import { fetchLessonById } from "../../services/datastore";
import { talkka } from "../../services/logging";
import { useTime } from "../../services/time";

import LessonSummary from "../../components/LessonSummary";
import LoadingOverlay from "../../components/LoadingOverlay";

import ChangeModuleModal from "./ChangeModuleModal";
import ClassReport from "./ClassReport";
import ClassStartArea from "./ClassStartArea";
import CouldNotFetchLesson from "./CouldNotFetchLesson";
import GiveUpClassModal from "./GiveUpClassModal";

import "./classDetail.scss";

/**
 * Render the class detail page from which instrcutors launch the class
 */
const ClassDetail = () => {

  /*
   * HOOKS
   */

  // Get lessonId from route
  const match = useRouteMatch("/class/:lessonId");

  // DrillLessonReport is an old class-baesd component, and relies on having history in props. So generate history here, and pass it in as a prop!
  const history = useHistory();

  // Get search parameter (query string), if any
  const location = useLocation();

  const { nowUTC } = useTime();

  /*
   * STATE
   */

  // Loading and error flags
  const [ loading, setLoading ] = useState( true );
  const [ hasError, setHasError ] = useState( false );

  // Store entire lesson object from Firestore
  const [ lesson, setLesson ] = useState( null );

  // Record room open and close times as UTC ISO timestamps
  // Class start button should be available only between these times
  const [ roomClosesUTC, setRoomClosesUTC ] = useState( null );
  const [ roomOpensUTC, setRoomOpensUTC ] = useState( null );

  // Booleans indicating whether or not to show indicated modals
  const [ showClassReportForm, setShowClassReportForm ] = useState( false );
  const [ showGiveUpClassModal, setShowGiveUpClassModal ] = useState( false );
  const [ showChangeModuleModal, setShowChangeModuleModal ] = useState( false );

  /*
   * USE EFFECT
   */

  // On load, fetch lesson info by lessonId in URI
  useEffect(() => {
    const { lessonId } = match.params;

    // If we get a lessonId, attempt to fetch and set it in state
    if ( lessonId ) {
      asyncFetchAndSetLessonInfo( lessonId );
    }
    // If no lessonId, render error message
    else {
      setHasError( true );
    }

    // Check to see if we have a query string
    if ( location.search ) {
      const showClassReportSearchParam =
        new URLSearchParams( location.search )
          .get("show-lesson-report");
      
      // Show form if any value was passed through under the show-lesson-report key
      setShowClassReportForm( !!showClassReportSearchParam );
    }

  }, []);

  // When lesson is set, remember room open and close ISO timestamp in separate state
  useEffect(() => {
    if ( !lesson ) {
      return;
    }

    // Grab Daily room open/close values (in Java epoch millis), and convert into ISO-format strings in UTC time
    if ( lesson.roomInfo ) {
      setRoomClosesUTC(
        new Date( lesson.roomInfo.config.exp * 1000 ).toISOString(),
      );
      setRoomOpensUTC(
        new Date( lesson.roomInfo.config.nbf * 1000 ).toISOString(),
      );
    }
    // If we just made a synthetic lesson, roomInfo may not have resolved. For now, just handle that with our generic error message
    else {
      setHasError( true );
    }

  }, [ lesson ]);

  /*
   * HELPERS
   */

  /**
   * Get lesson info from Firestore, set it in state, and deactivate local
   * loading flag
   */
  const asyncFetchAndSetLessonInfo = async ( lessonIdToFetch ) => {
    try {
      const lessonInfo = await fetchLessonById( lessonIdToFetch );

      // If we got lessonInfo, set it in state
      if ( lessonInfo ) {
        setLesson( lessonInfo );
      }
      // If not, throw error to trigger CouldNotFetchLesson display
      else {
        throw new Error("No data in lesson object returned from back end");
      }
    }
    // Any error in fetching triggers logEvent and local error display
    catch ( error ) {
      talkka.error(`Error fetching lesson info by id: ${ error }`);
      setHasError( true );
    }

    // Whether we succeeded or not, we're done loading!
    setLoading( false );
  };

  /*
   * RENDER
   */

  if ( hasError ) {
    return <CouldNotFetchLesson />;
  }

  return loading
    ? (
      <LoadingOverlay />
    )
    : (
      <main className="classDetail__container">
        <ClassStartArea
          idHash={ lesson.idHash }
          lessonIsKilled={ lesson.isKilled }
          lessonScheduledEndUTC={ lesson.lessonScheduledEndUTC }
          lessonScheduledStartUTC={ lesson.lessonScheduledStartUTC }
          roomOpensUTC={ roomOpensUTC }
          roomClosesUTC={ roomClosesUTC }
        />
        <div className="classDetail__innerContainer">
          <div className="classDetail__lessonSummaryContainer">
            <LessonSummary lesson={ lesson } />
          </div>
          <section className="classDetail__actionsContainer">
            {// Only show buttons if class is neither complete nor killed
              !lesson.isKilled && !lesson.complete
              && (
                <>
                  <button
                    className="classDetail__actionButton classDetail__actionButton--positiveAction"
                    onClick={ () => setShowClassReportForm( true ) }
                  >
                    Post-Drill Notes
                  </button>
                  <button
                    className={`classDetail__actionButton ${
                      nowUTC >= roomOpensUTC
                        ? "inactive"
                        : ""
                    }`}
                    onClick={ () => {
                    // If before class start, show change module modal
                      if ( nowUTC < roomOpensUTC ) {
                        setShowChangeModuleModal( true );
                      }
                      // If after class start, don't allow module change from this screen
                      else {
                        alert("Please change the module from within the class room");
                      }
                    
                    } }
                  >
                    Change Module
                  </button>
                  <button
                    className="classDetail__actionButton"
                    onClick={ () => setShowGiveUpClassModal( true ) }
                  >
                    Give Up Class
                  </button>
                  <button
                    className="classDetail__actionButton"
                    onClick={ () => history.push(`/kill-lesson/${ lesson.id }`) }
                  >
                    Kill Class
                  </button>
                </>
              )
            }
          </section>
        </div>
        {/* Elements below are modals that are toggled on/off by buttons */}
        <GiveUpClassModal
          exitFunc={ () => setShowGiveUpClassModal( false ) }
          lesson={ lesson }
          showGiveUpClassModal={ showGiveUpClassModal }
        />
        <ChangeModuleModal
          exitFunc={ () => setShowChangeModuleModal( false ) }
          languageTarget={ lesson?.languageTarget }
          lessonId={ lesson.id }
          module={ lesson.module }
          showChangeModuleModal={ showChangeModuleModal }
        />
        {// Show Class Report Form if state flag says to
          showClassReportForm
          && (
            <ClassReport
              exit={ () => setShowClassReportForm( false ) }
              history={ history }
              lesson={ lesson }
            />
          )
        }
      </main>
    );
};

export default ClassDetail;
