import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { fetchLessonById } from "../../services/datastore";
import { getCurrentUser } from "../../services/user";

import activebuildconfig from "../../../configs/activebuildconfig.json";
const { SPEAK_ROOT_URL } = activebuildconfig;

import "./lessonSummary.scss";

/**
 * LessonSummary component to display some details about a lesson
 * 
 * Must receive one of the following props:
 * @param { Object } lesson [optional] - lesson file fetched from back end
 * @param { String } lessonID [optional] - ID of a lesson to fetch and display summary for
 * @returns markup for LessonSummary
 */
const LessonSummary = ({ lesson, lessonID }) => {
  /*
   * CONTEXT
   */

  // Info for logged-in user
  const userInfo = getCurrentUser();

  /*
   * STATE
   */

  // Loading flag -- initialize true, is set to false at end of useEffect
  const [loading, setLoading] = useState(true);

  // Object to hold lesson info, either from passed-in lesson or fetch result
  const [lessonInfo, setLessonInfo] = useState(lesson ? lesson : null);

  // Counts of registered speakers and audience
  const [ registrantCount, setRegistrantCount ] = useState({
    total: 0,
    speakers: 0,
    audience: 0,
  });

  /*
   * USE EFFECT
   */

  // When props change, if lesson is not supplied and lesson ID is, load the lesson!
  useEffect(() => {
    // Check if state variable holds value from props
    if (!lessonInfo) {
      // If no lessonInfo in state, get it!
      // Helper de-activates loading flag
      fetchAndSetLessonInfo( lessonID );
    }
    else {
      // If we have lesson info, set it in state and disable loading flag
      setLessonInfo(lesson);
      setLoading(false);
    }
    // We know we have lessonInfo now, so de-activate loading flag
    
  }, [lesson, lessonID]);

  // When lessonInfo updates, compute registrant count
  useEffect(() => {

    // If we have not registrant info, we have nothing to do!
    if ( !lessonInfo.registrants ) {
      return;
    }

    // Reduce the registrant array into an object with registrant counts
    setRegistrantCount(() => lessonInfo.registrants.reduce(
      (count, registrant) => {
        // Increment total count
        count.total++;

        // Increment student registration type specific count
        if ( registrant.registrationTreatmentType === "SPEAKER" ) {
          count.speakers++;
        }
        else {
          count.audience++;
        }

        return count;
      }, {
        total: 0,
        speakers: 0,
        audience: 0,
      }),
    );

  }, [ lessonInfo ]);

  /*
   * HELPERS
   */

  // Fetch lesson info from Firestore, set in state, and disable loading flag
  const fetchAndSetLessonInfo = async ( lessonId ) => {
    const fetchedLessonInfo = await fetchLessonById( lessonId );
    setLessonInfo( fetchedLessonInfo );
    setLoading( false );
  };

  // Pull module info out of lesson
  const moduleInfo = () => {
    // Check if the lesson has a module assigned
    return lessonInfo?.module
      // If module is present, format the module info string the same way that ModuleSearch does
      ? `${lessonInfo.module.difficulty} ${lessonInfo.module.unit_name} ${lessonInfo.module.unit_number}: ${lessonInfo.module.lesson_name} (${lessonInfo.module.category})`
      // If no module, indicate so!
      : "None selected";
  };

  const studentJoinUrl = SPEAK_ROOT_URL + "/join/" + lessonInfo.idHash;
  const instructorJoinUrl = lessonInfo.lesson_event_type_category === "General" ? lessonInfo.zoom_link : "/teach/" + lessonInfo.idHash;

  // Markup for info only visible to admins
  const adminInfo = (
    <div>
      <br/>
      <div className="lesson-summary__element">
        <span className="lesson-summary__label">
        Lesson ID:
        </span>
        <span className="lesson-summary__value">
          {lessonInfo.id}
        </span>
      </div>
      <div className="lesson-summary__element">
        <span className="lesson-summary__label">
          Lesson ID Hash:
        </span>
        <span className="lesson-summary__value">
          {lessonInfo.idHash}
        </span>
      </div>
      <div className="lesson-summary__element">
        <span className="lesson-summary__label">
          Lesson Scheduled Start UTC:
        </span>
        <span className="lesson-summary__value">
          {lessonInfo.lessonScheduledStartUTC}
        </span>
      </div>
      <div className="lesson-summary__element">
        <span className="lesson-summary__label">
          Lesson Scheduled End UTC:
        </span>
        <span className="lesson-summary__value">
          {lessonInfo.lessonScheduledEndUTC}
        </span>
      </div>
      <br/>
      <div className="lesson-summary__element">
        <span className="lesson-summary__label">
          Student Join Link:
        </span>
        <span className="lesson-summary__value">
          <Link to={{ pathname: studentJoinUrl}} target="_blank">{studentJoinUrl}</Link>
        </span>
      </div>
      <div className="lesson-summary__element">
        <span className="lesson-summary__label">
          Instructor Join Link:
        </span>
        <span className="lesson-summary__value">
          <Link to={instructorJoinUrl} >{instructorJoinUrl}</Link>
        </span>
      </div>
      { lessonInfo.roomInfo?.name ? 
        <><br/>
          <div className="lesson-summary__element">
            <span className="lesson-summary__label">
          Daily Room:
            </span>
            <span className="lesson-summary__value">
              <a target="_blank" href={"https://dashboard.daily.co/rooms/" + lessonInfo.roomInfo.name}>Daily Room</a>
            </span>
          </div></>
        : <></>
      }
    </div>
  );

  let lessonType = "Unknown Drill Type";
  
  switch(lessonInfo.lesson_event_type_category) {
    case "General":
      lessonType = "Zoom Drill";
      break;
    case "Daily":
      lessonType = "Drill";
      break;
    default:
      lessonType = lessonInfo.lesson_event_type_category;
  }

  // Render the summary!
  return loading
    // If loading, display loading indicator
    ? (<div>Loading...</div>)
    // If not loading, check for lessonInfo before attempting to display
    : lessonInfo
      // If we have lessonInfo, display it!
      ? (
        <div className="lesson-summary">
          {/* Only display Lesson Summary Link if we are not on the Class Detail page */
            window.location.href.includes(`/class/${lessonInfo.id}`)
              ? <div className="lesson-summary__heading">Lesson Summary</div>
              : <Link to={`/class/${lessonInfo.id}`} className="lesson-summary__heading">Lesson Summary</Link>
          }
          {// Show admin info if user is admin
            userInfo.isAdmin && adminInfo
          }
          {// Display killed drill flag, if needed
            lessonInfo.isKilled
              ? (
                <div className="lesson-summary__element">
                  <span className="bold red">
                  KILLED
                  </span>
                  <br/>
                </div>
              )
              : null }
          <br/>
          <div className="lesson-summary__element">
            <span className="lesson-summary__label">
              Lesson Title:
            </span>
            <span className="lesson-summary__value">
              {lessonInfo.lesson_title}
            </span>
          </div>
          <div className="lesson-summary__element">
            <span className="lesson-summary__label">
              Lesson Type:
            </span>
            <span className="lesson-summary__value">
              {lessonType}
            </span>
          </div>
          <div className="lesson-summary__element">
            <span className="lesson-summary__label">
              Module:
            </span>
            {lessonInfo.module ? <Link style={{textDecoration: "none"}} target="_blank" to={`/manage/modules?moduleId=${lessonInfo.module.id}`} className="lesson-summary__value">{moduleInfo()}</Link> : 
              <span className="lesson-summary__value">No Module Assigned</span>}
          </div>
          <div className="lesson-summary__element">
            <span className="lesson-summary__label">
              Lesson Level:
            </span>
            <span className="lesson-summary__value">
              {lessonInfo.lesson_level || "Not Set"}
            </span>
          </div>
          <br/>
          <div className="lesson-summary__element">
            <span className="lesson-summary__label">
              Instructor:
            </span>
            <span className="lesson-summary__value">
              {lessonInfo.instructor || "Unclaimed"}
            </span>
          </div>
          <div className="lesson-summary__element">
            <span className="lesson-summary__label">
              Instructor&apos;s Email:
            </span>
            <span className="lesson-summary__value">
              {lessonInfo.instructor_email || "Unclaimed"}
            </span>
          </div>
          <div className="lesson-summary__element">
            <span className="lesson-summary__label">
              Date (NYC):
            </span>
            <span className="lesson-summary__value">
              {lessonInfo.date || "Could not be determined"}
            </span>
          </div>
          <div className="lesson-summary__element">
            <span className="lesson-summary__label">
              Start Time (NYC):
            </span>
            <span className="lesson-summary__value">
              {lessonInfo.time || "Could not be determined"}
            </span>
          </div>
          <br />
          <div className="lesson-summary__element">
            <span className="bold-text">{ registrantCount.total } students registered</span>
          </div>
          <br />
          <div className="lesson-summary__element">
            <span className="bold-text">{ registrantCount.speakers } Pro spots</span>
          </div>
          {lessonInfo.registrants && lessonInfo.registrants
            .filter(registrant => registrant.registrationTreatmentType === "SPEAKER")
            .map((registrant, index) => (
              <div key={index} className="lesson-summary__element">
                <span className="lesson-summary__label"></span> {registrant.firstName}
                <span className="lesson-summary__label"></span> {registrant.lastName}
              </div>
            ))}
          <br />
          <div className="lesson-summary__element">
            <span className="bold-text">{ registrantCount.audience } Audience/Observer</span>
          </div>
          {lessonInfo.registrants && lessonInfo.registrants
            .filter(registrant => registrant.registrationTreatmentType !== "SPEAKER")
            .map((registrant, index) => (
              <div key={index} className="lesson-summary__element">
                <span className="lesson-summary__label"></span> {registrant.firstName}
                <span className="lesson-summary__label"></span> {registrant.lastName}
              </div>
            ))}
        </div>
      )
      // If we don't have lessonInfo, display error message 
      : (
        <div className="lesson-summary">
          No lesson object or ID was provided for this LessonSummary instance!
        </div>)
  ;



};

export default LessonSummary;
