import React from "react";

// Component to display a group of lessons
import LessonGroup from "../LessonGroup";

// Get date in NYC and format it
import { getNYCdate } from "../../../services/time";

// Render lessons on future dates
const FutureLessons = ({ lessons, userIsAdmin, allTeachers, updateLessonTeacher }) => (
  <div className="futureLessons">
    {Object.keys(lessons).map((day) => (
      <div className="futureLessons__day" key={`futureLessons-${day}`}>
        <LessonGroup
          lessons={lessons[day]}
          header={(
            <>
              <h3>{getNYCdate(day, "weekday")}</h3>
              <div className="nyc-reminder">All times in NYC</div>
            </>
          )}
          userIsAdmin={userIsAdmin}
          allTeachers={allTeachers}
          updateLessonTeacher={updateLessonTeacher}
        />
      </div>
    ))}
  </div>
);

export default FutureLessons;
