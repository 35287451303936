// Function to grab and force play on all audio tracks on the page. Includes participant audio (rendered in Audio.js) and silentAudioElement (created in Holodeck)
export const playAllAudioElements = () => {
  document.querySelectorAll("audio").forEach(async (audio) => {
    try {
      if (audio.paused && audio.readyState === audio.HAVE_ENOUGH_DATA) {
        await audio?.play();
      }
    }
    catch (error) {
      // Auto play failed
      console.error("Could not auto-play audio track", error);
    }
  });
};

// Audio should be playing at all times (not paused), so this listener is added to all audio objects as onPause handlers, and they try to play audio after it has been paused
export const unPauseOneAudioElement = async ( audioObject ) => {
  try {
    // Try to play the audio
    await audioObject.play();
  }
  catch {
    // If couldn't play audio on first try, attach a listener to try again on the user's first click/tap
    document.addEventListener("click", () => audioObject?.play(), { once: true });
  }
};

// This is, by design, a non-React-y way to render an audio element playing a silent track. Based on Daily solution for audio tracks dropping in iOS: https://github.com/daily-demos/party-line/blob/main/ios/Party%20Line/Resources/client.html#L396
// Leaving this as a not-React-y solution because it's entirely possible that React optimizations, combined with iOS quirks, could render this solution useless if refactored to fit framework conventions
export const startSilentAudioElement = () => {
  try {
    // Check if silent audio element is already present, and remember if it is
    let silentAudioElement = document.querySelector("#silentAudioElement");

    // Only create a new silent audio element if one isn't already present (from a prior Drill)
    if ( !silentAudioElement ) {
      
      // Create new audio element and set id on element to unmount it later
      silentAudioElement = document.createElement("audio");
      silentAudioElement.setAttribute("id", "silentAudioElement");

      // Audio should loop, and source is text for silent audio
      silentAudioElement.loop = true;
      const silenceMono56kbps100msBase64Mp3 = "data:audio/mp3;base64,//tAxAAAAAAAAAAAAAAAAAAAAAAASW5mbwAAAA8AAAAFAAAESAAzMzMzMzMzMzMzMzMzMzMzMzMzZmZmZmZmZmZmZmZmZmZmZmZmZmaZmZmZmZmZmZmZmZmZmZmZmZmZmczMzMzMzMzMzMzMzMzMzMzMzMzM//////////////////////////8AAAA5TEFNRTMuMTAwAZYAAAAAAAAAABQ4JAMGQgAAOAAABEhNIZS0AAAAAAD/+0DEAAPH3Yz0AAR8CPqyIEABp6AxjG/4x/XiInE4lfQDFwIIRE+uBgZoW4RL0OLMDFn6E5v+/u5ehf76bu7/6bu5+gAiIQGAABQIUJ0QolFghEn/9PhZQpcUTpXMjo0OGzRCZXyKxoIQzB2KhCtGobpT9TRVj/3Pmfp+f8X7Pu1B04sTnc3s0XhOlXoGVCMNo9X//9/r6a10TZEY5DsxqvO7mO5qFvpFCmKIjhpSItGsUYcRO//7QsQRgEiljQIAgLFJAbIhNBCa+JmorCbOi5q9nVd2dKnusTMQg4MFUlD6DQ4OFijwGAijRMfLbHG4nLVTjydyPlJTj8pfPflf9/5GD950A5e+jsrmNZSjSirjs1R7hnkia8vr//l/7Nb+crvr9Ok5ZJOylUKRxf/P9Zn0j2P4pJYXyKkeuy5wUYtdmOu6uobEtFqhIJViLEKIjGxchGev/L3Y0O3bwrIOszTBAZ7Ih28EUaSOZf/7QsQfg8fpjQIADN0JHbGgQBAZ8T//y//t/7d/2+f5m7MdCeo/9tdkMtGLbt1tqnabRroO1Qfvh20yEbei8nfDXP7btW7f9/uO9tbe5IvHQbLlxpf3DkAk0ojYcv///5/u3/7PTfGjPEPUvt5D6f+/3Lea4lz4tc4TnM/mFPrmalWbboeNiNyeyr+vufttZuvrVrt/WYv3T74JFo8qEDiJqJrmDTs///v99xDku2xG02jjunrICP/7QsQtA8kpkQAAgNMA/7FgQAGnobgfghgqA+uXwWQ3XFmGimSbe2X3ksY//KzK1a2k6cnNWOPJnPWUsYbKqkh8RJzrVf///P///////4vyhLKHLrCb5nIrYIUss4cthigL1lQ1wwNAc6C1pf1TIKRSkt+a//z+yLVcwlXKSqeSuCVQFLng2h4AFAFgTkH+Z/8jTX/zr//zsJV/5f//5UX/0ZNCNCCaf5lTCTRkaEdhNP//n/KUjf/7QsQ5AEhdiwAAjN7I6jGddBCO+WGTQ1mXrYatSAgaykxBTUUzLjEwMKqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqg==";
      silentAudioElement.src = silenceMono56kbps100msBase64Mp3;

      // Mount element in DOM
      document.body.appendChild(silentAudioElement);
    }

    // Play silent audio element
    silentAudioElement.play();
  }
  catch (error) {
    console.error("Could not start silent audio track:", error);
  }
  
};

// Non-React-y way to stop silent audio track on component unmount
export const stopSilentAudioElement = () => {
  // Grab audio element by ID set in startSilentAudioElement
  const silentAudioElement = document.querySelector("#silentAudioElement");
  // Stop playback
  silentAudioElement?.pause();
  // Remove element from the DOM
  silentAudioElement?.remove();
};
