import React from "react";
import ReactDOM from "react-dom";
import "./style.scss";
import {
  BrowserRouter as Router,
} from "react-router-dom";
// eslint-disable-next-line no-unused-vars
import App from "./components/app";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(
  <Router>
    <App />
  </Router>, document.getElementById("main"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// test comments
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
