import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { useHolodeck } from "../Holodeck";

import ModuleSelector from "./ModuleSelector";

import "./engineering.scss";

/**
 * Slide-in menu for teacher actions
 * 
 * @param { String } lesson - Firestore object of lessson associated with this
 * WebDrill
 * @param { Object } module - From lesson.module in Firestore
 * @param { CallableFunction } setModule - Sets module info in Lesson.js state
 * @param { Boolean } isVisible - Indicates if engineering menu is on screen
 * @param { CallableFunction } toggleVisibility - !isVisible
 * @param { Boolean } isFullscreen - Indicates if app is in fullscreen mode
 * @param { CallableFunction } toggleFullscreen - !isFullscreen
 * @param { CallableFunction } setLesson - Update lesson object in Teach.js
 * @returns 
 */
const Engineering = ({
  lesson,
  module,
  setModule,
  isVisible,
  toggleVisibility,
  isFullscreen,
  toggleFullscreen,
}) => {
  // History from react-router-dom hook
  const history = useHistory();

  // Ability to toggle all keyboard shortcuts in Drill on or off
  const { setAllowShortcuts } = useHolodeck();

  // Open and close module-changing modal
  const [changeModule, setChangeModule] = useState(false);

  // Open module menu and disable shortcuts
  const openModuleMenu = () => {
    setAllowShortcuts(false);
    setChangeModule(true);
  };

  // Close module menu and enable shortcuts
  const closeModuleMenu = () => {
    setAllowShortcuts(true);
    setChangeModule(false);
  };

  return (
    <div
      className={`engineeringContainer${isVisible ? " isVisible" : ""}`}
      onClick={(event) => {
        // If click or tap is not on the actual menu, close the menu
        if (event.target.classList.contains("engineeringContainer")) {
          toggleVisibility();
        }
      }}
    >
      <div className="engineeringMenu">
        <div
          className="engineering__button"
          onClick={ () => toggleVisibility() }
        >
          Hide Menu
        </div>
        {// Only display fullscreen button if API is supported
          document.fullscreenEnabled
          && (
            <div
              className="engineering__button"
              onClick={ () => {
                // Toggle fullscreen mode
                toggleFullscreen();
                // Exit menu
                toggleVisibility();
              }}
              role="button"
            >
              {isFullscreen ? "Exit Fullscreen" : "Go Fullscreen"}
            </div>
          )
        }
        <div
          className="engineering__button"
          onClick={() => history.push(`/class/${lesson.id}?show-lesson-report=true`)}
          role="button"
        >
          Leave Lesson
        </div>
        <div
          className="engineering__button"
          onClick={ openModuleMenu }
        >
          Change Module
        </div>
        <div className="engineering__moduleInfo">
          {// Only render module info if we have one
            module
              && `Module: ${module.difficulty} ${module.unit_name} ${module.unit_number} - ${module.lesson_name}`
          }
        </div>
        <br/>
        <div className="engineering__lessonInfo">
          {`Lesson: L${lesson.lesson_level} - ${lesson.time.slice(0,5)} NYC time`}
        </div>
        <br/>
        <div className="engineering__lessonInfo">
          {// Only render conversation Prompt if we have one
            lesson.conversationPrompt
              && `Student Conversation Prompt: ${lesson.conversationPrompt.es}`
          }
        </div>
      </div>
      <ModuleSelector
        isVisible={ changeModule }
        close={ closeModuleMenu }
        lessonId={lesson.id}
        module={module}
        setModule={setModule}
        limitLanguageTo={lesson.languageTarget ? lesson.languageTarget : "es"}
      />
    </div>
  );
};

export default Engineering;
