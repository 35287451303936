import React, { Component } from "react";
import { searchModules } from "../../services/datastore";
import "./moduleSearch.scss";

// Given a module, return a string formatted to identify the module
function formatModule(module) {
  const moduleIsPopulated = Object.keys(module).length > 0;
  return moduleIsPopulated ? `${module.difficulty} ${module.unit_name} ${module.unit_number}: ${module.lesson_name} (${module.category})` : "";
}

/**
 * COMPONENT ModuleSearch
 *
 * @prop { Object } module - Firestore object of module to use as selected
 * option on initial load
 * @prop { CallableFunction } clearModule - clears module results
 * @prop { CallableFunction } moduleWasSelected - async callback for when the
 * user clicks on a module
 * @prop { boolean } omitWelcome - true if Welcome modules should be excluded
 * from search results
 * @prop { boolean } omitComingSoon - true if Coming Soon modules should be
 * excluded from search results
 * @prop { string } limitLanguageTo = Spanish or English (or null)
 */
class ModuleSearch extends Component {
  constructor(props) {
    super(props);

    const filters = [];
    if (props.omitWelcome) {
      filters.push("omitWelcome");
    }
    if (props.omitComingSoon) {
      filters.push("omitComingSoon");
    }

    let searchLanguage = null;

    if (props.limitLanguageTo) {
      searchLanguage = props.limitLanguageTo;
    }

    this.state = {
      filters,
      hasFetchError: false,
      results: [],
      searchLanguage,
      searchText: "",
      searchTextSet: false,
      searchTimeout: null,
    };

    this.performSearch = this.performSearch.bind(this);
    this.updateSearchText = this.updateSearchText.bind(this);
  }

  componentDidMount() {
    // Used when the module comes from query string instead of being explicitly searched for
    // If the module exists and the search text hasn't been set yet, set the search text
    if (this.props.module && !this.state.searchTextSet){
      this.setSearchText();
    }
  }

  performSearch() {
    // Clear error flag from previous search, if present
    this.setState({ hasFetchError: false });

    // Only do the search if `searchText` is not empty
    if (this.state.searchText) {
      searchModules(this.state.searchText, this.state.searchLanguage, this.state.filters)
        .then((modules) => {
          this.setState({
            results: modules ? modules : [],
            // Bad search results in return of false from datastore function, good search results in truthy value
            hasFetchError: !modules,
          });
        })
        .catch(() => this.setState({
          results: [],
          hasFetchError: true,
        }));
    }
    else {
      this.setState({
        results: [],
      });
    }
  }

  // Callback to update the search bar text & trigger a new search
  updateSearchText(event) {
    // If a search was already scheduled, cancel it
    if (this.state.searchTimeout) {
      clearTimeout(this.state.searchTimeout);
    }

    // Run the search after 300ms
    const searchTimeout = setTimeout(this.performSearch, 300);

    this.setState({
      searchText: event.target.value,
      searchTimeout,
    });

    // Will clear module from view page when there is nothing in the search bar
    event.target.value === "" ? this.props.clearModule() : null;
  }

  // Callback when the user clicks on a module search result
  selectModule(module) {
    // Trigger the module selected callback if one was provided
    if (this.props.moduleWasSelected) {
      this.props.moduleWasSelected(module.id);
    }

    // Update the search text to reflect the chosen module & hide the results
    this.setState({
      searchText: formatModule(module),
      results: [],
    });
  }

  // Sets the text of the module in the search bar when the module comes from the query string
  setSearchText = () => {
    this.setState({
      searchText: formatModule(this.props.module),
      searchTextSet: true,
    });
  };

  render() {
    return (
      <div className="moduleSearchContainer">
        <input
          className="moduleSearchForm"
          type="text"
          value={this.state.searchText}
          onChange={this.updateSearchText}
        />
        <div className="moduleSearchResults">
          {this.state.hasFetchError
            ? (
              <div className="moduleSearchResult">
                Could not fetch modules. Please check your internet connection.
              </div>
            )
            : this.state.results.map((module, i) => {
              return (
                <div
                  className="moduleSearchResult"
                  role="button"
                  tabIndex="0"
                  onClick={ () => this.selectModule(module) }
                  key={`result-${i}`}
                >
                  { formatModule(module) }
                </div>
              );
            })}
        </div>
      </div>
    );
  }
}
export default ModuleSearch;
