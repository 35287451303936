import React, {
  useEffect,
  useState,
}
  from "react";

import ReviewRow from "./ReviewRow";

import "./reviewTable.scss";

/**
 * Renders the table that shows teacher review data for admins, and allows
 * sorting by column values
 *
 * @param { Array } reviewData - data computed in CF about all reviews for
 * classes in user-selected time period
 */
const ReviewTable = ({ reviewData }) => {

  /*
   * STATE
   */

  // Store ordered reviewData for display
  const [ orderedData, setOrderedData ] = useState( reviewData );

  // Remember what we're ordering by! Default is teacher name
  const [ orderBy, setOrderBy ] = useState("teacherName");

  /*
   * USE EFFECT
   */

  // When we get new data or change what we're ordering by, recalculated orderedData
  useEffect(() => {

    // If no data, nothing to order!
    if ( !reviewData ) {
      return;
    }

    // We want ascending sort if sorting by name, descending sort if sorting by anything else
    const [aPlace, bPlace] = orderBy === "teacherName" ? [1, -1] : [-1, 1];

    const sortedData = reviewData.slice().sort(
      (a, b) => a[orderBy] > b[orderBy] ? aPlace : bPlace,
    );

    setOrderedData( sortedData );
  }, [
    orderBy,
    reviewData,
  ]);

  /*
   * SUBCOMPONENT
   */

  // Super-simple column header component. It lives within this component because it relies extensively on parent state
  const ReviewTableHeader = ({
    dataKey,
    displayName,
  }) => (
    <th
      onClick={() => setOrderBy(dataKey)}
      className={ `reviewTable__header ${orderBy === dataKey ? "reviewTable__header--activeIndex" : ""}`}
    >
      { displayName }
    </th>
  );

  /*
   * RENDER
   */

  return (
    <table className="teacherReviews__reviewTable">
      <thead>
        <tr>
          <ReviewTableHeader
            dataKey="teacherName"
            displayName="Teacher Name"
          />
          <ReviewTableHeader
            dataKey="classesTaught"
            displayName="Classes Taught"
          />
          <ReviewTableHeader
            dataKey="beepboopsGivenPerClass"
            displayName="Beepboops per Class"
          />
          <ReviewTableHeader
            dataKey="totalNumReviews"
            displayName="Reviews Received"
          />
          <ReviewTableHeader
            dataKey="totalAverageRating"
            displayName="Average Rating"
          />
          <ReviewTableHeader
            dataKey="vipAverageRating"
            displayName="Average Rating: VIP only"
          />
          <ReviewTableHeader
            dataKey="freeAverageRating"
            displayName="Average Rating: Free only"
          />
        </tr>
      </thead>
      <tbody>
        {// If there's no data for the given dates, say so!
          (!orderedData || !orderedData.length)
            ? (
              <tr>
                <td colSpan={7}>
                No reviews found for selected dates
                </td>
              </tr>
            )
            // If we have data, render one row per teacher
            : (
              orderedData?.map((teacherReviewData, i) => (
                <ReviewRow
                  key={`reviewRow${i}`}
                  teacherReviewData={teacherReviewData}
                />
              ))
            )
        }
      </tbody>
    </table>
  );
};

export default ReviewTable;
