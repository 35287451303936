import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { getCurrentUser } from "../../../services/user";

import OneLesson from "../OneLesson";

import "./incompleteLessons.scss";

/**
 * Render incomplete lessons
 * 
 * @param { Object } lessons - date-keyed object. Values are arrays of incomplete lessons on that date.
 * @returns Markup to render incomplete lessons on home page
 */
const IncompleteLessons = ({ lessons }) => {
  // History for use in onClick function
  const history = useHistory();

  // User info from context
  const userInfo = getCurrentUser();

  // State to hold markup rendered for incomplete lessons
  const [lessonsMarkup, setLessonsMarkup] = useState(null);

  // Whenever lessons change, create the markup to display them!
  useEffect(() => {
    // Only generate markup if lessons exist
    if (lessons) {
      // Generate one OneLesson component for each lesson
      const markedUp = lessons.map((lesson) => (
        <OneLesson
          key={lesson.id}
          userIsAdmin={userInfo.isAdmin}
          lesson={lesson}
          specialType="incomplete"
          onClickFunc={() => {
            history.push(`/class/${lesson.id}`);
          }}
        />
      ));

      // Save markup in state
      setLessonsMarkup(markedUp);
    }
    else {
      // If lessons don't exist, set markup to null
      setLessonsMarkup(null);
    }
  }, [lessons]);

  return lessons && lessons.length > 0 && lessonsMarkup
    ? (
      <div className="incompleteLessons">
        <div className="incomplete-header">Incomplete Lessons</div>
        <div className="home-instructions"> These are your past lessons with incomplete post-drill notes. Click on the lesson to complete them.</div>
        <div className="incomplete-container">
          {lessonsMarkup}
        </div>
      </div>
    )
    : null;
};

export default IncompleteLessons;
