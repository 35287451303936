import React from "react";
import { talkka } from "../../../../../../../services/logging";

import { useHolodeck } from "../../../../../Holodeck";

import "./benchModal.scss";

const BenchModal = ({ closeFunc }) => {

  const {
    changeLessonTreatment,
    currentSpeakerParticipant,
    getParticipantName,
  } = useHolodeck();

  // Get participant name to use in copy
  const name = getParticipantName( currentSpeakerParticipant );

  // Helper to execute benching and close modal
  const doBenching = () => {

    // Send message to student to change treatmentType. Function returns true if message sent, false if not
    const didSucceed = changeLessonTreatment({
      participant: currentSpeakerParticipant,
      newLessonTreatment: "OBSERVER",
    });

    // If message was successfully sent, record the event with a talkka.warn
    // TODO: Handle message-sending failure. Right now it just fails silently
    if ( didSucceed ) {
      // TODO: Add lessonId here to make lookup easier. Something is funky with the lessonId in Holodeck, so if exported there and imported here it comes through as undefined. I'm omitting it to move fast and becuase we can get the lesson from the idHash on the route in the logEvent, but we should either populate it earlier in Holodeck, or manually pass it down the tree from Teach to this component
      talkka.warn(`${ name } (${ currentSpeakerParticipant.user_id }) demoted from CANDIDATE to OBSERVER`);
    }

    // Close modal
    closeFunc();
  };

  return (
    <div
      className="benchModal__container"
      onClick={ (e) => {
        if ( e.target.classList.contains("benchModal__container") ) {
          closeFunc();
        }
      }}
    >
      <div className="benchModal__inner">
        <p>
          Are you sure you want to bench { name }?
        </p>
        <p>
          { name } will not be eligible for any more Audience Turns in this Drill.
        </p>
        <div className="benchModal__buttonsContainer">
          <button
            className="benchModal__button benchModal__button--confirm"
            onClick={ doBenching }
          >
            Bench { name }
          </button>
          <button
            className="benchModal__button benchModal__button--cancel"
            onClick={ closeFunc }
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default BenchModal;
