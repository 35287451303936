import React, { useEffect, useRef } from "react";

import { useHolodeck } from "../Holodeck";

import "./viewscreen.scss";

/**
 * Renders slides iframe, handles changing of slide index in local iframe
 * 
 * @param { String } slidesURL - Path to slides.com presentation for this lesson
 * @param { Function } setSlideFrameIndex - Setter to update slideFrameIndex in
 * Teach.js based on slides.com iframe's concept of which slide is currently
 * being displayed
 * @returns slides.com iframe and containing div
 */
const Viewscreen = ({
  slidesURL,
  setSlideFrameIndex,
}) => {
  // Check if local Daily participant is muted, get message sender
  const {
    isMuted,
    slideIndex,
  } = useHolodeck();

  // Ref to attach to iframe
  const slideFrameRef = useRef();

  /*
   * USE EFFECT
   */

  // On component load, add listener for messages from slide.com iframe
  useEffect(() => {
    // Listener to update setSlideIndex
    window.addEventListener("message", listenToSlideFrameIndex);

    // Remove listener on component unmount
    return function cleanUpSlideFrameListener() {
      window.addEventListener("message", listenToSlideFrameIndex);
    };
  }, []);
  
  // React when slide index changes
  useEffect(() => {
    // Only execute if iframe is loaded, as indicated by ref.current being truthy
    if (slideFrameRef.current) {
      // Send message to iframe with updated slide index
      slideFrameRef.current.contentWindow.postMessage(JSON.stringify({
        method: "slide",
        args: [slideIndex],
      }), "*");
    }
  }, [ slideIndex ]);

  // Update module when slidesURL changes, but without adding an entry in browser history (fix back button changing displayed module bug)
  useEffect(() => {
    // If slidesURL is null (no module assigned to lesson yet), don't do anything!
    if ( !slidesURL ) {
      return;
    }

    // Use location.replace to avoid stacking an entry in browser history
    slideFrameRef.current?.contentWindow?.location.replace(`${slidesURL}&style=hidden&postMessageEvents=true&controls=false`);
  }, [ slidesURL ]);

  /*
   * LISTENERS
   */

  // Keep concept of the actual index of the slide displayed in the iframe up to date
  const listenToSlideFrameIndex = (event) => {
    /* 
     * Slides.com messages have data objects that are JSON strings
     * Daily messages have data objects that are JS objects or empty strings
     * So a message that is a non-empty string must come from slides.com
     */
    if (typeof event.data === "string" && event.data.length > 0) {
      try {
        // Extract data from JSON string
        const data = JSON.parse(event.data);
  
        // Double-check that this is really a slides.com event
        if (data.namespace === "reveal") {
          // Set slides.com concept of displayed slide index in state
          setSlideFrameIndex(data.state?.indexh);
        }
      }
      catch {
        console.log("Message with data string not valid JSON");
      }
    }
  };

  return (
    <div className={`viewscreen${isMuted ? " muteWarning" : ""}`}>
      <div className="screenProtector" />
      {// Only render iframe if we have a valid slidesURL!
      // iframe has no src! It's handled with locaion.replace in slidesURL useEffect 
        slidesURL
          ? (
            <iframe
              ref={slideFrameRef}
              title="slide"
              scrolling="no"
              frameBorder="0"
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
              allowFullScreen
              className="slidesFrame"
            />
          )
          : (
            <div>
              NO MODULE SELECTED.<br />
              Select a module for this lesson in the menu.
            </div>
          )

      }
      
    </div>
  );
};

export default Viewscreen;
