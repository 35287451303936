import React, {
  useEffect,
  useMemo,
  useState,
} from "react";

import { getFeedbackForLoggedInTeacher } from "../../services/datastore";

import FeedbackDetailModal from "./FeedbackDetailModal";
import RatingStars from "./RatingStars/RatingStars";

import "./classFeedbackWidget.scss";

/**
 * Renders the "Feedback for the past week" box at the top of the homepage, and
 * the feedback detail modal that pops up when the user clicks on the box
 */
const ClassFeedbackWidget = () => {

  /*
   * STATE
   */

  // Loading flag
  const [ loading, setLoading ] = useState( true );

  // Feedback object from back end
  const [ feedback, setFeedback ] = useState( null );

  // Show or hide modal with feedback detail
  const [
    showFeedbackDetailModal,
    setShowFeedbackDetailModal,
  ] = useState( false );

  /*
   * USE EFFECT
   */

  // On load, fetch feedback for the current teacher
  useEffect(() => {
    getFeedback();
  }, []);

  /*
   * MEMOIZED VALUES
   */

  const averageRating = useMemo(() => (
    feedback?.averages?.averageRating
      ? Math.round( Number(feedback.averages.averageRating) * 100 ) / 100
      : null
  ), [ feedback ]);

  const numRatings = useMemo(() => (
    feedback?.averages?.totalRatingsGiven
      ? feedback.averages.totalRatingsGiven
      : null
  ), [ feedback ]);

  /*
   * HELPERS
   */

  // Async helper to fetch feedback info
  const getFeedback = async () => {
    // Not passing start or end dates causes CF to return default range of past 7 days
    const fetchedFeedback = await getFeedbackForLoggedInTeacher();
    setFeedback( fetchedFeedback );

    setLoading( false );
  };

  /*
   * RENDER
   */

  return (
    <section
      className="classFeedbackWidget"
      onClick={() => setShowFeedbackDetailModal( prev => !prev )}
    >
      {loading
        ? <>Loading...</>
        : (
          <>
            <h2 className="classFeedbackWidget__heading">
              Average rating for the past 7 days
            </h2>
            <RatingStars
              rating={averageRating}
              ratingCount={numRatings}
              showDetail
            />
          </>
        )
      }
      <FeedbackDetailModal
        feedback={ feedback }
        showModal={ showFeedbackDetailModal }
      />
    </section>
  );
};

export default ClassFeedbackWidget;
