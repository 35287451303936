import React from "react";
import { useHolodeck } from "../../Holodeck/Holodeck"; // Import useHolodeck directly
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faBellSlash } from "@fortawesome/free-solid-svg-icons"; // Import bell icons
import useJoinSound from "../../../../hooks/useJoinSound";
import "./joinSoundButton.scss";

const JoinSoundButton = () => {
  const { allParticipants } = useHolodeck(); // Get allParticipants directly
  const { enableSound, soundEnabled } = useJoinSound(allParticipants);

  return (
    <div className="communicator__joinSoundButtonContainer">
      <div
        className={`communicator__joinSoundButton${!soundEnabled ? " negativeState" : ""}`}
        role="button"
        onClick={() => enableSound(!soundEnabled)}
      >
        <FontAwesomeIcon icon={soundEnabled ? faBell : faBellSlash} />
        <div className="joinSoundButton__shortcutHint"></div>
      </div>
    </div>
  );
};

export default JoinSoundButton;
