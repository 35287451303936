import React, { Component } from "react";

/**
 * Component to display students & allow editing of their attendance status, beepboop count, and call count
 * @prop {Array} students - lesson students array to display
 * @prop {function} changeStudentInfo [fn(email, attendance_status, bbCount, callCount)] - function to change student information
 */
class ClassStudentList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      students: props.students,
    };
  }
  render() {
    if (this.state.students.length !== 0) {
      return (
        <div className="lessonReportWebDrillStudentList">
          <span className="studentNameLabel">Participants</span>
          <span className="bbCountLabel">BBs</span>
          <span className="bienCountLabel">Biens</span>
          {/* <span className="feedbackLabel">Feedback</span> */}
          {this.state.students.map((student) => {
            if (student && ["ATTENDED-SPEAKER", "ATTENDED-CANDIDATE-SPEAKER", "ATTENDED-OBSERVER"].includes(student.lessonAttendanceStatus)) {
              return (
                <React.Fragment key={student.studentId}>
                  <span className="studentName">{student.firstName}&nbsp;{student.lastName && student.lastName[0]}</span>
                  <span className="bbCount">{student.numIncorrect}</span>
                  <span className="bienCount">{student.numCorrect}</span>
                  {/* <input
                    type="text"
                    className="feedbackInput"
                    value={student.feedback || ""}
                    onChange={(e) => {
                      const updatedStudents = this.state.students.map((s) => {
                        if (s.studentId === student.studentId) {
                          return { ...s, feedback: e.target.value };
                        }
                        return s;
                      });
                      this.setState({ students: updatedStudents });
                    }}
                  /> */}
                </React.Fragment>
              );
            }
          })}
        </div>
      );
    }
    else {
      return <div>No students registered.</div>;
    }
  }
}

export default ClassStudentList;
