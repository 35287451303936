import React, { useState } from "react";

import { updateLessonModuleById } from "../../../services/datastore";

import LoadingOverlay from "../../../components/LoadingOverlay";
import ModuleSearch from "../../../components/ModuleSearch";

import "./changeModuleModal.scss";

/**
 * Modal shown on ClassDetail page to change the module for the lesson
 *
 * @param { CallableFunction } exitFunc - called to close this modal
 * @param { String } lessonId - of lesson for which we're changing module
 * @param { Object } module - Firestore object for module currently assigned to
 * given lesson
 * @param { boolean } showChangeModuleModal - true to display this component
 */
const ChangeModuleModal = ({
  exitFunc,
  languageTarget,
  lessonId,
  module,
  showChangeModuleModal,
}) => {

  const [ loading, setLoading ] = useState( false );

  const [ newModuleId, setNewModuleId ] = useState( null );

  const closeOnBackgroundClick = ( event ) => {
    if (
      event.target.classList.contains("classDetail__changeModuleModalOverlay")
    ) {
      exitFunc();
    }
  };

  return showChangeModuleModal
    ? (
      <div
        className="classDetail__changeModuleModalOverlay"
        onClick={ closeOnBackgroundClick }
      >
        <div className="classDetail__changeModuleModalInner">
          <div className="changeModuleModal__currentModule">
            {// Show module info, or notify that not yet selected
              module
                ? `Currently selected module: ${module.difficulty} ${module.unit_name} ${module.unit_number}: ${module.lesson_name} (${module.category})`
                : "No module selected"
            }
          </div>
          <div>
            Select new module:
          </div>
          <ModuleSearch
            module={ module }
            // We don't need to use the clearModule functionality of the ModuleSearch, but need to pass something to avoid a console error
            clearModule={ () => {} }
            moduleWasSelected={
              ( selectedModuleId ) => setNewModuleId( selectedModuleId )
            }
            omitWelcome={ false }
            omitComingSoon={ true }
            limitLanguageTo={ languageTarget }
          />
          <div className="changeModuleModal__buttonsContainer">
            <button
              className="changeModuleModal__button"
              onClick={ async () => {
                setLoading( true );
                await updateLessonModuleById(
                  lessonId,
                  newModuleId,
                );
                // Reload to force re-fetch of lesson, so updated module is reflected in lesson summary
                window.location.reload();
              } }
              role="button"
            >
            Change Module
            </button>
            <button
              className="changeModuleModal__button changeModuleModal__button--cancel"
              onClick={ exitFunc }
              role="button"
            >
            Cancel
            </button>
          </div>
        </div>
        <LoadingOverlay isVisible={ loading } />
      </div>
    )
    : null;
};

export default ChangeModuleModal;
