import React from "react";

// import local styles
import "./lessonsFilter.scss";

/**
 * Currently only renders a filter allowing Admins to filter lessons by teacher
 * 
 * @param { Array } allTeachers - array of teacher preferred_name's
 * @param { Array } selectedTeachers - array of selected teacher preferred_name's - from state in pages/Home/index.js
 * @param { Function } setSelectedTeachers - to update selectedTeachers in homepage state
 * @param { Function } updateQuerystringAndHistory - to update querystring and browser history
 * @returns markup for teacher selection form
 */
const LessonsFilter = ({ 
  allTeachers, 
  selectedTeachers, 
  setSelectedTeachers, 
  updateQuerystringAndHistory,
}) => {
  // Create list of teachers to select
  const options = allTeachers.map((name, index) => {
    // Render markdown for one teacher option
    return (
      <option key={`teacherFilter-${index}`} value={name}>
        {name}
      </option>
    );
  });

  /**
   * Handles clicks on teacher select.
   * Using onClick instead of onChange because onChange doesn't fire when there
   * is only one teacher selected, and that teacher is clicked again.
   */
  const handleClick = (event) => {
    // Get value of clicked option
    const clicked = event.target.value;

    // New array to hold updated selectedTeachers
    let newSelectedTeachers = [];
    
    // If "SHOW ALL" was clicked, we always want to show all
    if (clicked === "ALL") {
      // false indicates that all teachers are selected
      newSelectedTeachers = false;
    }
    
    // If clicked teacher is already in the array, remove them!
    else if (selectedTeachers && selectedTeachers.includes(clicked)) {
      // Remove the clicked teacher from the selected teacher array
      // If teacher was the only one in the array, set to false
      newSelectedTeachers = selectedTeachers.length === 1
        ? false
        : selectedTeachers.filter(t => t !== clicked);
    }

    // If clicked teacher wasn't already in the array, add them!
    else {
      // Check if selectedTeachers already is an array...
      newSelectedTeachers = selectedTeachers
        // if so, add the selected teacher
        ? [...selectedTeachers].concat([clicked])
        // if not, just create an array with the clicked teacher
        : [clicked];
    }

    // Set the updated array in state
    setSelectedTeachers(newSelectedTeachers);

    // update querystring with selected teachers
    updateQuerystringAndHistory("selectedTeachers", newSelectedTeachers);
  };

  // Add "show all" option to top of list
  // This option should show as selected only if no teachers are selected
  options.unshift(<option key="teacherFilter-reset" value="ALL">SHOW ALL</option>);

  // If no selected teachers, default value to all
  const selectedValue = selectedTeachers ? selectedTeachers : ["ALL"];

  // The readOnly prop on the select is to disable a warning that the component needs an onChange handler. See explanation in handleClick.
  return (<select multiple className="filterTeachers" onClick={handleClick} value={selectedValue} readOnly>{options}</select>);
};

export default LessonsFilter;
