import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Find icons to import at https://fontawesome.com/v5.15/icons
import {
  faArrowRight,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";

import { useEventListener } from "../../../hooks/useEventListener";
import { enableSwipedEvents } from "../../../services/swipedEvents";

import { useHolodeck } from "../Holodeck";

/**
 * Renders arrows to navigate
 * 
 * @param { Number } slideFrameIndex - Slide index as reported by slides.com
 * iframe. Should (but doesn't always) match slideIndex
 * @returns markup for command station
 */
const SlideArrows = ({ slideFrameIndex }) => {

  /*
   * CONTEXT
   */

  // Get slideIndex and setter from context
  const {
    allowShortcuts,
    slideIndex,
    setSlideIndex,
  } = useHolodeck();

  /*
   * HOOKS
   */

  // Enable swipe events via custom hook
  enableSwipedEvents();

  // Use custom hook to manage keyboard shortcuts
  useEventListener({
    listenerShouldFire: allowShortcuts,
    listener: (event) => {
      // Right arrow goes forward
      if ( event.key === "ArrowRight" ) {
        incrementSlideIndex();
      }
      // Left arrow goes backward
      else if ( event.key === "ArrowLeft" ) {
        decrementSlideIndex();
      }
    },
  });

  // Use custom hook to manage swipe events
  useEventListener({
    // Custom swiped event created by enableSwipedEvents
    eventType:"swiped",
    listenerShouldFire: allowShortcuts,
    listener: (event) => {
      // Only listen to swipes on Viewscreen (screenProtector laid over top intercepts event)
      if ( !event.target.classList.contains("screenProtector") ) return;
  
      // If swipe was on Viewscreen, handle it!
      if ( event.detail.dir === "left" ) {
        // Left swipe goes forward
        incrementSlideIndex();
      }
      else if ( event.detail.dir === "right" ) {
        // Right swipe goes back
        decrementSlideIndex();
      }
    },
  });

  /*
   * HELPERS
   */

  // Go "back" or "left", but not past the beginning of the presentation
  const decrementSlideIndex = () => {
    // slideIndex is zero-indexed, so we can only go back if it's 1 or more
    if (slideIndex > 0) {
      setSlideIndex(slideIndex - 1);
    }
  };

  // Go "forward" or "right", but not past the end of the presentation
  // Current logic allows slideIndex to be 1 greater than slideFrameIndex
  // TODO: fix it! (gets complex)
  const incrementSlideIndex = () => {
    // Increment slideIndex
    setSlideIndex(slideIndex + 1);

    // Check to see if slideIndex has advanced, but slides.com iframe hasn't
    if (slideIndex > slideFrameIndex) {
      // If it has, we've gone past the end of the presentation. So reset!
      setSlideIndex(slideFrameIndex);
    }
  };

  /*
   * RENDER
   */

  return (
    <div className="slideArrows">
      <FontAwesomeIcon
        icon={faArrowLeft}
        onClick={decrementSlideIndex}
        className="topArrows__icon"
      />
      <FontAwesomeIcon
        icon={faArrowRight}
        onClick={incrementSlideIndex}
        className="topArrows__icon"
      />
    </div>
  );
};

export default SlideArrows;
