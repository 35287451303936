import React from "react";
import { useHistory } from "react-router-dom";

// Component to display one lesson
import OneLesson from "../OneLesson";

// Components to display stats about lessons in the group
import FutureLessonsStats from "./FutureLessonsStats";
import PreviousLessonsStats from "./PreviousLessonsStats";

// Local styles
import "./lessonGroup.scss";

/**
 * Component to display a group of lessons
 *
 * @param { Array } lessons - lesson objects in the group
 * @param { JSX } header [optional] - markup to be displayed at the top of the group
 * @param { JSX } footer [optional] - markup to be displayed at the bottom of the group
 * @param { Boolean } userIsAdmin [optional] - flag indicating if logged-in user is admin
 * @param { Array } allTeachers [optional] - array of teacher names for use in changing teacher for lesson
 * @param { CallableFunction } onClickFunc - function to execute by default when a lesson is clicked
 * @param { Boolean } showCompletionStatus [optional] - flag indicating if status of PDN (complete or not) should be displayed on lessons
 * @param { Boolean } disableTeacherChange [optional] - flag indicating if ability to change teachers should be disabled for these lessons
 * @param { Boolean } enableFutureLessonsStats - flag indicating if FutureLessonsStats should be enabled (requires `userIsAdmin = true`)
 * @param { Boolean } enablePreviousLessonsStats - flag indicating if PreviousLessonsStats should be enabled (requires `userIsAdmin = true`)
 * @param { Function } updateLessonTeacher - used to set new teacher on a lesson object in homepage state
 */
const LessonGroup = ({
  lessons = false,
  header = null,
  footer = null,
  userIsAdmin = false,
  allTeachers = [],
  onClickFunc = false,
  showCompletionStatus = false,
  disableTeacherChange = false,
  enableFutureLessonsStats = false,
  enablePreviousLessonsStats = false,
  updateLessonTeacher = () => {},
}) => {
  // Grab history to use in default onClickFunc default
  const history = useHistory();

  return (
    <>
      {header}
      {userIsAdmin && enablePreviousLessonsStats && <PreviousLessonsStats lessons={lessons} />}
      {lessons && lessons.map((lesson) => (
        <OneLesson
          key={lesson.id}
          userIsAdmin={userIsAdmin}
          allTeachers={allTeachers}
          lesson={lesson}
          onClickFunc={onClickFunc || function clicker() {
            history.push(`/class/${lesson.id}`);
          }}
          showCompletionStatus={showCompletionStatus}
          disableTeacherChange={disableTeacherChange}
          updateLessonTeacher={updateLessonTeacher}
        />
      ))}
      {userIsAdmin && enableFutureLessonsStats && <FutureLessonsStats lessons={lessons} />}
      {footer}
    </>
  );
};

export default LessonGroup;
