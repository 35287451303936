import React, { useState, useEffect } from "react";

import { claimLesson } from "../../../services/datastore";

import OneLesson from "../OneLesson";
import ClaimLessonModal from "./ClaimLessonModal";

import "./UnclaimedLessons.scss";

/**
 * Render unclaimed lessons, and control flow of lesson claiming from homepage
 *
 * @param { Array } lessons - all unclaimed lessons present today or forward in database
 * @returns markup to display unclaimed lessons and lesson-claiming modal
 */
const UnclaimedLessons = ({ lessons }) => {
  // State to hold markup for unclaimed lessons
  const [lessonsMarkup, setLessonsMarkup] = useState(null);

  // State to hold clicked unclaimed lesson (for attempt to claim it)
  const [clickedLesson, setClickedLesson] = useState(null);

  // State to show or hide the lesson-claiming modal
  const [showModal, setShowModal] = useState(false);

  // State to indicate if modal should show success message, or do claiming
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  // Whenever lessons change, create the markup to display them!
  useEffect(() => {
    // Only generate markup if lessons exist
    if (lessons) {
      // Generate one OneLesson component for each lesson
      const markedUp = lessons.map((lesson) => (
        <OneLesson
          key={lesson.id}
          lesson={lesson}
          specialType="unclaimed"
          onClickFunc={() => {
            // Indicate which lesson was clicked in state
            setClickedLesson(lesson);
            // Show the lesson-claiming modal
            setShowModal(true);
          }}
        />
      ));

      // Save markup in state
      setLessonsMarkup(markedUp);
    }
    else {
      // If lessons don't exist, set markup to null
      setLessonsMarkup(null);
    }
  }, [lessons]);

  // Function to close modal
  // didClaimLesson is a flag for if the click that closed the modal was also the click to claim the lesson
  const closeModalFunc = (didClaimLesson = false) => {
    // Hide the modal only if the click didn't claim the lesson
    setShowModal(didClaimLesson);

    // Show success message only if the click did claim the lesson
    setShowSuccessMessage(didClaimLesson);
  };

  // Function to claim a lesson
  const claimUnclaimedLesson = () => {
    // Close the modal, with lesson-claiming flag set to true
    closeModalFunc(true);

    // Claim the lesson in the database
    // We know that clickedLesson is the lesson that was clicked to open the modal
    claimLesson(clickedLesson);
  };

  // Only render if we have unclaimed lessons, and the markup is ready
  return lessons.length > 0 && lessonsMarkup
    ? (
      <div className="unclaimedLessons">
        <div className="unclaimed-header">Unclaimed Lessons</div>
        <div className="home-instructions">Click a lesson time to claim it as yours!</div>
        <div className="unclaimed-container">
          {lessonsMarkup}
        </div>
        {clickedLesson && (
          <ClaimLessonModal
            showModal={showModal}
            lesson={clickedLesson}
            showSuccessMessage={showSuccessMessage}
            closeModalFunc={closeModalFunc}
            claimLessonFunc={claimUnclaimedLesson}
          />
        )}
      </div>
    )
    : null;
};

export default UnclaimedLessons;
