import React from "react";

import "./loadingOverlay.scss";

/**
 * Covers screen with transparent background and centered loading message
 * 
 * @param { Boolean } isVisible - true to show overlay, false to not!
 */
const LoadingOverlay = ({ isVisible }) => {
  // Don't render if not visible
  if ( !isVisible ) return null;

  // If visible, cover page!
  // TODO: Make this look better
  return (
    <div className="loadingOverlay">
      <div className="loadingOverlay__content">
        Loading...
      </div>
    </div>
  );
};

export default LoadingOverlay;
