import React from "react";

import { getNYCtime, getNYCdate } from "../../services/time";

/**
 * Ticking clock that always shows current NYC time
 *
 * @returns markup for clock
 */
const Clock = () => {
  // Get time & date from context
  const NYCtime = getNYCtime();
  const NYCdate = getNYCdate(null, "weekday");

  return (
    <div className="nyc-clock">
      <div className="nyc-clock__label">NYC time</div>
      <div className="nyc-clock__time">{NYCtime}</div>
      <div className="nyc-clock__date">{NYCdate}</div>
    </div>);
};

export default Clock;
