// Context to get current NYC time

import React, {
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

// Create a context to use for NYC time throughout the app
const TimeContext = createContext();

// Helper to compute current NYC time based on current machine time
// WARNING: If user has wrong time set on local machine, this will show wrong NYC time.
const tick = () => {
  // Options for formatter
  const options = {
    timeZone: "America/New_York",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hourCycle: "h23",
  };

  // Formatter to use with built-in internationalization API to get NYC time
  const formatter = new Intl.DateTimeFormat("en-US-u-h24", options);

  // return the current system time
  return formatter.format(new Date());
};

// Helper to generate string representing current NYC date in MM/DD/YYYY format
const generateDateString = () => {
  // Return formatted date string for today's date in NYC
  return (new Date()).toLocaleString("en-US", {
    timeZone: "America/New_York",
    month: "2-digit",
    day: "2-digit",
    year: "numeric"});
};

/**
 * Generates markup to provide time context to all components in app
 *
 * @param {*} children - default React prop representing JSX children of rendered element
 * @returns Markup to wrap children in time provider
 */
export const TimeProvider = ({ children }) => {
  // Set time to track current NYC time
  const [ time, setTime ] = useState( tick() );

  // State to track current NYC date
  const [ date, setDate ] = useState( generateDateString() );

  // State to hold ISO string of current UTC time
  const [ nowUTC, setNowUTC ] = useState( (new Date()).toISOString() );

  // On component mount, start the clock ticking
  useEffect(() => {
    // Set interval for clock tick, and remember the reference
    const tickRef = window.setInterval(() => {
      setTime(tick());
    }, 1000);

    // Return cleanup function to stop the ticking
    return function cleanup() {
      window.clearInterval(tickRef);
    };
  }, []);

  // Update the date when the clock hits midnight
  useEffect(() => {
    if (time === "00:00:00") {
      setDate(generateDateString());
    }
    // Also update the UTC ISO timestamp with every tick
    setNowUTC( (new Date()).toISOString() );
  }, [time]);


  return (
    <TimeContext.Provider value={{
      NYCtime: time,
      NYCdate: date,
      nowUTC,
    }}>
      {children}
    </TimeContext.Provider>
  );
};

// Export function to get NYC time
export const getNYCtime = () => {
  // Grab context to use
  const context = useContext(TimeContext);
  // If no valid context, return false
  if (context === undefined) {
    return false;
  }
  // Otherwise, return context value!
  return context.NYCtime;
};

/**
 * Export function to get date string in NYC time
 * By default, gets date for this instant in NYC
 * Can optionally pass Date object for instant to calculate NYC date
 * 
 * @param { Date, String } dateObject [optional] - JS Date object to convert to NYC date string, or string
 * @param { String } format [optional] - string describing format in which to return date (see formatDateString)
 * @returns { String } in format MM/DD/YYYY for date in NYC
 */
export const getNYCdate = (date = false, format = false) => {
  // If string was supplied for date, convert it to date object
  const dateObject = typeof date === "string" ? new Date(date) : date;

  // If date is supplied, get NYC date for the time it represents
  if (dateObject) {// Options to pass for date formatting
    const dateOptions = {
      timeZome: "America/New_York",
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    };

    // Generate MM/DD/YYYY date string for today's date in NYC
    const usFormatDate = dateObject.toLocaleDateString("en-US", dateOptions);
  
    // Return date string in specified format
    return format ? formatDateString(usFormatDate, format) : usFormatDate;
  }

  // If no dateObject is provided, return current NYC date per context
  const context = useContext(TimeContext);
  // If no valid context, return false
  if (context === undefined) {
    return false;
  }
  // Otherwise, return context value!
  return format ? formatDateString(context.NYCdate, format) : context.NYCdate;
};

// Export function to directly use time context and values
export const useTime = () => useContext( TimeContext );

// Takes a date string in same format as NYCdate (MM/DD/YYYY), returns same date in indicated format
export const formatDateString = (dateString, format) => {
  // Early return if not in desired format
  if (!/^\d{2}\/\d{2}\/\d{4}$/.test(dateString)) {
    console.error("Invalid date string passed to formatDateString");
    return dateString;
  }

  switch (format.toLowerCase()) {
  // ISO format is YYYY-MM-DD
    case "iso":
    // Split pieces of date on "/"
      const [m, d, y] = dateString.split("/");
      // Return in ISO format for date
      return `${y}-${m}-${d}`;

    // Will return "[weekday], [month] [date]"
    case "weekday":
    // Use provided string to get Date object
      const date = new Date(dateString);

      // Set options for formatting locale string
      const options = {
        weekday: "long",
        month: "long",
        day: "numeric",
      };

      // Return formatted date string!
      return date.toLocaleDateString("en-US", options);
  }

  // By default, return string unchanged
  return dateString;
};
