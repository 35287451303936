import { useEffect } from "react";

import { talkka } from "../services/logging";

/**
 * Manage event listeners attached to the document
 * 
 * @param { String } eventType - type of event to listen for. Same as string
 * you would pass in to .addEventListener()
 * @param { CallableFunction } listener - to fire on event. Has access to event.
 * @param { Boolean } listenerShouldFire [optional] - Controls whether or not
 * listener is active. If not given, listener will trigger on all events of
 * given type
 */
export const useEventListener = ({
  eventType = "keydown",
  listener,
  listenerShouldFire = true,
}) => {

  // When inputs change, re-attach listeners!
  useEffect(() => {

    // Only attach listener if we're in a state where it should fire
    if ( listenerShouldFire ) {

      // Errors thrown in event listeners don't get automatically caught by our ErrorBoundary - so dispatch a talkka.error here if the listener triggers an error!
      const wrappedListener = (event) => {
        try {
          listener(event);
        }
        catch (error) {
          talkka.error(error);
        }
      };

      // Attach listener
      document.addEventListener( eventType, wrappedListener );

      // Clean up listener on unmount (or between re-renders)
      return function keyboardShortcutCleanup() {
        document.removeEventListener( eventType, wrappedListener );
      };
    }
  }, [
    eventType,
    listener,
    listenerShouldFire,
  ]);
};
