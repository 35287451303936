import React from "react";

import Header from "../Header";

import "./errorFallback.scss";

/**
 * Component that is rendered when an unhandled error bubbles up to our
 * ErrorBoundary (previously would cause a WSOD)
 *
 * @param { error } error that caused this component to render
 * @param { CallableFunction } resetErrorBoundary - Function that attempts to clear error state and restart application. Passed to ErrorBoundary as onReset in app.js
 * @returns 
 */
const ErrorFallback = ({
  error,
  resetErrorBoundary,
}) => {
  
  return (
    <>
      <Header errorFallback />
      <div className="errorFallback">
        <main>
          <h1>¡Huy!</h1>
          <p>
            Something went wrong!
          </p>
          <p className="errorFallback__errorMessage">

            { error?.stack }
          </p>
          <p>
            If you continue to get this message, please take a screenshot of this page and post it in the problemas-tecnológicos channel on Slack so the engineering team can investigate.
          </p>
          <button
            onClick={ resetErrorBoundary }
          >
            Try again
          </button>
        </main>
      </div>
    </>
  );
};

export default ErrorFallback;
