/**
   * Given a Daily Participant object, returns the number of Audience Turns
   * that participant is currently eligible to receive in this Drill
   * 
   * @param { DailyParticipant } participant - from which to extract info
   * @returns { Number } of Audience Turns that the participant can receive
   */
export const getParticipantAudienceTurnsRemaining = ( participant ) => {
    
  // A nonexistent participant gets no Audience Turns!
  // The back-end protects against non-Candidates having Audience Turns, but protect again here, to be safe
  if (
    !participant
    || !getParticipantTreatmentType( participant ).startsWith("CANDIDATE")
  ) {
    return 0;
  }

  // Extract info from user_name JSON string
  const { audienceTurnLimit } = JSON.parse( participant.user_name );

  // Return numeric value
  return Number( audienceTurnLimit );
};

/**
   * Given a Daily Participant object, returns the display name for that
   * participant (first name and last initial from Firestore)
   * 
   * @param { DailyParticipant } participant - from which to extract name
   * @returns { String } Display name of participant
   */
export const getParticipantName = ( participant ) => {
  // If no valid object passed in, return empty string
  if ( !participant ) return "";
  
  // If participant is instructor, slice out name from user_name string
  if ( participant.user_name?.startsWith("instructor-") ) {
    return participant.user_name.slice(11);
  }

  // Otherwise, extract name from JSON string in user_name and return
  const { name } = JSON.parse(participant.user_name);
  return name ? name : "";
};

/**
 * 
 * @param { DailyParticipant } participant - from which to extract
 * lessonTreatmentType
 * @returns { String } SPEAKER, OBSERVER, or INSTRUCTOR
 */
export const getParticipantTreatmentType = ( participant ) => {
  // If no valid object passed in, return empty string
  if ( !participant ) return "";

  // If participant is instructor, return "INSTRUCTOR"
  if ( participant.user_name?.startsWith("instructor-") ) {
    return "INSTRUCTOR";
  }

  // Otherwise, extract treatment type string and return
  const { lessonTreatmentType } = JSON.parse(participant.user_name);
  return lessonTreatmentType ? lessonTreatmentType : "";
};
