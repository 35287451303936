import React, {
  useEffect,
  useState,
} from "react";
import { useHistory } from "react-router-dom";

import { useTime } from "../../../services/time";

import "./classStartArea.scss";

/**
 * Renders button to start class when room is available, and timers to class
 * start or end (as appropriate)
 *
 * @param { String } idHash - of lesson to start
 * @param { boolean } lessonIsKilled - true if lesson has been killed
 * @param { String } lessonScheduledEndUTC - ISO UTC string for lesson end time
 * @param { String } lessonScheduledStartUTC - ISO UTC string for lesson start
 * @param { String } roomOpensUTC - ISO UTC string for Daily room open time
 * @param { String } roomClosesUTC - ISO UTC string for Daily room close time
 */
const ClassStartArea = ({
  idHash,
  lessonIsKilled,
  lessonScheduledEndUTC,
  lessonScheduledStartUTC,
  roomOpensUTC,
  roomClosesUTC,
}) => {

  /*
   * HOOKS
   */

  const history = useHistory();

  const { nowUTC } = useTime();

  /*
   * STATE
   */

  // Room open & close refer to the Daily room start (nbf) and end (exp) values
  const [ isBeforeRoomOpens, setIsBeforeRoomOpens ] = useState( true );
  const [ isAfterRoomCloses, setIsAfterRoomCloses ] = useState( false );
  
  // Class start & end refers to the scheduled start and end times for the class, as indicated by lessonScheduledStartUTC and lessonScheduledEndUTC
  const [ isBeforeClassStarts, setIsBeforeClassStarts ] = useState( true );
  const [ isAfterClassEnds, setIsAfterClassEnds ] = useState( false );

  /*
   * USE EFFECT
   */

  // As clock ticks, compare UTC time to room open and close times
  useEffect(() => {
    setIsBeforeRoomOpens( nowUTC < roomOpensUTC );
    setIsAfterRoomCloses( nowUTC > roomClosesUTC );
    
    setIsBeforeClassStarts( nowUTC < lessonScheduledStartUTC );
    setIsAfterClassEnds( nowUTC > lessonScheduledEndUTC );

  }, [ nowUTC ]);

  /*
   * HELPERS
   */

  /**
   * Given two ISO strings, returns the time in human-readable mm:ss format
   * between the two. If difference is greater than an hour, gives "over X
   * hours" string
   *
   * Math geek reference: minuend - subtrahend = difference
   *
   * @param { String } minuendUTC - ISO string of time to subtract from
   * @param { String } subtrahendUTC - ISO string of time to be subtracted
   * @returns difference between given times, in mm:ss format
   */
  const getHumanReadableTimeDifference = ( minuendUTC, subtrahendUTC ) => {
    // Get the difference between the provided times in seconds, rounded down
    // Use Math.abs to always return a positive value, even if minuend and subtrahend are switched
    const diffSecs = Math.abs(
      Math.floor(
        ( new Date(minuendUTC) - new Date(subtrahendUTC) )
        / 1000,
      ),
    );

    const mins = Math.floor( diffSecs / 60 );

    // If the difference is an hour or more, just show hours!
    if ( mins > 59 ) {
      const hours = Math.floor( mins / 60 );
      return `over ${ hours } hour${ hours === 1 ? "" : "s" }`;
    }

    // If difference is under an hour, calculate seconds to show mm:ss
    const secs = diffSecs % 60;

    // Add leading zeroes to numbers < 10
    const displayMins = mins < 10 ? `0${mins}` : mins;
    const displaySecs = secs < 10 ? `0${secs}` : secs;
    
    return `${displayMins}:${displaySecs}`;
  };

  /*
   * RENDER
   */

  return (
    <section className="classDetail__classStartArea">
      {// Can't start a killed drill
        lessonIsKilled
          ? (
            <p className="classDetail__classIsKilled">
              This class has been killed
            </p>
          )
          // Before room open, show message
          : isBeforeRoomOpens
            ? <p>The room for this class is not yet ready</p>
            // After room closes, show message
            : isAfterRoomCloses
              ? <p>The room for this class has closed</p>
              // While room is open, show button
              : (
                <button
                  className="classDetail__classStartButton"
                  onClick={ () => history.push(`/teach/${idHash}`)}
                >
                  JOIN CLASS
                </button>
              )
      }
      <p>
        {// Nothing to show for killed class
          lessonIsKilled
            ? null
            // Show countdown to class start or end
            : isBeforeClassStarts
              ? `Class will start in ${
                getHumanReadableTimeDifference( lessonScheduledStartUTC, nowUTC )
              }`
              : !isAfterClassEnds
                ? `Class will end in ${
                  getHumanReadableTimeDifference( nowUTC, lessonScheduledEndUTC )
                }`
                : "Class is over"
        }
      </p>
    </section>
  );
};
export default ClassStartArea;
