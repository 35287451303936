import React, {
  useEffect,
  useState,
} from "react";
import {
  useHistory,
  useLocation,
} from "react-router-dom";

import "./wordBundleSearch.css";

/**
 * Use given search map (keys are search strings, values are objects with
 * bundle name and id) to identify bundles associated with user-input query
 *
 * @param { Object } searchMap - with all info needed to execute search
 */
const WordBundleSearch = ({
  searchMap,
  selectedBundleId,
}) => {

  const history = useHistory();

  const location = useLocation();

  /*
   * STATE
   */

  // Array of search strings for all bundles -- used to identify which bundles' info to grab from the search map
  const [ searchStrings, setSearchStrings ] = useState( [] );

  // Text entered in search field, to compare against searchStrings
  const [ query, setQuery ] = useState( "" );

  const [ queryResults, setQueryResults ] = useState( null );

  /*
   * USE EFFECT
   */

  // When bundle is selected, persist entry in search field
  useEffect(() => {
    if ( location?.state?.query ) {
      setQuery( location.state.query );
    }
  }, [ location ]);

  // Keep search strings up to date from searchMap info
  useEffect(() => {
    if ( searchMap ) {
      setSearchStrings( Object.keys(searchMap) );
    }
    else {
      setSearchStrings( [] );
    }
  }, [ searchMap ]);

  // As query updates, update which bundles match it
  useEffect(() => {
    if ( query && searchStrings && searchMap ) {
      const matchedStrings = searchStrings.filter(s => (
        s.toLowerCase().includes(query.toLowerCase())
      ));
      setQueryResults(
        matchedStrings
          .map(s => searchMap[s])
          .sort((a,b) => a.name.localeCompare(b.name)),
      );
    }
    else {
      setQueryResults( null );
    }
  }, [
    query,
    searchMap,
    searchStrings,
  ]);

  /*
   * RENDER
  */

  return (
    <section className="manageWordBundles__wordBundleSearch">
      <label
        className="wordBundleSearch__searchLabel"
        htmlFor="bundleSearch"
      >
        Search Word Bundles:
      </label>
      <input
        autoFocus
        className="wordBundleSearch__searchField"
        name="bundleSearch"
        type="text"
        value={ query }
        onChange={ (e) => setQuery( e.target.value ) }
      />
      { // Only show matched bundles if we have 'em
        queryResults
        && (
          <ul className="wordBundleSearch__resultsList">
            {
              queryResults.map( bundle => (
                <li key={ bundle.id }>
                  <button
                    className={`wordBundleSearch__resultButton ${selectedBundleId === bundle.id ? "selected" : ""}`}
                    onClick={() => history.push({
                      search: `id=${bundle.id}`,
                      state: {
                        query,
                      },
                    })}
                  >
                    { bundle.name }
                  </button>
                </li>
              ))
            }
          </ul>
        )
      }
    </section>
  );
};

export default WordBundleSearch;
