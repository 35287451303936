import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { signOut } from "firebase/auth";

// Auth object to process log out
import { firebaseAuth } from "../../../services/base";

import Hamburger from "./hamburger";
import ManageMenu from "./ManageMenu";

import "./nav.scss";


// Helper to render header links for non-logged-in user
const notLoggedInLinks = (
  <>
    <Link className="nav-link" to="/signIn">Sign In</Link>
    <Link className="nav-link" to="/signUp">Sign Up</Link>
  </>
);

// Helper to render header links for logged in user
const loggedInLinks = (
  <>
    <Link className="nav-link" to="/">Home</Link>
    <ManageMenu />
    <span className="nav-link sign-out"
      role="button"
      tabIndex="0"
      onClick={() => {
        signOut(firebaseAuth);
      }}
    >
      Sign out
    </span>
  </>
);

/**
 * Renders header links and hamburger button (if in mobile view)
 *
 * @param { Boolean } isLoggedIn - state var from Header/index to indicate if page has logged-in user. 
 * @returns markup for links and hamburger
 */
const Nav = ({ isLoggedIn }) => {
  // State to control mobile or desktop view
  const [useMobile, setUseMobile] = useState();

  // On load, set width
  useEffect(() => {
    // If we're in a window, grab the width. Otherwise, set to 0
    const viewportWidth = typeof window !== "undefined" ? window.innerWidth : 0;

    // Set initial value for useMobile - default is to use mobile view
    // 750px is same as breakpoint in header.scss
    setUseMobile(viewportWidth <= 750);

    // Handler function to check for mobile view on window size change
    const resizeHandler = () => {
      // If a resize occurs, we can assume we're in a window
      setUseMobile(window.innerWidth <= 750);
    };

    // Listener for window resize
    window.addEventListener("resize", resizeHandler);

    // Return cleanup function
    return function cleanup() {
      window.removeEventListener("resize", resizeHandler);
    };
  }, []);

  // State to control menu open or closed in mobile view
  const [open, setOpen] = useState(false);

  // Handler to open and close menu
  const toggleMenu = () => {
    setOpen(!open);
  };

  return (
    <>
      {// Only render hamburger if we're in mobile view
        useMobile && <Hamburger open={open} toggleMenu={toggleMenu} />
      }
      <nav
        className={`header__nav${open ? " open" : ""}`}
        onClick={(event) => {
          // If a link was clicked, close the menu!
          if (useMobile && event.target?.classList?.contains("nav-link")) {
            toggleMenu();
          }
        }}
      >
        {isLoggedIn ? loggedInLinks : notLoggedInLinks}
      </nav>
    </>
  );
};

export default Nav;
