import React, { useState, useEffect } from "react";

import { useHolodeck } from "../../../Holodeck";

import OnePresentSpeaker from "./OnePresentSpeaker";
import CurrentSpeakerPanel from "./CurrentSpeakerPanel";
import AudienceTurnSpeaker from "./AudienceTurnSpeaker";

/**
 * Render all present speakers, and markup to broadcast/debroadcast them
 * 
 * @param { Object } lesson - Lesson info from Firestore, used to send feedback
 * @param { Object } module - Firestore object for module associated with lesson
 * @returns markup for present student portion of StudentPresence
 */
const PresentSpeakers = ({ lesson, module }) => {

  /*
   * CONTEXT
   */

  // Get Daily info from call context
  const {
    eligibleCandidateCount,
    conversationMode,
    currentSpeakerParticipant,
    mutedSpeakerStudentIds,
    presentSpeakerParticipants,
  } = useHolodeck();

  /*
   * STATE
   */

  // Remember number of times each student has been called on
  const [ callCountMap, setCallCountMap ] = useState({});

  // Boolean for whether or not to display the Audience Turn speaker entry
  const [ showAudienceTurnSpeaker, setShowAudienceTurnSpeaker ] = useState(false);

  // True if current time is 5+ min before lessonScheduledStartUTC
  const [ timeIsFiveMinBeforeLessonEnd, setTimeIsFiveMinBeforeLessonEnd ] = useState(false);

  /*
   * USE EFFECT
   */

  // When speakers are updated, update the call count map!
  useEffect(() => {

    // If we have no speakers, don't do anything!
    if ( !presentSpeakerParticipants ) {
      return;
    }

    // Extract studentIds that already exist in callCountMap
    const speakersWithCalls = Object.keys(callCountMap);

    // Create new call count object
    const newCallCountMap = presentSpeakerParticipants.reduce((accumulatorObject, thisSpeaker) => {
      // Grab studentId of this student
      const studentId = thisSpeaker.user_id;

      // Check if student already has a call count
      if (speakersWithCalls.includes(studentId)) {        
        // Copy call count for this student from existing callCountMap into new map
        accumulatorObject[studentId] = callCountMap[studentId];  
      }
      // If student doesn't exist in callCountMap, create new entry for student
      else {
        accumulatorObject[studentId] = 0;
      }

      // Return accumulatorObject for next iteration
      return accumulatorObject;
    }, {});

    // Set new call count map in state
    setCallCountMap((prev) => ({
      ...prev,
      ...newCallCountMap,
    }));

  }, [ presentSpeakerParticipants ]);

  // When lesson is loaded, set and set timers for timeIsFiveMinBeforeLessonEnd
  useEffect(() => {

    // If no lesson, don't do anything!
    if ( !lesson ) {
      return;
    }

    // Get info on lesson end time
    const { lessonScheduledEndUTC } = lesson;

    // Get this instant in JS epoch millis
    const nowMillis = Date.now();

    // Calculate JS epoch millis for 5 min before lesson end
    const fiveMinBeforeEndMillis = (new Date(lessonScheduledEndUTC)).getTime() - (5 * 60 * 1000);

    // If after 5 min before lesson end, set appropriate state
    if ( nowMillis > fiveMinBeforeEndMillis ) {
      setTimeIsFiveMinBeforeLessonEnd( false );

      // No timers to set, since threshold won't be crossed again
    }

    // If more than 5 min before lesson end, set appropriate state and timers
    else {
      // We're currently before five min before lesson end
      setTimeIsFiveMinBeforeLessonEnd( true );

      // Compute time between now and 5 min before lesson end
      const millisUntilFiveMinBeforeLessonEnd = fiveMinBeforeEndMillis - nowMillis;

      // Set timeout to update timeIsFiveMinBeforeLessonEnd
      const fiveMinBeforeLessonEndTimeoutId = setTimeout(() => {
        setTimeIsFiveMinBeforeLessonEnd( false );
      }, millisUntilFiveMinBeforeLessonEnd);

      // Clear timeout on unmount
      return function() {
        clearTimeout( fiveMinBeforeLessonEndTimeoutId );
      };
    }
  }, [ lesson ]);

  // Update showAudienceTurnSpeaker when factors that contribute to it are updated
  useEffect(() => {

    // If after 5 min before lesson end, don't show Audience Turn
    if ( !timeIsFiveMinBeforeLessonEnd ) {
      setShowAudienceTurnSpeaker( false );
    }

    // If before 5 min until lesson end, show Audience Turn as long as there are Candidates (who have Audience Turns available) present and we are in Drill mode.
    else {
      setShowAudienceTurnSpeaker(
        !!eligibleCandidateCount
        && !conversationMode,
      );
    }

  },
  [
    eligibleCandidateCount,
    conversationMode,
    timeIsFiveMinBeforeLessonEnd,
  ]);

  /*
   * HELPERS
   */

  // Increment call count by one for one student with given studentId
  const incrementCallCount = ( studentId ) => {
    // Don't increment if student is already current
    if ( currentSpeakerParticipant?.user_id === studentId ) {
      return;
    }

    // If student is newly nominated as curent, increment call count for that student
    setCallCountMap((prev) => ({
      ...prev,
      [studentId]: prev[studentId] ? prev[studentId] + 1 : 1,
    }));
  };

  /*
   * RENDER
   */

  // Variables to use in logic to map the order of rendered OnePresentSpeakers to keyboard shortcuts for nomination
  const nominationShortcuts = ["q","w","e","r","t","y"];
  let nominationIndex = 0;

  return presentSpeakerParticipants
    ? (
      <div className="studentPresence__active">
        <CurrentSpeakerPanel
          lesson={lesson}
          module={module}
        />
        {presentSpeakerParticipants.map(speaker => {
          // Get studentId
          const studentId = speaker.user_id;
          // Get keyboard shortcut to use for this student
          const shortcutKey = nominationShortcuts[nominationIndex];
          // Increment nominationIndex for next student
          nominationIndex++;
          return (
            <OnePresentSpeaker
              key={speaker.session_id}
              speaker={speaker}
              muted={mutedSpeakerStudentIds.includes(studentId)}
              callCount={ callCountMap[studentId] }
              incrementCallCount={ () => incrementCallCount(studentId) }
              isCurrent={ currentSpeakerParticipant?.user_id === studentId }
              lessonId={ lesson.id }
              shortcutKey={ shortcutKey }
            />
          );
        })}
        {/* nominationIndex was already incremented by last Speaker, so can safely use next in list for Audience Turn */}
        <AudienceTurnSpeaker
          lessonId={ lesson.id }
          showAudienceTurnSpeaker={ showAudienceTurnSpeaker }
          shortcutKey={ nominationShortcuts[nominationIndex] }
        />
      </div>
    )
    : null;
};

export default PresentSpeakers;
