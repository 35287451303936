import React, { useEffect, useState } from "react";
import { studentQuery, getOneStudentToManage } from "../../../services/datastore";
import "./ManageStudents.scss";
import { withRouter } from "react-router-dom";
import { NoUpdateModal, LevelUpdateModal, ChangeVipExpModal, TypeUpdateModal } from "./UpdateModals.js";

/**
 * 
 * @param {number} waitTime milliseconds to wait after typing to send request
 * @param {function} onSelect function to execute when a student is selected
 * @param {string} initialText text to populate the search bar with
 * @returns StudentSearchBar component
 */
const StudentSearchBar = ({waitTime, onSelect, initialText}) => {
  const [timeoutFunction,setTimeoutFunction] = useState(false);
  // Value of null for students = no search yet performed or no current search (for when no results should be shown)
  // value of empty array = search performed, but no results
  // populated array = students found
  const [students,setStudents] = useState(null);
  return (
    <>
      <input
        className="studentSearchBar__input"
        type="text"
        defaultValue={initialText}
        onChange={(e)=>{
          if(timeoutFunction){
            clearInterval(timeoutFunction);
          }
          const value = e.target.value;
          if(!value || value?.trim() === ""){
            setStudents(null);
          }
          else{
            const timeout = setTimeout(async () => {

              let formattedValue = value.trim();

              if (formattedValue?.includes("@")) {
                formattedValue = formattedValue.toLowerCase();
              }
              const query = await studentQuery(formattedValue);
              setStudents(query);
            },waitTime);
            setTimeoutFunction(timeout);
          }
        }}
      />
      <div className="stuList">
        {
          students ?
            students.map((student)=>{
              return <p className="dropDownItem" onClick={()=>{
                onSelect(student);
                setStudents(null);
              }}>{`${student.first_name} ${student.last_name}  | ${student.email} | ${student.studentId} `}</p>;
            }) 
            : null
        }
        {
          students && students.length === 0
            ?
            <p className="dropDownItem">No students found!</p>
            :
            <></>
        }
      </div>
    </>);
};

function StudentDataLine(name,value,onClick,modifiable){
  if(value === null){
    value = "-";
  }
  if(value === false){
    value = "false";
  }
  if(value === true){
    value = "true";
  }
  if(Array.isArray(value)){
    return <div className="studentSectionTextList">
      <p className="studentSectionText studentSectionTextListHeader"><b>{name}:</b></p>
      {value.map((val) => {
        return <p className="studentSectionText studentSectionTextListElement">{val}</p>;  
      })}
    </div>;
  }
  return (
    <p 
      className={modifiable ? "studentSectionTextMod" : "studentSectionText"} 
      onClick={onClick}
    >
      <b>{name}: </b> 
      {value}
    </p>
  );
}

function ManageStudents({location}) {
  const [currentStudent,setCurrentStudent] = useState();
  const [email, setEmail] = useState();
  const [fieldToUpdate, setFieldToUpdate] = useState(null);
  const [showNoUpdateModal, setShowNoUpdateModal] = useState(false);

  // Helper to get the data for a student given their email
  const getStudent = async (email) => {
    const student = await getOneStudentToManage(email);
    setCurrentStudent(student);
  };

  // On page load, call the getStudent helper if we got an email in the URL
  useEffect(() => {
    const getData = async (email) => {
      await getStudent(email);
    };

    // Get email from URL params
    const params = new URLSearchParams(location.search);
    const emailParam = (params.get("email") || "").replace(/ /g, "+").toLowerCase();

    // If email specified, set it in state & get new student data
    if (emailParam) {
      setEmail(emailParam);
      getData(emailParam);
    }
  }, []);

  // Conditionally displays the red BANNED texts next to banned emails.
  const processEmails = ((emails)=>{
    if(emails){
      return emails.map((email)=>{
        if(email.banned){
          return <span>{email.value} <span className="red">BANNED</span></span>;
        }
        return <span>{email.value}</span>;
      });
    }
    return "";
  });

  // Formats date to account for localized differences in date/month vs. month/date
  const formatDate = ((dateToFormat)=>{
    if(dateToFormat){
      try {
        const date = new Date(dateToFormat);

        const options = { weekday: "short", year: "numeric", month: "short", day: "numeric" };

        // undefined = use browser's understanding of logged-in user's timezone
        return date.toLocaleDateString(undefined, options);
      }
      catch {
        return "Invalid Date";
      }
    }
    return "--";
  });

  // Callback to refresh all student data lines by reloading from the database
  const refresh = async () => {
    setCurrentStudent(null);  // Make all fields show up blank
    await getStudent(email);
  };

  // The following functions are callbacks for clicks on student data lines
  // Show a message that the clicked property can't be updated
  const noUpdate = () => {
    //setShowNoUpdateModal(true);
  };
  // Update level when clicked on
  const updateLevel = () => {
    currentStudent && setFieldToUpdate("level");
  };
  // Update VIP expiration when clicked on
  const updateVipExpiration = () => {
    currentStudent && setFieldToUpdate("VIP_expiration");
  };
  // Update student type when clicked on
  const updateStudentType = () => {
    currentStudent && setFieldToUpdate("student_type");
  };
  return (
    <div className="stuSearchContainer">
      <p>Searches are case-sensitive, and only match the beginning of students' traits</p>
      <StudentSearchBar initialText={email} waitTime={300} onSelect={async (stu)=>{
        if(stu && stu.email){
          // Get student info
          await getStudent(stu.email);

          // Set email in URL params
          history.replaceState(null, null, "?email=" + stu.email);

          // Set email in state
          setEmail(stu.email);
        }
      }} />
      <div>Click on any blue property to modify it.</div>
      <div className="studentSectionList">
        {StudentDataLine("Speak Account", currentStudent?.has_speak_account, noUpdate)}
        {StudentDataLine("Speak Account Auth", currentStudent?.has_speak_account_auth, noUpdate)}
        {StudentDataLine("Student ID", currentStudent?.studentId, noUpdate)}
        {StudentDataLine("First Name", currentStudent?.first_name, noUpdate)}
        {StudentDataLine("Last Name", currentStudent?.last_name, noUpdate)}
        {StudentDataLine("Email", processEmails(currentStudent?.emails), noUpdate)}
        {StudentDataLine("Student Type", currentStudent?.student_type, updateStudentType, true)}
        {StudentDataLine("VIP Registration", formatDate(currentStudent?.VIP_registration), noUpdate)}
        {StudentDataLine("VIP Expiration", formatDate(currentStudent?.VIP_expiration), updateVipExpiration, true)}
        {StudentDataLine("Level", currentStudent?.level, updateLevel, true)}
        {StudentDataLine("Current Streak", currentStudent?.streak_current, noUpdate)}
        {StudentDataLine("# of Modules on Wishlist", currentStudent?.wish_list_length, noUpdate)}
        {StudentDataLine("Modules on Wishlist", currentStudent?.wish_list, noUpdate)}
        {StudentDataLine("Student Native Language", currentStudent?.languageFluent === "es" ? "Spanish" : "English", noUpdate)}
        {StudentDataLine("Student Target Language", currentStudent?.languageTarget === "en" ? "English" : "Spanish", noUpdate)}
      </div>
      <LevelUpdateModal 
        student={currentStudent} 
        refresh={refresh} 
        show={fieldToUpdate === "level"} 
        hide={() => {
          setFieldToUpdate(null);
        }}
      />
      <ChangeVipExpModal
        student={currentStudent}
        refresh={refresh}
        show={fieldToUpdate === "VIP_expiration"}
        hide={() => {
          setFieldToUpdate(null);
        }}
      />
      <TypeUpdateModal
        student={currentStudent}
        refresh={refresh}
        show={fieldToUpdate === "student_type"}
        hide={() => {
          setFieldToUpdate(null);
        }}
      />      
      <NoUpdateModal show={showNoUpdateModal} hide={() => {
        setShowNoUpdateModal(false);
      }} />
    </div>
  );
  
}
export default withRouter(ManageStudents);
