import React, { Component, useContext } from "react";
import {
  BrowserRouter as Router, Route, Switch, withRouter,
} from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";

import { talkka } from "../services/logging";
import { UserProvider, UserContext } from "../services/user";
import { TimeProvider } from "../services/time";
import PrivateRoute from "../services/PrivateRoute";
import {
  createUser,
  signIn,
  unClaimLesson,
} from "../services/datastore";

import ClassDetail from "../pages/ClassDetail";
import KillDrillPage from "../pages/KillDrillPage";
import Home from "../pages/Home";
import Manage from "../pages/Manage";
import Teach from "../pages/Teach";
import { Holodeck } from "../pages/Teach/Holodeck";

import Header from "./Header";
import SignUp from "./signup";
import SignIn from "./signin";
import ResetPassword from "./resetPassword";
import VersionCheck from "./VersionCheck";
import ErrorFallback from "./ErrorFallback";

import "../style.scss";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      nextLesson: null,
    };
  }

  makeUser = (email, password, callback) => {
    createUser(email, password, callback);
  };

  signInUser = (email, password, history, callback) => {
    signIn(email, password, history, callback);
  };

  signUp = () => {
    return <VersionCheck> <SignUp onSubmit={this.makeUser} /> </VersionCheck>;
  };

  signIn = () => {
    const userInfo = useContext(UserContext);
    return <VersionCheck> <SignIn onSubmit={this.signInUser} userInfo={userInfo} /> </VersionCheck>;
  };

  killDrillPage = () => {
    const userInfo = useContext(UserContext);
    return (
      <VersionCheck> <KillDrillPage userInfo={userInfo} /> </VersionCheck>
    );
  };

  FallBack = () => {
    return <VersionCheck> URL Not Found </VersionCheck>;
  };

  giveUpLesson = (lesson, date, history) => {
    unClaimLesson(lesson, date, history, () => {});
  };

  isPast = (lesson, offset) => {
    const start = new Date();
    start.setDate(start.getDate() + offset);
    start.setHours(0, 0, 0, 0);

    const lessonDate = new Date(lesson.date);
    return lessonDate < start;
  };

  convertDateToArr = (lesson) => {
    const res = [];
    const dateArray = lesson.date.split("/");
    res.push(parseInt(dateArray[2], 10)); // year
    res.push(parseInt(dateArray[0], 10) - 1); // month
    res.push(parseInt(dateArray[1], 10)); // day

    const timeArray = lesson.time.split(":");
    res.push(parseInt(timeArray[0], 10)); // hour
    res.push(parseInt(timeArray[1], 10)); // minute
    /* const day = dateArray[1];
      const month = String(parseInt(dateArray[0], 10), 10);
      const year = dateArray[2];
      const final = month.concat('/', day, '/', year); */
    return res;
  };

  HomeComponent = () => {
    return (
      <VersionCheck> <Home /> </VersionCheck>
    );
  };

  resetPassword = () => {
    return (
      <VersionCheck> <ResetPassword /> </VersionCheck>
    );
  };

  Manage = () =>{
    return (
      <VersionCheck> <Manage /> </VersionCheck>
    );
  };

  StarshipEnterprise = () => (
    <VersionCheck> 
      <Holodeck>
        <Teach />
      </Holodeck>
    </VersionCheck>
  );

  ShowClassDetail = () => (
    <VersionCheck>
      <ClassDetail />
    </VersionCheck>
  );

  // Wrapping inside Header and individual Components with VersionCheck instead of outside UserProvider so in-app navigation also checks VersionCheck
  render() {
    return (
      <UserProvider>
        <TimeProvider>
          <ErrorBoundary
            FallbackComponent={ ErrorFallback }
            onError={ (error, info) => talkka.error(error, info) }
            onReset={ () => window.location.reload() }
          >
            <Router>
              <VersionCheck>
                <Header />
              </VersionCheck>
              <Switch>
                <Route path="/signup" component={this.signUp} />
                <Route path="/signin" component={this.signIn} />
                <Route path="/reset-password" component={this.resetPassword} />
                <PrivateRoute exact path="/" component={this.HomeComponent} />
                <PrivateRoute exact path="/class/:lessonID" component={this.ShowClassDetail} />
                <PrivateRoute path="/kill-lesson/:lessonID" component={this.killDrillPage} />
                <PrivateRoute path="/manage" component={this.Manage} />
                <PrivateRoute path="/teach/:lessonIdHash?" component={this.StarshipEnterprise} />
                <Route component={this.FallBack} /> 
              </Switch>
            </Router>
          </ErrorBoundary>
        </TimeProvider>
      </UserProvider>
    );
  }
}

export default withRouter(App);
