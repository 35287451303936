import React, {
  useEffect,
  useState,
} from "react";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";

import { getFeedbackForAllTeachers } from "../../../services/datastore";
import { getNYCdate } from "../../../services/time";

import ReviewTable from "./ReviewTable";

import "./teacherReviews.scss";

/**
 * Shows review data for all teachers over a given time period.
 * Admin only.
 */
const TeacherReviews = () => {

  /*
   * CONSTANT
   */

  // Get date object set to tomorrow, for limiting date
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);

  /*
   * HOOKS
   */

  // Get the date in NYC for this exact moment
  const todayDateString = getNYCdate();

  /*
   * STATE
   */

  // Loading flag
  const [ loading, setLoading ] = useState( true );

  // Array with start date object and end date object, in order, for dates over which to calculate averages
  const [ dateRange, setDateRange ] = useState( null );

  // Array to hold fetched review data
  const [ reviewData, setReviewData ] = useState( null );

  /*
   * USE EFFECT
   */

  // On load, set initial start and end dates in dateRange
  useEffect(() => {

    // Get date objects with today's date and 14 days ago
    const todayDateObject = new Date( todayDateString );
    const pastDateObject = new Date();
    pastDateObject.setDate( todayDateObject.getDate() - 14 );

    setDateRange([pastDateObject, todayDateObject]);

  }, []);

  // When dateRange changes, fetch the data for the new dates!
  useEffect(() => {

    // We can only update the dates if we have new dates!
    if ( dateRange ) {

      // Don't allow selection of dates in the future
      if ( dateRange.some((date) => date > tomorrow ) ) {
        return alert("Please only select dates on or before today");
      }

      // Don't allow selection of > 31 days of data
      const millisIn31Days = 1000 * 60 * 60 * 24 * 31;
      if ( dateRange[1] - dateRange[0] > millisIn31Days ) {
        return alert("Please select a range of 31 or fewer days");
      }

      // If dates are valid, set them in state
      getAndSetAllTeacherReviews({
        startDate: dateRange[0],
        endDate: dateRange[1],
      });
    }

  }, [ dateRange ]);

  /*
   * HELPERS
   */

  const getAndSetAllTeacherReviews = async ({ startDate, endDate }) => {

    setLoading( true );

    // Datastore function wants start and end dates in YYYY-MM-DD format
    const startDateString = startDate.toISOString().slice(0, 10);
    const endDateString = endDate.toISOString().slice(0, 10);

    const allReviews = await getFeedbackForAllTeachers({
      startDate: startDateString,
      endDate: endDateString,
    });

    setReviewData(allReviews);

    setLoading( false );
  };

  const handleDatepickerChange = (newDateRange) => {
    setDateRange(newDateRange);
  };

  return loading
    ? <div>Loading...</div>
    : (
      <>
        <div className="teacherReviews__dateRangePickerContainer">
          <p>
            Showing data for classes between:
          </p>
          <DateRangePicker
            className="teacherReviews__dateRangePicker"
            clearIcon={null}
            maxDate={ today }
            onChange={handleDatepickerChange}
            value={dateRange}
          />
        </div>
        <ReviewTable reviewData={reviewData} />
      </>
    );
};

export default TeacherReviews;
