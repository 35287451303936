import React, { useState } from "react";
/**
 * @prop {string} className (optional) The className string to be added to the resulting button
 * @prop {string} initialText The default state button text
 * @prop {string} loadingText The button text for when the request is loading
 * @prop {string} completeText (optional) The button text for when the request has completed successfully
 * @prop {string} errorText (optional) The button text for when the request has errored
 * @prop {boolean} disableOnComplete (optional) Whether to disable the button on successful completion
 * @prop {boolean} disableOnError (optional) Whether to disable the button on request error
 * @prop {function} completeFunction (optional) function to execute when the request has completed successfully
 * @prop {function} errorFunction (optional) function to execute on request error
 * @prop {function} function (optional) function to execute, function must return a promise, which will be awaited on
 * @prop {number} timeout (optional) the amount of time before a timeout error would be raised.
*/
const AsyncButton = React.forwardRef((props, ref) => {
  const [loading, setLoading] = useState(false);
  const [isComplete, setComplete] = useState(false);
  const [isErrored, setErrored] = useState(false);
  const isDisabled = () => loading || props.disabled;
  const clickHandler = async () => {
    // We check this to protect against multiple clicks.
    if (!isDisabled()) {
      setLoading(true);
      let timeoutRef;
      // If a timeout value is defined,
      // create a function that after the inputted amount of time
      // will reactivate the button, and process the error.
      if (props.timeout) {
        timeoutRef = setTimeout(() => {
          setLoading(false);
          if (props.errorFunction) {
            props.errorFunction("Timeout Error");
          }
          setErrored(true);
        }, props.timeout);
      }
      try {
        const res = await props.function();
        // The request has completed at this point, so we can clear
        // the timeout if it exists
        if (timeoutRef) {
          clearTimeout(timeoutRef);
        }
        // This assumes that the request follows the success pattern in place.
        if (res.success === true) {
          if (props.completeFunction) {
            await props.completeFunction(res);
          }
          if (!props.disableOnComplete) {
            setLoading(false);
          }
          setComplete(true);
        }
        else {
          if (props.errorFunction) {
            props.errorFunction(res.error);
          }
          if (!props.disableOnError) {
            setLoading(false);
          }
          setErrored(true);
        }
      }
      catch (e) {
        if (props.errorFunction) {
          props.errorFunction(e);
        }
        if (!props.disableOnError) {
          setLoading(false);
        }
        setErrored(true);
      }
    }
  };
  let text;
  if (isComplete && props.completeText) {
    text = props.completeText;
  }
  else if (isErrored && props.errorText) {
    text = props.errorText;
  }
  else if (isDisabled()) {
    text = props.loadingText;
  }
  else {
    text = props.initialText;
  }
  return (
    <button ref={ref} className={props.className} disabled={isDisabled()} onClick={() => {
      clickHandler(); 
    }} type="button">
      {text}
    </button>
  );
});

export default AsyncButton;
