import React, { useEffect } from "react";

/**
 * Displays teacher info and allows an admin to change the teacher
 *
 * @param changeTeacher         boolean indicating if teacher is being changed
 * @param currentTeacher        name and email of teacher currently assigned
 * @param allTeachers           names and emails of all teachers
 * @param changeTeacherFunc     function to send change teacher request to db
 * @param openTeacherChangeForm function to display drop-down to change teacher
 * @param closeTeacherChangeForm function to remove drop-down
 * @returns markup to display teacher name and change form
 */
const TeacherToggle = ({
  changeTeacher,
  currentTeacher,
  allTeachers,
  changeTeacherFunc,
  openTeacherChangeForm,
  closeTeacherChangeForm,
}) => (
  changeTeacher
    ? <ChangeTeacherForm currentTeacher={currentTeacher} allTeachers={allTeachers} onChange={changeTeacherFunc} closer={closeTeacherChangeForm} />
    : <PlainName onClick={openTeacherChangeForm} displayName={currentTeacher.preferred_name} />
);

// Component to display teacher name when not editing
const PlainName = ({ onClick, displayName }) => (
  <span role="button" tabIndex="0" onClick={onClick}>
    <span className="lesson-instructorName">
      {displayName}
    </span>
  </span>
);

// Component to select teacher
const ChangeTeacherForm = ({
  currentTeacher,
  allTeachers,
  onChange,
  closer,
}) => {
  // use allTeachers to generate options for dropdown
  const options = allTeachers.map((t) => (<option key={`select-${t.preferred_name}`} value={t.preferred_name} className="teacherSelect">{t.preferred_name}</option>));

  // Get current teacher name
  const selectedValue = currentTeacher ? currentTeacher.preferred_name : "";

  // On component mount, add click listener
  useEffect(() => {
    // Function to close ChangeTeacherForm on click outside of drop-down
    const listenForClick = (event) => {
      // Use classList to check for teacherSelect class -- should be present only on teacher dropdown
      if (!event.target.classList.contains("teacherSelect")) {
        // If click is anywhere other than on dropdown, close the dropdown!
        closer();
      }
    };
    // Add event listener to window, so it gets all clicks
    window.addEventListener("click", listenForClick);

    // Create cleanup function to remove listener
    const cleanup = () => {
      window.removeEventListener("click", listenForClick);
    };
    // Return cleanup function so it executes on component unmount
    return cleanup;
  }, []);

  return (
    <span role="button" tabIndex="0">
      <span className="lesson-instructorName">
        <select className="teacherSelect" value={selectedValue} onChange={onChange}>
          {options}
        </select>
      </span>
    </span>
  );
};

export { TeacherToggle as default, PlainName };
