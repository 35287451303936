import {
  useEffect,
  useState,
} from "react";
import {
  onValue,
  ref,
  set,
} from "firebase/database";

import { firebaseRealTimeDatabase } from "../../services/base";
import { talkka } from "../../services/logging";

/**
 * Manage socket connection to Real Time Database, and alert when the
 * connection drops
 * 
 * @param { Object } lesson - Firestore lesson object for this class
 * @returns { Object } with boolean indicating if connection is present or
 * dropped, and function to reset warning
 */
export const useRealTimeDatabase = ({ lesson }) => {

  // Wait 2 seconds before showing Connection Warning
  const WAIT_BEFORE_SHOWING_WARNING_MILLIS = 2000;

  /*
   * STATE
   */

  // Boolean indicating if the intial connection to Real-Time Database has been made
  const [
    hasEstablishedInitialConnection,
    setHasEstablishedInitialConnection,
  ] = useState( false );

  // Remember the ID of the timeout set to show the connection warning, so we can cancel it if the connection recovers.
  const [
    showConnectionWarningTimeout,
    setShowConnectionWarningTimeout,
  ] = useState( null );

  // Boolean to indicate whether or not the connection warning should be shown. Toggling this to true starts the countdown to show the warning, but doesn't actually show it.
  const [
    shouldShowConnectionWarning,
    setShouldShowConnectionWarning,
  ] = useState( false );

  // Boolean to indicate whether or not the connection warning is actually being shown at this time. The warning is shown when this is flipped to true, which will happen at the end of the countdown started when shouldShowConnectionWarning is flipped to true.
  const [
    isShowingConnectionWarning,
    setIsShowingConnectionWarning,
  ] = useState( false );

  /*
   * USE EFFECT
   */

  // On load, set up connection RTD to monitor instructor internet connection
  useEffect(() => {

    // Only proceed if we have lesson information
    if ( !lesson ) {
      return;
    }
    
    // Get access to special location where RTD stores connection info
    const connectedRef = ref(firebaseRealTimeDatabase, ".info/connected");

    // When RTD detects that connection status has changed, respond appropriately
    // onValue returns an unsubscribe function, so take advantage of that here!
    const unsubscribeFromOnValue = onValue(connectedRef, (snap) => {

      if (snap.val() === true) {
        talkka.log("Connected to RTD");

        // Set initial connection status to true
        // Will fire every time, but we only need it to fire once
        setHasEstablishedInitialConnection( true );

        // Clear displayed warning or countdown (if any)
        setShouldShowConnectionWarning( false );
      }
      else {
        talkka.log("DISCONNECTED");

        // Start countdown to show connection warning
        setShouldShowConnectionWarning( true );
      }
    });

    // Create a reference to the class-specific location at which we'll store timestamps
    const timestampRef = ref(
      firebaseRealTimeDatabase,
      // Using idHash because lessonId contains invalid characters for RTD path
      `drills/${lesson.idHash}/presentStudents/instructor`,
    );

    // Record instructor presence on load
    set(
      timestampRef,
      { lastPing: Date.now() },
    );

    /*
     * Set up an interval to record the instructor's presence in the classroom
     * every 30 secs with a timestamp. For now, this is on a fairly long
     * interval, and only serves to keep the connection alive. In the future,
     * we could shorten this interval and use it to check latency (see notes in
     * https://beepboop-us.monday.com/boards/612100833/pulses/2344534045)
     */
    const pingIntervalId = setInterval(
      () => {
        // Write a new timestamp, or overwrite the existing timestamp in this student-specific location
        set(
          timestampRef,
          { lastPing: Date.now() },
        );
      },
      30000,
    );

    // On unmount, unsubscribe from onValue listener and cancel ping interval
    return function cleanUpRTD() {
      unsubscribeFromOnValue();
      clearInterval( pingIntervalId );
    };

  }, [ lesson ]);

  // When state related to connection status changes, decide if we should show or hide the connection warning
  useEffect(() => {

    // If we haven't yet established the initial connection, don't do anything!
    if ( !hasEstablishedInitialConnection ) {
      return;
    }

    // If we should show the warning, and we're not already waiting to do so, set the timeout to do it!
    if (
      shouldShowConnectionWarning
      && !showConnectionWarningTimeout
    ) {
      setShowConnectionWarningTimeout(
        setTimeout( showConnectionWarning, WAIT_BEFORE_SHOWING_WARNING_MILLIS ),
      );
    }
    // If we shouldn't show the warning, and we have one waiting, cancel it!
    else if (
      !shouldShowConnectionWarning
      && showConnectionWarningTimeout
    ) {
      clearTimeout( showConnectionWarningTimeout );
      setShowConnectionWarningTimeout( null );
    }
    // If we shouldn't show the warning, but we are right now, hide it!
    else if ( !shouldShowConnectionWarning ) {
      hideConnectionWarning();
    }

  }, [
    hasEstablishedInitialConnection,
    shouldShowConnectionWarning,
    showConnectionWarningTimeout,
  ]);

  /*
   * HELPERS
   */

  // Show the connection warning - last step in a chain started by the onValue, when RTD detects that the connection to the database is lost
  const showConnectionWarning = () => {

    // Toggle boolean to actually display the warning
    setIsShowingConnectionWarning( true );

    // Use talkka to emit console warning in DEV, and attempt to record logEvent in other environments
    talkka.warn(`Instructor for lesson ${lesson.id} dropped connection to RTD at ${(new Date()).toISOString()}`);
  };

  // Hide the connection warning and reset associated state to its intial status, so that it may be re-shown if connection fails again
  const hideConnectionWarning = () => {
    setIsShowingConnectionWarning( false );
    setShouldShowConnectionWarning( false );
    setShowConnectionWarningTimeout( null );
  };

  /*
   * RETURN
   */

  // Return a boolean indicating if connection warning should be shown or not
  return {
    connectionLost: isShowingConnectionWarning,
    hideConnectionWarning,
  };
};
