import React from "react";

import { useHolodeck } from "../../Holodeck";

import PresentSpeakers from "./PresentSpeakers";

import "./studentPresence.scss";

/**
 * Show all registered and present speakers, and do things to them!
 * FUTURE: Will also show some basic info on observers
 * 
 * @param { Object } lesson - Lesson file from firestore
 * @param { Object } module - Firestore object for module associated with lesson
 * @returns markup listing all students in class and enabling student-based
 * actions
 */
const StudentPresence = ({ lesson, module }) => {
  const {
    observerCount,
    eligibleCandidateCount,
  } = useHolodeck();

  return (
    <div className="commandStation__studentPresence">
      <PresentSpeakers lesson={lesson} module={module} />
      <div className="studentPresence__invisibleStudentsCounts">
        {`${ observerCount } Observer${ observerCount === 1 ? "" : "s" } + `}
        {`${ eligibleCandidateCount } in Audience`}
      </div>
    </div>
  );
};

export default StudentPresence;
