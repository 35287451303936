import React, { useEffect, useState } from "react";

import goldStar from "../../../images/star-gold.svg";
import blankStar from "../../../images/star-unfilled.svg";

import "./ratingStars.scss";

/**
 * Gives a visual indicator of feedback/rating by rendering a gold star for
 * each feedback 'point' given, and a blank star for each possible feedback
 * 'point' that wasn't earned. Rounds decimal values to the nearest whole
 * number for star display purposes
 */
const RatingStars = ({
  outOf = 5,
  rating,
  ratingCount,
  showDetail = false,
  title,
}) => {

  /*
   * STATE
   */

  // Number of stars that should be "filled"
  const [ filledStarsCount, setFilledStarsCount ] = useState( 0 );

  // Holds JSX to display all stars
  const [ starsMarkup, setStarsMarkup ] = useState( null );

  /*
   * USE EFFECT
   */

  // When rating is updated, update how many stars to display!
  useEffect(() => {
    // Round to nearest whole number -- 3.5 rounds to 4, 2.4 rounds to 2
    setFilledStarsCount(rating ? Math.round(rating) : 0);
  }, [ rating ]);

  // When we know how many stars to fill, render the stars
  useEffect(() => {
    // Array to hold markup for all displayed stars
    const starsArray = [];

    for (let i = 0; i < outOf; i++) {
      starsArray.push(
        <img
          className="ratingStars__star"
          key={`star${i}`}
          src={ i < filledStarsCount ? goldStar : blankStar }
        />,
      );
    }

    setStarsMarkup( starsArray );

  }, [ filledStarsCount, outOf ]);

  /*
   * RENDER
   */

  return (
    <section className="ratingStars">
      { title ? `${title}: ` : null }
      { starsMarkup }
      { showDetail
        && (
          <span className="ratingStars__showValue">
            { Math.round(rating * 100) / 100 } out of { outOf } ({ratingCount} reviews)
          </span>
        )
      }
    </section>
  );
};

export default RatingStars;
