import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

import {
  fetchLessonById,
  makeSyntheticBooking,
} from "../../../services/datastore";
import LessonSummary from "../../../components/LessonSummary";

// Local styles
import "./makeBooking.scss";

// Get user info from context
import { getCurrentUser } from "../../../services/user";

// Generate a unique 5-character string
function generateFragmemnt() {
  const result = [];
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const charactersLength = characters.length;
  for (let i = 0; i < 5; i += 1) {
    result.push(characters.charAt(Math.floor(Math.random() * charactersLength)));
  }
  return result.join("");
}

// Formats an email 'user@domain' to 'user+fragment@domain'
function formatEmail(email, fragment) {
  // If `email` is undefined, null, or empty, return an empty string so the user has to explicitly set it
  if (!email) {
    return "";
  }

  const [user, domain] = email.split("@");
  return `${user}+${fragment}@${domain}`;
}

// Generate a random 16 character string with digits and uppercase letters to serve as a Calendly UUID
function generateUUID() {
  const result = [];
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < 16; i += 1) {
    result.push(characters.charAt(Math.floor(Math.random() * charactersLength)));
  }
  return result.join("");
}

// Generate a random Zoom link
function generateZoomLink() {
  const result = [];
  const characters = "0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < 11; i += 1) {
    result.push(characters.charAt(Math.floor(Math.random() * charactersLength)));
  }
  const str = result.join("");
  return `https://us02web.zoom.us/j/${str}`;
}

// Render Make Booking page
const MakeBooking = ({ location }) => {
  /*
   * CONTEXT
   */

  // Info for current logged-in teacher
  const userInfo = getCurrentUser();

  /*
   * STATE
   */

  // Loading flag
  const [loading, setLoading] = useState(true);

  // Target lesson for booking (if it exists)
  const [lesson, setLesson] = useState(null);

  // Keep track of booking status
  const [bookingWasMade, setBookingWasMade] = useState(false);
  const [bookingResult, setBookingResult] = useState(null);

  // Data used to make synthetic booking -- passed to makeSyntheticBooking
  // Initially populated with default values
  const [syntheticBookingInfo, setSyntheticBookingInfo] = useState({
    calendly_uuid: generateUUID(),
    cancelled: "False",
    drill_date: "",
    drill_link: generateZoomLink(),
    drill_preference: "(Student Message)",
    drill_time: "(Formatted Time)",
    drill_type: "",
    email: "",
    event_type: "",
    first_name: "",
    last_name: "",
    scheduler: "",
    text_reminder_number: "+1 111-111-1111",
    time_registered: new Date().toISOString(),
    time_zone: "",
    time_zone_isolated: "",
    invitee_start_time: "",
    utm_campaign: "SYNTHETIC",
    utm_medium: "SYNTHETIC",
    utm_source: "SYNTHETIC",
  });

  /*
   * USE EFFECT
   */

  // On component mount, populate fields with info and check query string
  useEffect(() => {
    // Unique string to append to generated user info for booking
    const fragment = generateFragmemnt();

    // Set form values related to logged-in user in state
    setSyntheticBookingInfo({
      ...syntheticBookingInfo,
      first_name: userInfo.first_name ? `${userInfo.first_name}-${fragment}` : "",
      last_name: userInfo.last_name ? userInfo.last_name : "",
      email: formatEmail(userInfo.email, fragment),
    });


    // If a lesson was passed by ID in the query string, populate some of the inputs
    const query = new URLSearchParams(location.search);
    const lessonID = query.get("lesson_id");
    if (lessonID) {
      // Use async helper to fetch lesson info and set state
      fetchAndSetLessonInfo( lessonID, fragment );
    }
    else {
      // If no lesson, just set form values related to logged-in user in state
      setSyntheticBookingInfo({
        ...syntheticBookingInfo,
        first_name: userInfo.first_name ? `${userInfo.first_name}-${fragment}` : "",
        last_name: userInfo.last_name ? userInfo.last_name : "",
        email: formatEmail(userInfo.email, fragment),
      });
      setLoading(false);
    }
  }, []);

  // Fetch lesson from Firestore, and set up initial state
  const fetchAndSetLessonInfo = async ( lessonId, fragment ) => {
    const updatedLesson = await fetchLessonById( lessonId );

    // Check that updatedLesson is truthy (successful fetch)
    if (updatedLesson) {
      // Update lesson with fetched version
      setLesson(updatedLesson);

      // Populate some form fields from the lesson details we just received
      setSyntheticBookingInfo({
        ...syntheticBookingInfo,
        first_name: userInfo.first_name ? `${userInfo.first_name}-${fragment}` : "",
        last_name: userInfo.last_name ? userInfo.last_name : "",
        email: formatEmail(userInfo.email, fragment),
        // Note that temp file fields are not the same as lesson file fields
        drill_date: updatedLesson.id.substring(0, 25), // extract the ISO 8601 date-time of the lesson
        drill_link: updatedLesson.zoom_link,
        drill_type: (updatedLesson.lesson_title && updatedLesson.lesson_title !== "") ? updatedLesson.lesson_title : updatedLesson.difficulty,
        event_type: updatedLesson.type,
        scheduler: updatedLesson.id.substring(25), // extract the 'level{n}aquokka@gmail.com' scheduler field
        invitee_start_time: updatedLesson.id.substring(0, 25), // extract the ISO 8601 date-time of the lesson
      });
    }
    // Once all other state is set, reset loading flag
    setLoading(false);
  };

  const handleInputChange = (event) => {
    setSyntheticBookingInfo({
      ...syntheticBookingInfo,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async () => {
    // Use booking info from form to create synthetic booking
    const result = await makeSyntheticBooking(syntheticBookingInfo);

    // Record booking result in state --used to generate success/fail message(?)
    setBookingResult(result);

    // Set flag that booking was made before preparing for the next one
    setBookingWasMade(true);

    // Generate new fragment for potential next entry
    const fragment = generateFragmemnt();
    
    // Put new fields with new fragment in state, so clicking 'submit' again generates new unique booking
    setSyntheticBookingInfo({
      ...syntheticBookingInfo,
      calendly_uuid: generateUUID(),
      email: formatEmail(userInfo.email, fragment),
      first_name: userInfo.first_name ? `${userInfo.first_name}-${fragment}` : "",
      last_name: userInfo.last_name ? userInfo.last_name : "",
    }); 
  };  

  return (
    loading 
      ? <div>Loading...</div>
      : (
        <div>
          {// Display LessonSummary if this page is currently associated with a lesson
            lesson
            && <LessonSummary lesson={lesson} />
          }
          <form onSubmit={(event) => {
            // Don't refresh the page on submit
            event.preventDefault(); 
            handleSubmit();
          }} className="booking-fields">
            <div>
              <label htmlFor="email">
                Student Email:
                <input name="email" type="text" value={syntheticBookingInfo.email} onChange={(event) => {
                  handleInputChange(event);
                }} />
              </label>
            </div>
            <div>
              <label htmlFor="first_name">
                First Name:
                <input name="first_name" type="text" value={syntheticBookingInfo.first_name} onChange={(event) => {
                  handleInputChange(event);
                }} />
              </label>
            </div>
            <div>
              <label htmlFor="last_name">
                Last Name:
                <input name="last_name" type="text" value={syntheticBookingInfo.last_name} onChange={(event) => {
                  handleInputChange(event);
                }} />
              </label>
            </div>
            <div>
              <label htmlFor="calendly_uuid">
                Calendly UUID:
                <input name="calendly_uuid" type="text" value={syntheticBookingInfo.calendly_uuid} onChange={(event) => {
                  handleInputChange(event);
                }} />
              </label>
            </div>
            <div>
              <label htmlFor="drill_date">
                Drill Date:
                <input name="drill_date" type="text" value={syntheticBookingInfo.drill_date} onChange={(event) => {
                  handleInputChange(event);
                }} />
              </label>
            </div>
            <div>
              <label htmlFor="drill_link">
                Drill Link:
                <input name="drill_link" type="text" value={syntheticBookingInfo.drill_link} onChange={(event) => {
                  handleInputChange(event);
                }} />
              </label>
            </div>
            <div>
              <label htmlFor="drill_preference">
                Drill Preference:
                <input name="drill_preference" type="text" value={syntheticBookingInfo.drill_preference} onChange={(event) => {
                  handleInputChange(event);
                }} />
              </label>
            </div>
            <div>
              <label htmlFor="drill_time">
                Drill Time:
                <input name="drill_time" type="text" value={syntheticBookingInfo.drill_time} onChange={(event) => {
                  handleInputChange(event);
                }} />
              </label>
            </div>
            <div>
              <label htmlFor="drill_type">
                Drill Type:
                <input name="drill_type" type="text" value={syntheticBookingInfo.drill_type} onChange={(event) => {
                  handleInputChange(event);
                }} />
              </label>
            </div>
            <div>
              <label htmlFor="event_type">
                Event Type:
                <input name="event_type" type="text" value={syntheticBookingInfo.event_type} onChange={(event) => {
                  handleInputChange(event);
                }} />
              </label>
            </div>
            <div>
              <label htmlFor="scheduler">
                Scheduler:
                <input name="scheduler" type="text" value={syntheticBookingInfo.scheduler} onChange={(event) => {
                  handleInputChange(event);
                }} />
              </label>
            </div>
            <div>
              <label htmlFor="text_reminder_number">
                Phone Number:
                <input name="text_reminder_number" type="text" value={syntheticBookingInfo.text_reminder_number} onChange={(event) => {
                  handleInputChange(event);
                }} />
              </label>
            </div>
            <div>
              <label htmlFor="time_registered">
                Time Registered:
                <input name="time_registered" type="text" value={syntheticBookingInfo.time_registered} onChange={(event) => {
                  handleInputChange(event);
                }} />
              </label>
            </div>
            <div>
              <label htmlFor="time_zone">
                Time Zone:
                <input name="time_zone" type="text" value={syntheticBookingInfo.time_zone} onChange={(event) => {
                  handleInputChange(event);
                }} />
              </label>
            </div>
            <div>
              <label htmlFor="time_zone_isolated">
                Isolated Time Zone:
                <input name="time_zone_isolated" type="text" value={syntheticBookingInfo.time_zone_isolated} onChange={(event) => {
                  handleInputChange(event);
                }} />
              </label>
            </div>
            <div>
              <label htmlFor="invitee_start_time">
                Invitee Start Time:
                <input name="invitee_start_time" type="text" value={syntheticBookingInfo.invitee_start_time} onChange={(event) => {
                  handleInputChange(event);
                }} />
              </label>
            </div>
            <div>
              <label htmlFor="utm_campaign">
                UTM Campaign:
                <input name="utm_campaign" type="text" value={syntheticBookingInfo.utm_campaign} onChange={(event) => {
                  handleInputChange(event);
                }} />
              </label>
            </div>
            <div>
              <label htmlFor="utm_medium">
                UTM Medium:
                <input name="utm_medium" type="text" value={syntheticBookingInfo.utm_medium} onChange={(event) => {
                  handleInputChange(event);
                }} />
              </label>
            </div>
            <div>
              <label htmlFor="utm_source">
                UTM Source:
                <input name="utm_source" type="text" value={syntheticBookingInfo.utm_source} onChange={(event) => {
                  handleInputChange(event);
                }} />
              </label>
            </div>
            <div>
              <input type="submit" />
              {// Display success/failure message after form was submitted
                bookingWasMade
                && <div className={`makeBookingStatus makeBookingStatus--${bookingResult ? "failure" : "success"}`}>{bookingResult || "Success"}</div>
              }
            </div>
          </form>
        </div>
      )
  );
};

export default withRouter(MakeBooking);
