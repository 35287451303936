import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Find icons to import at https://fontawesome.com/v5.15/icons
import {
  faMicrophone,
  faMicrophoneSlash,
} from "@fortawesome/free-solid-svg-icons";

import { useHolodeck } from "../../Holodeck";
import { useEventListener } from "../../../../hooks/useEventListener";

import "./muteButton.scss";

/**
 * Display mute button to toggle local participant audio
 */
const MuteButton = () => {

  /*
   * CONTEXT
   */

  const {
    allowShortcuts,
    isMuted,
    toggleIsMuted,
  } = useHolodeck();

  /*
   * EVENT LISTENERS
   */

  // Add keypress listener for space bar
  useEventListener({
    listenerShouldFire: allowShortcuts,
    listener: (event) => {
      // Mute/Unmute on Space Bar
      if ( event.code === "Space" ) {
        // Prevent event from triggering action if an input element has focus
        event.preventDefault();
        // Toggle mute
        toggleIsMuted();
      }
    },
  });

  /*
   * RENDER
   */

  return (
    <div className="communicator__muteButtonContainer">
      <div
        className={`communicator__muteButton${isMuted ? " negativeState" : ""}`}
        role="button"
        onClick={() => toggleIsMuted()}
      >
        <FontAwesomeIcon
          icon={isMuted ? faMicrophoneSlash : faMicrophone}
        />
        <div className="muteButton__shortcutHint">[espaciador]</div>
      </div>
    </div>
  );
};

export default MuteButton;
