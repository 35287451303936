import {
  useEffect, 
  useState,
} from "react";

import { recordTurn } from "../../../../services/datastore";
import { talkka } from "../../../../services/logging";

import {
  getParticipantAudienceTurnsRemaining,
  getParticipantTreatmentType,
} from "../participantHelpers";

/**
 * Logic to manage Audience Turns: Who's eligible, who gets a specific turn,
 * and telling whether or not the current student is an Audience member
 *
 * @param { Array } candidateParticipants - Daily Participant objects for all
 * Candidates present in the room
 * @param { CallableFunction } changeLessonTreatment - Used to demote Audience
 * members who are out of turns to Observers
 * @param { DailyParticipant } currentSpeakerParticipant - for student who is
 * nominated as current speaker
 * @param { String } lessonId - of the current lesson
 * @param { CallableFunction } nominateStudent - Holodeck helper that
 * designates the given student as the current student
 * @returns { Object } exposing Audience Turn info & functionality
 */
export const useAudienceTurn = ({
  candidateParticipants,
  changeLessonTreatment,
  currentSpeakerParticipant,
  lessonId,
  nominateStudent,
}) => {

  /*
   * STATE
   */

  // Participant Objects of Candidates who are present in class and have Audience Turns remaining
  const [
    eligibleCandidateParticipants,
    setEligibleCandidateParticipants,
  ] = useState([]);

  // Remember the last-selected Candidate for Audience Turn
  const [
    lastSelectedCandidateId,
    setLastSelectedCandidateId,
  ] = useState(null);

  // Boolean indicating if current speaker is an Audience Turn
  const [ currentIsAudience, setCurrentIsAudience ] = useState(false);

  /*
   * USE EFFECT
   */

  /**
   * When the Candidate list changes, update who's eligible for Audience Turns.
   * This will fire when:
   * 
   * - A student joins or leaves the Drill
   * - A student's audienceTurnLimit changes
   * - Any other student change that fires a participant-joined,
   *   participant-left, or participant-updated event
   */ 
  useEffect(() => {

    // Sort new candidates list into those who have turns left and those who don't
    const [
      candidatesWithTurns,
      candidatesOutOfTurns,
    ] = candidateParticipants.reduce( (arrays, participant) => {
      if ( getParticipantAudienceTurnsRemaining(participant) > 0 ) {
        arrays[0].push( participant );
      }
      else {
        arrays[1].push( participant );
      }
      return arrays;
    }, [ [], [] ]);

    // Demote candidates with 0 turns remaining
    candidatesOutOfTurns.forEach( participant => changeLessonTreatment({
      participant,
      newLessonTreatment: "OBSERVER",
    }));

    // Candidates with >= 0 turns remaining are eligible for Audience Turn
    setEligibleCandidateParticipants( candidatesWithTurns );

  }, [ candidateParticipants ]);

  // Whenever the currentSpeakerParticipant changes, check if they're a Candidate. If so, update state!
  useEffect(() => {

    // If we just nomiated a Candidate, update the last-nominated Candidate in state
    if ( getParticipantTreatmentType(currentSpeakerParticipant) === "CANDIDATE-SPEAKER" ) {
      setLastSelectedCandidateId( currentSpeakerParticipant.user_id );
    }

    // Get studentIds of Candidates, and check if one of them is the current Speaker!
    setCurrentIsAudience(() => (
      currentSpeakerParticipant
      && candidateParticipants.map( p => p.user_id )
        .includes( currentSpeakerParticipant?.user_id )
    ));

  }, [ currentSpeakerParticipant ]);

  /*
   * HELPERS
   */

  // Nominate an eligible candidate to be the current student
  const startAudienceTurn = () => {

    // If we don't have any eligible candidates, we can't do anything!
    if ( !eligibleCandidateParticipants?.length ) {
      return;
    }

    // Eliminate the last Candidate who was nominated as Current Student from consideration
    const validCandidateParticipants = eligibleCandidateParticipants
      .filter( p => p.user_id !== lastSelectedCandidateId );

    // Randomly select a student from the list of eligible candidates (or the only one in it)
    const selectedCandidate = eligibleCandidateParticipants?.length === 1
      ? eligibleCandidateParticipants[0]
      : validCandidateParticipants[
        Math.floor(Math.random() * validCandidateParticipants.length)
      ];

    // In edge-case situations, we could get an undefined selectedCandidate. Protect against that!
    if ( selectedCandidate ) {
      // Nominate the selected Candidate
      nominateStudent( selectedCandidate );

      // Fire and forget async function to record that the student was selected in Firestore
      recordTurn({
        studentId: selectedCandidate.user_id,
        lessonId,
        lessonTreatmentType: "CANDIDATE-SPEAKER",
      });
    }
    // If we have no selectedCandidate, just clear the current student
    else {
      // TODO: This keeps the teacher from experiencing an error, but doesn't help illuminate what happened and what the teacher should do. What's a better / more helpful behavior, here?
      nominateStudent( null );

      // TEMP: better understand edge case we've run into by logging info to logEvent. Cramming info into an awkward string to make sure we have state at this moment in logs.
      // HYPOTHESIS: duplicate entries in eligibleCandidateParticipants (user who joined on second device?), that student was selected as current student for an audience turn and was the only CANDIDATE present. so eligibleCandidateParticipants would have length < 1, and validCandidateParticipants would filter out to no entries (because both duplicates share the same id and were filtered out), so we got an undefined selectedCandidate. If this is what happened, in this log entry we should see eligibleCandidateParticipants with multiple entries with the same id, and validCandidateParticipants as an empty array
      // CONFIRMED: Hypothesis above is exactly what happened in this logEvent: https://console.firebase.google.com/u/0/project/dazzling-plate-277321/firestore/data/~2FlogEvents~2FyaERHKKVRYfRY3sROprk
      talkka.error(
        `Selected undefined selectedCandidate from eligibleCandidateParticipants: [ ${eligibleCandidateParticipants.map(p => p.user_id).join(", ")} ] and validCandidateParticipants: [ ${validCandidateParticipants.map(p => p.user_id).join(", ")} ]`,
      );
    }

    // When nominated for Audience Turn, student's Speak App will decrement the number of Audience Turns remaining in the student's user_name, and that change will propagate to the teacher via participant-updated -- no need to manually update it here
  };

  /*
   * RETURN
   */

  return {
    currentIsAudience,
    eligibleCandidateParticipants,
    startAudienceTurn,
  };
};
