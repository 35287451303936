import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { getOneModule, getVocabularyForModule } from "../../../services/datastore";
// user context 
import { getCurrentUser } from "../../../services/user";

import UploadVocabulary from "./UploadVocabulary";

// Renders the vocabulary for the specified module
// Enables Admins to upload vocabulary for a specified module
const ModuleVocabulary = () => {

  /*
   * CONTEXT
   */

  // Info for logged-in teacher
  const userInfo = getCurrentUser();

  // Hook used to grab the query string
  const location = useLocation();

  /*
   * STATE
   */

  // The module we are grabbing the vocabulary for
  const [module, setModule] = useState(null);
  
  // Holds the vocabulary array that we fetch from the backend
  const [vocabulary, setVocabulary] = useState(null);

  const [loading, setLoading] = useState(true);

  // Runs on Component mount 
  useEffect(() => {

    // Get the moduleId from the query string
    const searchParams = new URLSearchParams(location.search);
    const moduleId = searchParams.get("moduleId");

    // Gets the module and put it in state
    // Gets the module's vocabulary and put it in state
    // Once the fetching is complete, set loading to false
    performAsyncFunctions(moduleId);
  }, []);

  // Gets the module and put it in state
  // Gets the module's vocabulary and put it in state
  // Once the fetching is complete, set loading to false
  const performAsyncFunctions = async (moduleId) => {
    const result = await Promise.all([fetchModule(moduleId), fetchVocabulary(moduleId)]);
    result ? setLoading(false) : null;
  };

  // Gets the module from the backend
  const fetchModule = async (moduleId) => {
    const module = await getOneModule(moduleId);
    setModule(module);
  };

  // Gets the vocabulary from the backend given a specified module
  const fetchVocabulary = async (moduleId) => {
    const vocabulary = await getVocabularyForModule(moduleId);
    setVocabulary(vocabulary);
  };


  // Given a module, return a string formatted to identify the module
  function formatModule(module) {
    return `${module.difficulty} ${module.unit_name} ${module.unit_number}: ${module.lesson_name} (${module.category})`;
  }

  // Check user permission to upload vocab
  const userCanUploadVocab = userInfo?.isAdmin || userInfo?.canManageModules;
  
  //If the module has not been set yet, display the loading page
  return loading ? 
    <div>Loading...</div>
    :
  // If the module exists, display the module and display the vocabulary for that module if it exists
    module ?
      <div>
        {/* Show the name of the module if it exists, otherwise show nothing */}
        <p className="module-name">Module: {module ? formatModule(module) : null}</p>
        <div className="vocabulary-container">
          <div className={userCanUploadVocab ? "vocabulary--admin" : "vocabulary--non-admin"}>
            {/* Display the Vocabulary if it exits. Otherwise display message that "No vocabulary uploaded yet for this module" */}
            {vocabulary
              ? vocabulary.map((word, i) => 
                <div className={userCanUploadVocab ? "display-vocabulary-admin" : "display-vocabulary-non-admin"} key={`word-${i}`}>
                  <p className="native-language">{word.en}</p> 
                  <p className="target-language">{word.es}</p>
                </div>) 
              : <p className={userCanUploadVocab ? "no-vocabulary-uploaded-message-admin" : "no-vocabulary-uploaded-message-non-admin"}>
              No vocabulary uploaded yet for this module
              </p>}
          </div>
          {}
          {/* Only enable the UploadVocabulary component if the teacher has permission */
            module && userCanUploadVocab 
              ? (
                <div className="upload-vocabulary-container">
                  <UploadVocabulary moduleId={module.id} setVocabulary={setVocabulary}/>
                </div>
              )
              : null
          }
        </div>
      </div>
      :
    // If the module does not exists, display message that "The module you requested does not exist"
      <div>
        <p className="module-name">Module:</p>
        <p className="no-vocabulary-uploaded-message-non-admin"> The module you requested does not exist</p>
      </div>;
};

export default ModuleVocabulary;
