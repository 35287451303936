// This component has the same name as src/components/Lesson-Components/ModuleSelector -- but that component will be deprecated once we're off of Zoom & lesson.js, so I'm ok with the naming collision, for now.

import React, { useState } from "react";

import { updateLessonModuleById } from "../../../services/datastore";
import { useHolodeck } from "../Holodeck";

import ModuleSearch from "../../../components/ModuleSearch";

import "./moduleSelector.scss";

/**
 * Update the module for the ongoing drill
 * 
 * @param { Boolean } isVisible - indicates if module selector modal is on
 * screen or not
 * @param { CallableFunction } close - Function to hide modal
 * @param { String } lessonId - of lesson for this WebDrill
 * @param { Object } module - All info for module currently selected for this
 * drill - from Teach.js state
 * @param { CallableFunction } setModule - sets new module in Teach.js state
 * @returns Markup for module-changing modal
 */
const ModuleSelector = ({
  isVisible,
  close,
  lessonId,
  module,
  setModule,
  limitLanguageTo = null,
}) => {
  // If hidden, don't show anything!
  if (!isVisible) return null;

  const { setSlideIndex, sendMessage } = useHolodeck();

  // Remember new module ID
  const [newModuleId, setNewModuleId] = useState(null);

  // Fire when Confirm button clicked
  const setNewModule = async (moduleId) => {
    // If we're selecting the same module as before, don't do anything!
    if (moduleId === module?.id) return;

    // Set selected module as new module on lesson in Firestore
    // TODO: Remember success flag from this return, and adjust if failure
    const newModule = await updateLessonModuleById(lessonId, moduleId);

    // Update module in Teach.js with new module info
    setModule(newModule);

    // Start new module at slide index to 0
    setSlideIndex(0);

    // Send message with new slidesDotComURL to all students
    sendMessage({ moduleId: newModuleId });
  };

  return (
    <div
      className="engineering__moduleModal"
      onClick={close}
    >
      <div
        className="moduleModal__content"
        onClick={ (event) => event.stopPropagation() }
      >
        Select New Module:
        <ModuleSearch
          module={ module }
          // We don't need to use the clearModule functionality of the ModuleSearch, but need to pass something to avoid a console error
          clearModule={ () => {} }
          moduleWasSelected={
            (selectedModuleId) => setNewModuleId(selectedModuleId)
          }
          limitLanguageTo={limitLanguageTo}
          omitWelcome={false}
          omitComingSoon={true}
        />
        <button
          className="moduleModal__button"
          onClick={ async () => {
            // Do things
            await setNewModule(newModuleId);
            // Close modal
            close();
          } }
          role="button"
        >
          Confirm
        </button>
        <button
          className="moduleModal__button"
          onClick={ close }
          role="button"
        >
          Cancel
        </button>
      </div>
    </div>

  );
};

export default ModuleSelector;
