import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  fetchLessonById,
  killLesson,
} from "../../services/datastore";
import LessonSummary from "../../components/LessonSummary";
import "./KillDrillPage.scss";
import AsyncButton from "../../components/AsyncButton";

class KillDrillPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showConfirmationModal: false,
      showSuccessModal: false,
      lesson: undefined,
      request_finished: false,
      getLabels: undefined,
      errorValue: undefined,
    };
  }

  // On component mount, fetch info from Firestore and set it in local state
  componentDidMount() {

    // Fetch in parallel with promise.all, then set state once all calls resolve
    Promise.all([
      fetchLessonById( this.props.match.params.lessonID ),
    ]).then(( vals ) => {
      this.setState({
        lesson: vals[0],
        request_finished: true,
      });
    });

  }

  render() {
    if (!this.state.lesson && !this.state.request_finished) {
      return <div className="centerer"><p>Loading Drill Info</p></div>;
    }

    if (!this.state.lesson && this.state.request_finished) {
      return <div className="centerer"><p>Drill not found</p></div>;
    }

    const studentRows = this.state.lesson.registrants.map((stu, i) => (
      <tr key={`stu${i}`} >
        <td>
          <input disabled type="checkbox" defaultChecked={true} />
        </td>
        <td>
          {`${stu.firstName} ${stu.lastName}`}
        </td>
        <td>
          {stu.registrationTreatmentType}
        </td>
      </tr>
    ));

    return (
      <div
        role="button"
        tabIndex="0"
        onKeyDown={(e) => {
          if (e.key === "Escape") {
            this.setState({ showConfirmationModal: false });
          }
        }}
      >
        {
          this.state.showConfirmationModal ? (
            <div className="modal">
              <div className="modal-body">
                <div role="button"
                  className="x-out"
                  tabIndex={0}
                  onClick={() => {
                    this.setState({ showConfirmationModal: false });
                  }}
                ><i className="fas fa-times" />
                </div>
                <div className="centerer" style={{ flexDirection: "column" }}>
                  <p style={{ maxWidth: "80%" }}>¿Estás seguro de que quieres matar este drill y mandar un correo a todos los estudiantes, disculpándonos por la cancelación?</p>
                  {this.state.errorValue ? <p className="error">{this.state.errorValue}</p> : <div />}
                  <AsyncButton
                    className="kill-drill-button"
                    initialText="Matar"
                    loadingText="Killing..."
                    timeout={25000}
                    completeText="Drill Killed"
                    disableOnComplete
                    completeFunction={() => {
                      this.setState({ showConfirmationModal: false, showSuccessModal: true });
                    }}
                    errorFunction={(e) => {
                      this.setState({ errorValue: e });
                    }}
                    function={() => killLesson(this.state.lesson.id)}
                  />
                </div>
              </div>
            </div>
          ) : <div />
        }
        {
          this.state.showSuccessModal ? (
            <div className="modal">
              <div className="modal-body">
                <div className="x-out"
                  tabIndex={0}
                  role="button"
                  onClick={() => {
                    this.setState({ showSuccessModal: false });
                  }}
                ><i className="fas fa-times" />
                </div>
                <div className="centerer" style={{ flexDirection: "column" }}>
                  <p className="green">Esta lección ha sido eliminada con éxito.</p>
                  <button type="button" className="kill-drill-button" onClick={() => {
                    this.props.history.push(`/class/${this.props.match.params.lessonID}`); 
                  }}>Close</button>
                </div>
              </div>
            </div>
          ) : <div />
        }
        <div className="centerer"
          style={{ flexDirection: "column" }}
        >
          <LessonSummary lesson={this.state.lesson} />
          <table className="kill-drill-table">
            <thead>
              <tr>
                <th>Send Email?</th>
                <th>Name</th>
                <th>Registered As</th>
              </tr>
            </thead>
            <tbody>
              {studentRows}
            </tbody>
          </table>
          <button type="button" className="kill-drill-button" onClick={() => {
            this.setState({ showConfirmationModal: true }); 
          }}>Emergency: Kill This Class</button>
          <button type="button" className="kill-drill-button" onClick={() => {
            this.props.history.push(`/class/${this.props.match.params.lessonID}`); 
          }}>Back</button>
        </div>
      </div>
    );
  }
}

export default withRouter(KillDrillPage);
