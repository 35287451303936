// Get current environment
import activeBuildConfig from "../../../configs/activebuildconfig.json";
const { APP_POINTER } = activeBuildConfig;

import version from "../../../version/version.json";
// For version display - add environment if not on prod
const versionString = `${version.version} ${APP_POINTER !== "PROD" ? APP_POINTER : ""}`;

// Datastore function to record event/error info in Firestore
import { saveLogEvent } from "../datastore";

/*
 * MAIN FUNCTIONS
 */

/**
 * Print error to console if in logging-authorized environment
 * Ship log info to Firestore
 */
function error() {
  if ( canLog() ) {
    // Lean on built-in arguments object to maintain default console behavior
    console.error(...arguments);
  }

  // Use helper to save log info
  recordLogEvent(arguments, "ERROR");
}

/**
 * Print info to console if in logging-authorized environment
 * Ship log info to Firestore
 */
function info() {
  if ( canLog() ) {
    // Lean on built-in arguments object to maintain default console behavior
    console.info(...arguments);
  }

  // Use helper to save log info
  recordLogEvent(arguments, "INFO");
}

/**
 * Print log to console if in logging-authorized environment
 * DOES NOT ship log info to Firestore
 */
function log() {
  if ( canLog() ) {
    // Lean on built-in arguments object to maintain default console behavior
    console.log(...arguments);
  }
}

/**
 * Print warning to console if in logging-authorized environment
 * Ship log info to Firestore
 */
function warn() {
  if ( canLog() ) {
    // Lean on built-in arguments object to maintain default console behavior
    console.warn(...arguments);
  }

  // Use helper to save log info
  recordLogEvent(arguments, "WARN");
}

/*
 * HELPERS
 */

// Check if in environment that allows logging
const canLog = () => APP_POINTER === "DEV";

// Helper function to get the stack trace of an event
const getStack = () => {
  try {
    throw new Error();
  }
  catch (error) {
    // Modify the stack before returning by trimming whitespace, removing the first entry (which should just be the word "Error"), and joining the entries together into one string with a standard delimiter
    return error.stack.split("\n").map(l => l.trim()).slice(1).join("; ");
  }
};

// Takes the arguments passed to talkka.error and tries to figure out what detail and stack trace to log in Firestore
const interpretLogArguments = ( passedArgs ) => {

  // Check if there is an error (Native JavaScript, or Daily-emitted) in the passed arguments, and extract info
  const {
    // True if there's an error
    hasError,
    // Populated if there was an error
    errorMessage,
    // Always has an error-generated or best-guess stack
    stack,
  } = findErrorInArgs( passedArgs );

  // If the first argument is a string, use it as the eventMessage
  const eventMessage = typeof passedArgs[0] === "string"
    ? passedArgs[0]
    // If we have an error, use its message as the eventMessage
    : hasError
      ? errorMessage
      // If we don't have a string or an error, just try to stringify the first argument
      : passedArgs[0].toString();

  // return message and stack as computed for given error
  return {
    eventMessage,
    eventStack: stack,
  };
};

/**
 * Given the arguments passed to a talkka.X call, extract error information (if
 * any), and return an object with useful details
 *
 * @param { arguments } passedArgs - all arguments that were passed to the
 * talkka.X logging function
 * @returns { Object } with the following keys:
 * @returns { Boolean } hasError - True if there is a native JS error or
 * Daily-emitted error object in the arguments
 * @returns { String } errorMessage - The error message if one was in the args,
 * otherwise a blank string
 * @returns { String } stack - The stack trace of the error, if one was in
 * the args, or a stack generated from here if not
 */
const findErrorInArgs = ( passedArgs ) => {

  // Will find and return the first error in arguments, or undefined if there isn't one
  const javascriptError = [...passedArgs].find(arg => arg instanceof Error);

  // Daily's "error" objects have different signatures based on their type, so our best bet is to find the "action" key that's on all of them, and see if it exactly matches what Daily would emit for that error type

  // We'll assume that there will only be one Daily error passed to our logger, so we'll just find the first one that matches a known "action"
  const dailyError = [...passedArgs].find((arg) => (
    // Daily errors are just objects
    typeof arg === "object"
    // Daily error objects all have the "action" key
    && Object.keys( arg ).includes("action")
    // We check for camera and nonfatal errors, as well as generic Daily errors
    && [ "camera-error", "nonfatal-error", "error" ].includes(arg.action)
  ));

  // Get message and stack info from the error object, preferring the native JS error, if there is one
  const errorMessage =
    javascriptError
      ? javascriptError.message
      : dailyError
        ? `${dailyError.action} - ${
          // Daily stores error messages in the errorMsg key, but sometimes it's just error.errorMsg, and sometimtes it's error.errorMsg.errorMsg. Since we can't just call .toString() on an object, we need another ternary to check...
          typeof dailyError.errorMsg === "string"
            ? dailyError.errorMsg
            : dailyError.errorMsg.errorMsg
        }`
        // If we don't have an error, we don't have an error message!
        : "";

  // Get the stack trace from the error, or grab it from here
  const stack =
    javascriptError
      ? javascriptError.stack
      // For Daily errors OR no error at all, grab the Stack from this point
      : getStack();

  // Return in the shape described in the JSDoc
  return {
    // hasError is true if we have either a JS error or a Daily error
    hasError: !!(javascriptError || dailyError),
    errorMessage,
    stack,
  };
};

// Save log info in Firestore using datastore helper
const recordLogEvent = (logArgs, logType) => {
  // Use helper to extract error message and stack from passed arguments.
  const { eventMessage, eventStack } = interpretLogArguments(logArgs);

  // All errors logged with this function should also be saved to Cloud Functions
  saveLogEvent({
    appVersion: versionString,
    eventDetail: eventMessage,
    eventFiredAtUTC: (new Date()).toISOString(),
    eventStackTrace: eventStack,
    eventType: logType,
    route: window?.location?.href,
    userAgent: window?.navigator?.userAgent,
    // Logged-in teacher's email, or null if not present
    userEmail: sessionStorage?.getItem("userEmail"),
  });
};

/*
 * RETURN
 */

// Collect loggers into convenience object
export const talkka = {
  error,
  info,
  log,
  warn,
};
