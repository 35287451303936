import React, { useEffect, useState } from "react";
import Modal from "../../../components/modal";
import AsyncButton from "../../../components/AsyncButton";
import { changeStudentLevel, changeStudentVipExpirationDate, changeStudentType } from "../../../services/datastore";

/**
 * Component - Modal to be displayed when a student property can't be updated
 * 
 * @param { Boolean } show - flag to determine if the modal is shown
 * @param { Function } hide - callback for the modal to set show to false
 */
export function NoUpdateModal({show, hide}) {
  return (
    <Modal
      showModal={show}
      modalTextArr={["This property cannot be modified at this time."]} 
      modalButtonText={"Close"}
      onXOutFunction={() => {
        hide(); 
      }}
      onSubmitFunction={() => {
        hide();
      }}
    />
  );
}

/**
 * Component - Modal to update a student's level
 * 
 * @param {*} student - the current student being managed
 * @param { Function } refresh - callback to refresh all data lines after update completes
 * @param { Boolean } show - flag to determine if the modal is shown
 * @param { Function } hide - callback for the modal to set show to false
 */
export function LevelUpdateModal({student, refresh, show, hide}) {
  const [newLevel, setNewLevel] = useState(student?.level || "1");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);

  return (
    <Modal
      showModal={show && student}
      onXOutFunction={() => {
        setSuccess(false);
        setError(null);
        hide();
      }}
      modalTextArr={["Please enter a new level: 1, 2, or 3."]}
      hideButton
    >
      <input 
        type="number" 
        min={1}
        max={3}
        value={newLevel} 
        onChange={(event) => {
          setNewLevel(event.target.value);
        }}
      />
      <AsyncButton
        className="updateButton"
        initialText="Update"
        loadingText="Processing..."
        completeText="Updated"
        disableOnComplete
        function={async () => {
          const res = await changeStudentLevel(student.studentId, student.level, newLevel);
          if (res.success) {
            setSuccess(true);
            setError(null);
          } 
          else {
            setSuccess(false);
            setError(res.error);
          }
          return res;
        }}
        completeFunction={refresh}
      />
      {success && <div className="successMessage">The update was successful.</div>}
      {error && <div className="errorMessage">{error}</div>}
    </Modal>
  );
}

/**
 * Component - Modal to update a student's VIP expiration date
 * 
 * @param {*} student - the current student being managed
 * @param { Function } refresh - callback to refresh all data lines after update completes
 * @param { Boolean } show - flag to determine if the modal is shown
 * @param { Function } hide - callback for the modal to set show to false
 */
export function ChangeVipExpModal({student, refresh, show, hide}) {
  const [newDate, setNewDate] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (student?.VIP_expiration) {
      setNewDate((new Date(student.VIP_expiration)).toISOString().split("T")[0]);
    }
  }, [student?.VIP_expiration]);
    
  return (
    <Modal
      showModal={show && student}
      onXOutFunction={() => {
        setSuccess(false);
        setError(null);
        hide();
      }}
      modalTextArr={[
        "Please enter a new VIP expiration date.",
      ]}
      hideButton
    >
      <input 
        type="date"
        value={newDate} 
        onChange={(event) => {
          setNewDate(event.target.value);
        }}
      />
      <AsyncButton
        className="updateButton"
        initialText="Update"
        loadingText="Processing..."
        completeText="Updated"
        disableOnComplete
        function={async () => {
          const date = new Date(newDate);
          const formattedDate = getFormattedDate(date);
          const res = await changeStudentVipExpirationDate(student.studentId, student.VIP_expiration, formattedDate);
          if (res.success) {
            setSuccess(true);
            setError(null);
          } 
          else {
            setSuccess(false);
            setError(res.error);
          }
          return res;
        }}
        completeFunction={refresh}
      />
      {success && <div className="successMessage">The update was successful.</div>}
      {error && <div className="errorMessage">{error}</div>}
    </Modal>
  );
}

/**
 * Component - Modal to update a student's type
 * 
 * @param {*} student - the current student being managed
 * @param { Function } refresh - callback to refresh all data lines after update completes
 * @param { Boolean } show - flag to determine if the modal is shown
 * @param { Function } hide - callback for the modal to set show to false
 */
export function TypeUpdateModal({student, refresh, show, hide}) {
  const [newType, setNewType] = useState(student?.student_type || "TRIAL");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);

  return (
    <Modal
      showModal={show && student}
      onXOutFunction={() => {
        setSuccess(false);
        setError(null);
        hide();
      }}
      modalTextArr={["Please select a new Student Type:"]}
      hideButton
    >
      <select 
        value={newType} 
        onChange={(event) => {
          setNewType(event.target.value);
        }}
      >
        <option value="TRIAL">TRIAL</option>
        <option value="ACTIVE">ACTIVE</option>
        <option value="BRONZE">BRONZE</option>
        <option value="SILVER">SILVER</option>
        <option value="GOLD">GOLD</option>
        <option value="PLATINUM">PLATINUM</option>
      </select>
      <AsyncButton
        className="updateButton"
        initialText="Update"
        loadingText="Processing..."
        completeText="Updated"
        disableOnComplete
        function={async () => {
          const res = await changeStudentType(student.studentId, student.student_type, newType);
          if (res.success) {
            setSuccess(true);
            setError(null);
          } 
          else {
            setSuccess(false);
            setError(res.error);
          }
          return res;
        }}
        completeFunction={refresh}
      />
      {success && <div className="successMessage">The update was successful.</div>}
      {error && <div className="errorMessage">{error}</div>}
    </Modal>
  );
}

/**
 * Returns date formatted in the form it is formatted in the database (MM/dd/yyyy)
 * @param {Date} date takes a date object
 * @return {String} with the formatted date
 */
export const getFormattedDate = (date) => {
  const year = date.getUTCFullYear();

  let month = (1 + date.getUTCMonth()).toString();
  month = month.length > 1 ? month : "0" + month;

  let day = date.getUTCDate().toString();
  day = day.length > 1 ? day : "0" + day;
  
  return month + "/" + day + "/" + year;
};
