import React from "react";

/**
 * Renders one row of ReviewTable, showing the given data for one teacher
 *
 * @param { Object } teacherReviewData - data computed in CF about reviews for
 * classes taught by this teacher in the user-selected time period
 */
const ReviewRow = ({ teacherReviewData }) => {

  return (
    <tr>
      <td>{ teacherReviewData.teacherName }</td>
      <td>{ teacherReviewData.classesTaught }</td>
      <td>{ teacherReviewData.beepboopsGivenPerClass }</td>
      <td>{ teacherReviewData.totalNumReviews }</td>
      <td>{ teacherReviewData.totalAverageRating }</td>
      <td>{ teacherReviewData.vipAverageRating }</td>
      <td>{ teacherReviewData.freeAverageRating }</td>
    </tr>
  );
};

export default ReviewRow;
