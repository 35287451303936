import React, { useEffect, useState, useContext } from "react";
import { onAuthStateChanged } from "firebase/auth";

import { firebaseAuth } from "./base";
import { getUserInfo } from "./datastore";

export const UserContext = React.createContext();

export const UserProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [pending, setPending] = useState(true);

  useEffect(() => {
    onAuthStateChanged(firebaseAuth, async (user) => {
      // Set loading flag while fetching user data
      setPending(true);

      // If we have a user, set info in state to pass through context
      if (user) {
        const userInfo = await getUserInfo();
        setCurrentUser(userInfo);

        // Talkka logging needs user email outside of Context, so toss it into sessionStorage
        sessionStorage.setItem("userEmail", userInfo.email);
      }
      // If no user, remove previous user info from context
      else {
        setCurrentUser(null);
      }

      // De-activate loading flag
      setPending(false);
    });
  }, []);

  if (pending) {
    return <>Loading...</>;
  }

  return (
    <UserContext.Provider
      value={{
        currentUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

// Helper function to get current user
export const getCurrentUser = () => {
  // Grab context to use
  const context = useContext(UserContext);
  // If no valid context, return false
  if (context === undefined) {
    return false;
  }
  // Otherwise, return context value!
  return context.currentUser;
};
