import { useState, useEffect, useRef } from "react";
import joinSound from "../sounds/student-joining-sound.wav";

const useJoinSound = (allParticipants) => {
  const [lastParticipantCount, setLastParticipantCount] = useState(0);
  const [soundEnabled, setSoundEnabled] = useState(true);
  const audioRef = useRef(new Audio(joinSound));

  useEffect(() => {
    if (allParticipants && soundEnabled) {
      const nonInstructorParticipants = allParticipants.filter(participant =>
        !participant.user_name?.startsWith("instructor-"),
      );

      const currentCount = nonInstructorParticipants.length;

      if (currentCount > lastParticipantCount) {
        // A new student has joined
        audioRef.current.play();
      }

      // Update the last participant count
      setLastParticipantCount(currentCount);
    }
  }, [allParticipants, lastParticipantCount, soundEnabled]);

  const enableSound = (enabled) => {
    setSoundEnabled(enabled);
  };

  return { enableSound, soundEnabled };
};

export default useJoinSound;
