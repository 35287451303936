import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import Nav from "./Nav";
import Clock from "./clock";

// Get current app version
import version from "../../../version/version.json";
// Get flag for environment in which app is being run
import activebuildconfig from "../../../configs/activebuildconfig.json";
const { APP_POINTER } = activebuildconfig;

// Get logo image
import beepboopLogo from "../../images/beepbooplogo.svg";
// Get user info from context
import { getCurrentUser } from "../../services/user";

// Import styles
import "./header.scss";

/**
 * Header component! Renders blue top bar and all of its contents
 *
 * @param { Boolean } errorFallback - Used to force render in ErrorFallback
 * component on any page
 * @returns markup for sitewide header
 */
const Header = ({ errorFallback }) => {
  // Grab location from router hook
  const location = useLocation();

  // If we're on a lesson page and this header was generated in app.js, don't render anything!

  /*
   * Don't render header if we're on a teaching page
   */
  if (
    !errorFallback
    && location.pathname.startsWith( "/teach/" )
  ) {
    return null;
  }

  // State to determine if we have a logged-in user or not
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  // Pull user info from context
  const currentUser = getCurrentUser();

  // On component mount, check for a logged-in user
  useEffect(() => {
    // If user exists, we're logged in!
    // Double bang converts any truthy or falsy value to appropriate boolean
    setIsLoggedIn(!!currentUser);
  }, []);

  // Construct welcome message for logged-in user
  const userWelcome = currentUser
    ? (
      <div className="header-welcome">
        Bienvenid@,{" "}
        <span className="header-welcome__name">
          {currentUser.preferred_name}
        </span>
      </div>
    )
    : null;

  // For version display - add environment if not on prod
  const versionString = `${version.version} ${APP_POINTER !== "PROD" ? APP_POINTER : ""}`;

  return (
    <div className="headerContainer">
      <Link to="/"><img className="header-logo" src={beepboopLogo} alt="" /></Link>
      {// Show clock and welcome message only for logged-in header
        isLoggedIn
        && (
          <>
            {userWelcome}
            <Clock />
          </>
        ) 
      }
      <Nav isLoggedIn={isLoggedIn} />
      <div className="nav-version">{versionString}</div>
      <div className="headerBackground" />
    </div>
  );
};

// export default withRouter(Header);
export default Header;
