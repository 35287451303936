import React, { useEffect, useState } from "react";

// Sort and display today's lessons
import TodayLessons from "./TodayLessons";

// Display future lessons
import FutureLessons from "./FutureLessons";

// Display Past Lessons
import PastLessons from "./PastLessons";

// Local styles
import "./myLessons.scss";

/**
 * Renders all "upcoming" (non-incomplete and non-unclaimed)
 * lessons on home screen
 *
 * @param { Object } lessons - Lessons fetched from home.js, in an object keyed by date in MM/DD/YYYY format
 * @param { Boolean } userIsAdmin - flag for if user is admin per database
 * @param { array } allTeachers - names of all teachers in database
 * @param { Function } updateLessonTeacher - used to set new teacher on a lesson object in homepage state
 * @returns markup for all "upcoming" lessons
 */
const MyLessons = ({
  lessons, todayDate, userIsAdmin = false, allTeachers, updateLessonTeacher,
}) => {
  // State to hold lessons
  const [pastLessons, setPastLessons] = useState(false);
  const [todayLessons, setTodayLessons] = useState(false);
  const [futureLessons, setFutureLessons] = useState(false);

  // On component load, or when lessons or todayDate props change, set initial lessons state
  useEffect(() => {
    sortLessonsByDate();
  }, [lessons, todayDate]);

  // Helper to sort lessons by date
  const sortLessonsByDate = () => {
    // Check if today's date is in the passed-in lessons
    if (Object.prototype.hasOwnProperty.call(lessons, todayDate)) {
      // If so, set today's lessons in state
      setTodayLessons(lessons[todayDate]);
    }
    else {
      // If there are no lessons for today, make sure todayLessons is false in state
      setTodayLessons(false);
    }

    // Convert today's date to ISO string once
    const todayISO = convertLessonDateToISO(todayDate);

    // Objects to hold lessons sorted into past and future dates
    const [past, future] = [{}, {}];

    // Loop over date keys in lessons object, and split into past and future days
    Object.keys(lessons).forEach((date) => {
      // Convert lesson date to ISO
      const dateISO = convertLessonDateToISO(date);

      // If this dateISO is less than todayISO, the lessons at this key are in the past
      if (dateISO < todayISO) {
        past[date] = lessons[date];
      }
      else if (dateISO > todayISO) {
        // If this dateISO is greater than todayISO, the lessons at this key are in the future
        future[date] = lessons[date];
      }
    });

    // Check if there are any past lessons
    if (Object.keys(past).length > 0) {
      // If so, set past lessons in state
      setPastLessons(past);
    }
    else {
      setPastLessons(false);
    }

    // Check if there are any future lessons
    if (Object.keys(future).length > 0) {
      // If so, set future lessons in state
      setFutureLessons(future);
    }
    else {
      setFutureLessons(false);
    }
  };

  // Conditionally render text to display if no lessons are present for admins & non-admins
  const noLessonsMessage = `No lessons found for the selected ${userIsAdmin ? "combination of date range and instructor(s)" : "date range"}.`;

  return (
    <div className="myLessons">
      {pastLessons
        && <PastLessons lessons={pastLessons} userIsAdmin={userIsAdmin} allTeachers={allTeachers} />}
      {todayLessons
        && (
          <TodayLessons lessons={todayLessons} userIsAdmin={userIsAdmin} allTeachers={allTeachers} todayDate={todayDate} updateLessonTeacher={updateLessonTeacher} />
        )}
      {futureLessons
        && (
          <FutureLessons lessons={futureLessons} userIsAdmin={userIsAdmin} allTeachers={allTeachers} updateLessonTeacher={updateLessonTeacher} />
        )}
      {// If no past, today, or future lessons, display useful message
        !pastLessons && !todayLessons && !futureLessons
        && (
          <div>{noLessonsMessage}</div>
        )
      }
    </div>
  );
};

export default MyLessons;

// Convert MM/DD/YYYY into YYYY-MM-DD
const convertLessonDateToISO = (date) => {
  // Split on /
  const [m, d, y] = date.split("/");

  // Rejoin and return
  return `${y}-${m}-${d}`;
};
