import React from "react";

import { useHolodeck } from "../Holodeck";

import "./deflectorShield.scss";

/**
 * Renders appropriate message if Teach page is loaded outside of time that
 * Daily room is available.
 * 
 * @param { String } lessonId - ID of lesson in Firestore database
 * @returns 
 */
const DeflectorShield = ({ lessonId }) => {
  const {
    roomWindowStarted,
  } = useHolodeck();

  // Generate appropriate text for time
  const message = !roomWindowStarted
    ? "This lesson has not started yet. Please wait until 5 minutes before the lesson start time before opening the lesson room."
    : "This lesson is over.";
    
  return (
    <div className="deflectorShield">
      <div>
        {message}
      </div>
      <div>
        <a href={`/class/${lessonId}?show-lesson-report=true`}>Go back to Lesson Detail Page</a>
      </div>
    </div>
  );
};

export default DeflectorShield;
