import React, { useEffect, useState } from "react";

import { getCurrentUser } from "../../../services/user";
import { getNYCtime, getNYCdate } from "../../../services/time";
import { makeSyntheticDrill } from "../../../services/datastore";

import LoadingOverlay from "../../../components/LoadingOverlay";

import "./newDrill.scss";
import LessonSummary from "../../../components/LessonSummary";

/**
 * Allows admins to create new drills with instructor assigned
 * 
 * @returns 
 */
const NewDrill = () => {

  /*
   * CONTEXT 
   */

  const user = getCurrentUser();

  const timeNYC = getNYCtime();
  const dateNYC = getNYCdate();

  /*
   * STATE
   */

  // Loading flag
  const [ loading, setLoading ] = useState(false);

  // Hold info for new lesson in separate state objects
  const [ level, setLevel ] = useState(2);
  const [ dateTimeNYC, setDateTimeNYC ] = useState("");
  const [ instructorEmail, setInstructorEmail ] = useState(user.email);
  const [ studentEmail, setStudentEmail ] = useState("synthetic_booking@beepboop.us");
  const [ languageTarget, setLanguageTarget ] = useState("es");

  // Display a useful message if something goes wrong with the request to create the drill
  const [ dateTimeNYCErrorMessage, setDateTimeNYCErrorMessage] = useState(null);

  // Error for instructor email
  const [ instructorEmailErrorMessage, setInstructorEmailErrorMessage ] = useState(null);

  // General error message not associated with one specific input
  const [ formErrorMessage, setFormErrorMessage ] = useState(null);

  // Array of full lesson objects for lessons created during this session
  const [ createdLessons, setCreatedLessons ] = useState([]);

  /*
   * USE EFFECT
   */

  // On load, set dateTimeNYC
  useEffect(() => {
    // Split USA format date into labeled parts
    const [month, date, year] = dateNYC.split("/");

    // Split time into labeled parts, discarding seconds
    const [hours, minutes] = timeNYC.split(":");

    // Generate and set ISO string for dateTimeNYC
    setDateTimeNYC(`${year}-${month}-${date}T${hours}:${minutes}`);

  }, []);

  // When dateTimeNYC is updated, make sure the created drill will have a future exp time
  // Helps to prevent errors when CF dispatches request to create Daily room
  useEffect(() => {
    // Javascript epoch milliseconds for 25 minutes in the past
    const cutoffMillis = new Date(Date.now() - (25 * 60 * 1000));

    // ISO format date string in same format as dateTimeNYC, representing 25 minutes in past in NYC time
    const cutoffInNYC = new Date(cutoffMillis).toLocaleString("sv-SE", {timeZone: "America/New_York"}).replace(" ", "T").slice(0,-3);

    // If the drill is in the past, we don't want to allow it
    if (cutoffInNYC > dateTimeNYC) {
      setDateTimeNYCErrorMessage("Can't create Drills starting more than 25 minutes in the past. Please select a more recent date & time");
    }
    else {
      setDateTimeNYCErrorMessage("");
    }

  }, [ dateTimeNYC ]);

  /*
   * HELPERS
   */

  // On form submit, dispatch request for new drill to CF
  const createNewLesson = async () => {

    // If dateTimeNYC is too far in the past, don't submit the request!
    if ( dateTimeNYCErrorMessage ) {
      return alert("Cannot submit new Drill with invalid date/time");
    }

    // Trigger loading flag
    setLoading(true);

    // Clear all error messages
    setDateTimeNYCErrorMessage(null);
    setInstructorEmailErrorMessage(null);
    setFormErrorMessage(null);
    
    // Convenience object with all new drill info to send
    const newDrillInfo = {
      level,
      dateTimeNYC,
      instructorEmail,
      studentEmail,
      languageTarget,
    };

    // Send request and await response
    const result = await makeSyntheticDrill( newDrillInfo );

    // Check result for an error code
    if ( result.errorCode ) {
      handleErrorCode( result.errorCode );
    }
    else {
      // If no errorCode, add lesson info in return to the beginning of the array in state!
      setCreatedLessons( [result].concat(createdLessons) );
    }

    setLoading(false);
  };

  // Handle errors returned from CF
  const handleErrorCode = ( errorCode ) => {
    
    // Differentiate response based on error code
    switch (errorCode) {
      case 405:
        setFormErrorMessage("Synthetic bookings cannot be made in this environment.");
        break;
      case 488:
        setInstructorEmailErrorMessage("No instructor with this email found in database.");
        break;
      case 489:
        setFormErrorMessage("The combination of date/time and level you entered resulted in a non-unique lessonId. Please change date, time, and/or level.");
        break;
      case 487:
        setFormErrorMessage("The drill was successfully created, but the instructor could not be assigned. Please go to the homescreen and claim the drill.");
        break;
      default:
        setFormErrorMessage("Something went wrong!");
    }
  };

  // 

  /*
   * RENDER
   */

  return (
    <div className="manage__newDrill">
      <LoadingOverlay isVisible={loading} />
      <form
        className="newLesson__form"
        onSubmit={(event) => {
        // Prevent reload
          event.preventDefault();
          // Dispatch request to create new lesson
          createNewLesson();
        }}
      >
        {// Display whole-form error message, if any
          formErrorMessage
          && (
            <div className="newDrill__error">
              {formErrorMessage}
            </div>
          )
        }
        <label className="newDrill__label" htmlFor="dateTimeNYC">
          Drill Date/Time (NYC):
          <input
            className="newDrill__input"
            name="dateTimeNYC"
            type="datetime-local"
            value={ dateTimeNYC }
            onChange={ (e) => setDateTimeNYC(e.target.value) }
          />
        </label>
        {// If we have an error message, display it!
          dateTimeNYCErrorMessage
            && (
              <div className="newDrill__error">
                { dateTimeNYCErrorMessage }
              </div>
            )
        }
        <label className="newDrill__label" htmlFor="instructorEmail">
          Instructor Email: 
          <input
            className="newDrill__input"
            name="instructorEmail"
            type="email"
            value={ instructorEmail }
            onChange={ (e) => setInstructorEmail(e.target.value) }
          />
        </label>
        {// If we have an error message, display it!
          instructorEmailErrorMessage
          && (
            <div className="newDrill__error">
              { instructorEmailErrorMessage }
            </div>
          )
        }
        <label className="newDrill__label" htmlFor="level">
          Level: 
          <input
            className="newDrill__input"
            name="level"
            type="number"
            min="1" 
            max="3"
            value={ level }
            onChange={ (e) => setLevel(e.target.value) }
          />
        </label>
        <label className="newDrill__label" htmlFor="studentEmail">
          Student email: 
          <input
            className="newDrill__input"
            name="studentEmails"
            type="text"
            value={ studentEmail }
            onChange={ (e) => setStudentEmail(e.target.value) }
          />
        </label>
        <label className="newDrill__label" htmlFor="languageTarget">
          Language:
          <select
            className="newDrill__select"
            name="languageTarget"
            onChange={ (e) => setLanguageTarget(e.target.value) }
            value={languageTarget}
          >
            <option value="es">Spanish</option>
            <option value="en">English</option>
          </select>
        </label>
        <input
          className="newDrill__submit"
          type="submit"
          onSubmit={ createNewLesson }
        />
      </form>
      {// If we've created some lessons in this session, show them!
        createdLessons.length > 0
        && (
          <div className="newDrill__createdLessonsContainer">
            <h2 className="newDrill__heading">Lessons Created</h2>
            <div className="newDrill__createdLessons">
              {createdLessons.map((l) => (
                <LessonSummary lesson={l} key={l.id} />
              ))}
            </div>
          </div>
        )
      }
    </div>
  );
};

export default NewDrill;
