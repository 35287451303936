import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

import RatingStars from "../RatingStars";

import "./feedbackDetailModal.scss";

/**
 * Rendered by ClassFeedbackWidget, shows a table with info on each piece of
 * class feedback the teacher has received in the past week
 */
const FeedbackDetailModal = ({ feedback, showModal }) => {
  
  // Holds JSX generated from feedback object
  const [ feedbackMarkup, setFeedbackMarkup ] = useState( null );

  // When feedback updates, generate markup!
  useEffect(() => {

    // No feedback = nothing to display
    if ( !feedback ) {
      return setFeedbackMarkup( null );
    }

    const markupArray = Object.keys(feedback)
      // Remove averages
      .filter(key => key !== "averages")
      // Antipattern to rely on lessonId for class timing, but it's what we have access to, here
      .sort((a, b) => a < b ? 1 : -1)
      // Render rows of feedback forr each lesson
      .map(lessonId => {
        const lessonFeedbackRows = feedback[lessonId].map((f, i) => (
          <tr key={`row-${lessonId}-${i}`}>
            <td>
              {
                ["BRONZE","SILVER","GOLD","PLATINUM"].includes(f.studentType)
                  ? "VIP"
                  : "non-VIP"
              }
            </td>
            <td>{ f.instructorRating ? f.instructorRating : "" }</td>
            <td>{ f.instructorFeedback }</td>
          </tr>
        ));

        // WARNING: THIS IS AN ANTI-PATTERN
        // We shouldn't be parsing lesson date/time from the ID, but this is the quickest way to make a teacher-friendly heading
        // TODO: Rely on lessonScheduledStartUTC and local time to display this info in a safer, more extensible and futureproof way
        const lessonIdTitle = `Class on ${lessonId.slice(0,10)} at ${lessonId.slice(11, 16)}`;

        return (
          <React.Fragment key={`feedbackRows-${lessonId}`}>
            <tr>
              <td colSpan="3" className="feedbackDetailModal__lessonId">
                { lessonIdTitle }
              </td>
            </tr>
            <tr className="feedbackDetailModal__headingsRow">
              <th>Student Type</th>
              <th>Rating</th>
              <th>Comments</th>
            </tr>
            { lessonFeedbackRows }
          </React.Fragment>
        );
      });

    setFeedbackMarkup( markupArray.length ? markupArray : <tr><td>No class feedback given</td></tr> );

  }, [ feedback ]);

  return (
    <Modal
      show={ showModal }
      onHide={() => {}}
      dialogClassName="feedbackDetailModal__container"
    >
      <Modal.Body className="feedbackDetailModal">
        <div className="feedbackDetailModal__scrollContainer">
          <RatingStars
            rating={ feedback?.averages?.averageVIPRating }
            ratingCount={ feedback?.averages?.vipRatingsGiven }
            showDetail
            title="VIP reviews"
          />
          <RatingStars
            rating={ feedback?.averages?.averageFreeRating }
            ratingCount={ feedback?.averages?.freeRatingsGiven }
            showDetail
            title="non-VIP reviews"
          />
          <table className="feedbackDetailModal__table">
            <tbody>
              { feedbackMarkup }
            </tbody>
          </table>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default FeedbackDetailModal;
