import React from "react";
import activebuildconfig from "../../../../configs/activebuildconfig.json";
const { TARGET_BOOKING_COUNT } = activebuildconfig;
/**
 * Component to display statistics about lessons happening in the future
 * @param {Array} props.lessons - lesson objects in the group
 */
const FutureLessonsStats = ({ lessons }) => {
  const numLessons = lessons.length;

  // Count attending students and cancellations
  let numAttendingStudents = 0;
  let numCancellations = 0;
  lessons.forEach((lesson) => { // Iterate over the lessons in the group
    lesson.students.forEach((student) => { // Iterate over the bookings in each lesson
      // If the student has not cancelled, count them as attending
      if (student.attendance_status.toLowerCase() === "cancelled") {
        numCancellations += 1;
      }
      else {
        numAttendingStudents += 1;
      }
    });
  });

  // Compute target bookings and utilization
  const targetBookings = numLessons * TARGET_BOOKING_COUNT;
  const percentBooked = targetBookings ? ((numAttendingStudents / targetBookings) * 100).toFixed(1) : "-";

  // Get today's date in MM/DD/YYYY
  const todayDate = (new Date()).toLocaleString("en-us", {
    timeZome: "America/New_York",
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });

  // Get lessons date from first lesson in list
  const lessonsDate = lessons[0].date;

  // Decide whether to render lessonsDate, or the string "Today"
  const titleDate = todayDate === lessonsDate ? "Today" : `on ${lessonsDate}`;

  // Construct title string
  const title = `${numLessons} Un-Started Lessons ${titleDate}`;

  return (
    <div className="futureLessonsStats">
      <div>{title}</div>
      <div className="statsItem">{`# Attending Students: ${numAttendingStudents}`}</div>
      <div className="statsItem">{`Target # of Attending Students: ${targetBookings}`}</div>
      <div className="statsItem">{`Target Capacity Utilization: ${percentBooked}%`}</div>
      <div className="statsItem">{`# of Cancellations: ${numCancellations}`}</div>
    </div>
  );
};

export default FutureLessonsStats;
