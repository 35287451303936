import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

import { getOneModule } from "../../../services/datastore";
import { getCurrentUser } from "../../../services/user";

import ModuleSearch from "../../../components/ModuleSearch";
import ViewModule from "./ViewModule";
import AddModule from "./AddModule";
import EditModule from "./EditModule";

import "./moduleDetails.scss";

/**
 * COMPONENT ModuleDetails
 *
 * Allows for searching, display, and (in future) editing of module information
 *
 */
const ModuleDetails = ({ location, history }) => {
  /*
   * CONTEXT
   */
  const userInfo = getCurrentUser();

  // Check user permission to edit and add modules
  const userCanEditAndAddModules = userInfo?.isAdmin || userInfo?.canManageModules;

  /*
   * STATE
   */

  // Instantiate loading state to false - will trigger as true if we need to fetch a module
  const [loading, setLoading] = useState(false);

  // Store info on module
  const [module, setModule] = useState({});

  // Toggle between View, Add, and Edit modes
  const [selectedViewOption, setSelectedViewOption] = useState("View");

  /*
   * USE EFFECT
   */

  // On component load, check for module-indicating queryString
  useEffect(() => {
    // Try to grab the moduleId value from the query string
    const moduleId = getModuleIdFromQueryString();

    // If we have a moduleId query string, load the appropriate module
    if (moduleId) {
      // Async function fired and forgotten
      asyncGetModuleById(moduleId);
    }
  }, []);

  // Module can be set by module search component. When it is, update query string
  // This is kind of backwards (child component setting state here, and this component adjusting queryString to match), but this works with minimal changes to ModuleSearch (which is being developed against in another feature branch)
  useEffect(() => {
    // Get id of module in state
    const stateModuleId = module.id;

    // Only proceed if we have a module with an id in state
    if (stateModuleId) {
      // Get id indicated by query string
      const queryStringModuleId = getModuleIdFromQueryString();

      // If the two don't match, update the query string to reflect state
      if (stateModuleId !== queryStringModuleId) {
        const query = new URLSearchParams(location.search);
        query.set("moduleId", stateModuleId);
        history.push({
          pathname: location.pathname,
          search: query.toString(),
        });
      }
    }
  }, [ module ]);

  /*
   * HELPERS
   */

  // Helper to extract module id from query string
  const getModuleIdFromQueryString = () => {
    // Get query string from location
    const query = new URLSearchParams(location.search);

    // Try to grab the moduleId value from the query string
    const moduleId = query.get("moduleId");

    // Return moduleId if we get it, or false if it doesn't exist
    return moduleId || false;
  };

  const getGoogleSlidesLink = () => {
    return "https://docs.google.com/presentation/d/e/".concat(module.publish_code, "/embed?start=false&loop=false&delayms=3000");
  };

  // Callback to select a module by its ID, query the database, and display its details
  const asyncGetModuleById = async (moduleId) => {
    // First render the loading screen & clear module info
    setLoading(true);
    setModule({});

    // Then get module information
    const fetchedModule = await getOneModule(moduleId);

    // Set module info with returned object, or empty one if no return
    setModule(fetchedModule || {});

    // Remove loading flag
    setLoading(false);
  };

  return (
    <>
      <div className="moduleSearchBar">
        {/* Only show the module search bar once the module has loaded or we don't have a module yet */}
        {selectedViewOption === "View" && (module.id || !getModuleIdFromQueryString())
          ? (
            <ModuleSearch
              moduleWasSelected={asyncGetModuleById}
              module={module}
              clearModule={() => {
                history.push({ search: ""});
                setModule({});
              } }
            />
          )
          : null }
      </div>
      {loading
        ? <div>Loading...</div>
        : (
          <div className={selectedViewOption === "View" ? "moduleDetailsView" : "moduleDetailsAddEdit"}>
            <div>
              {userCanEditAndAddModules ?
                <div className="moduleDetailsViewOptions">
                  <p
                    className={selectedViewOption === "View"
                      ? "moduleDetailsViewOption selected"
                      : "moduleDetailsViewOption"}
                    onClick={() => setSelectedViewOption("View")}
                  >
                    View
                  </p>
                  <p
                    className={selectedViewOption === "Add"
                      ? "moduleDetailsViewOption selected"
                      : "moduleDetailsViewOption"}
                    onClick={() => {
                      setSelectedViewOption("Add");
                      history.push({
                        search: "",
                      });
                    }}
                  >
                    Add
                  </p>
                  {module.id ? <p
                    className={selectedViewOption === "Edit"
                      ? "moduleDetailsViewOption selected"
                      : "moduleDetailsViewOption"}
                    onClick={() => setSelectedViewOption("Edit")}
                  >
                    Edit
                  </p> : null}
                </div>
                : null
              }
              <div className="moduleData">
                {selectedViewOption === "View"
                  ? (
                    <ViewModule module={module} />
                  )
                  : selectedViewOption === "Add" && userCanEditAndAddModules
                    ?  <AddModule />
                    : selectedViewOption === "Edit" && userCanEditAndAddModules
                      ? <EditModule module={module} />
                      : null  
                }
              </div>
            </div>
            {selectedViewOption === "View"
              ? (
                <div className="moduleSlides">
                  {module.slidesDotComURL
                    ? <iframe title="Slides.com" src={module.slidesDotComURL} className="slidesDotComIframe" />
                    : null}
                  {module.link
                    ? <iframe title="Google Slides" src={getGoogleSlidesLink()} className="googleSlidesIframe" />
                    : null}
                </div>
              )
              : null
            }
          </div>
        )}
    </>
  );
};

export default withRouter(ModuleDetails);
