import React, {useState, useEffect} from "react";
import { addModule } from "../../../services/datastore";

export default function AddModule() {

  /*
  * STATE
  */
  const [moduleData, setModuleData] = useState({
    level: "L1",
    isPreferred: "false", 
    isWelcome: "false", 
    isComingSoon: "false", 
    useSlidesDotCom: "true",
    language: "Spanish",
    hideFromTopicTree: false,
    lessonEventTypeCategoryRestriction: "",
    conversationPrompt: {en: "", es: ""},
  });

  // Hanldes whether or not the error message is shown
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  // Hanldes whether or not the success message is shown
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  // Controls whether or not the submit button is clickable
  const [disableSubmit, setDisableSubmit] = useState(false);

  // Set moduleData to hold all of the deafult form values
  useEffect(() => {
    setModuleData(
      {level: "L1",
        isPreferred: "false", 
        isWelcome: "false", 
        isComingSoon: "false", 
        useSlidesDotCom: "true",
        language: "Spanish",
        hideFromTopicTree: false,
        lessonEventTypeCategoryRestriction: "",
        conversationPrompt: {en: "", es: ""}},
    );
  }, []);

  // Handles submitting the form to add the module
  const handleSubmit = async (event) => {
    setDisableSubmit(true);
    event.preventDefault();
    const result = await addModule(moduleData);
    result ? setShowSuccessMessage(true) : setShowErrorMessage(true);
  };

  const resetStatus = () => {
    setShowErrorMessage(false);
    setShowSuccessMessage(false);
    setDisableSubmit(false);
  };

  const handleChange = (event) => {
    const key = event.target.name;
    const value = trimSpaces(event.target.value);
    setModuleData({...moduleData, [key]: value});
    resetStatus();
  };

  // Removes leading and trailing spaces from a string
  // Returns the string that was passed as the parameter if its: 1) not a string 2) null, 3) an empty string
  const trimSpaces = string => {
    if (typeof(string) !== "string" || !string){
      return string;
    }
    return string.trim();
  };
  
  return (
    <div className="moduleDetails">
      {showSuccessMessage ? <p className="add-module-success-message">The module has been successfully added.</p> : null}
      {showErrorMessage ? <p className="add-module-error-message">The module you are attempting to add already exists.</p> : null}
      <form 
        className="moduleInformation"
        onSubmit={handleSubmit}>
        <div className="input-field">
          <label htmlFor="level" >
      Level: 
            <br></br>
            <select 
              name="level" 
              onChange={(event) => handleChange(event)}>
              <option value="L1">L1</option>
              <option value="L2">L2</option>
              <option value="L3">L3</option>
            </select>
          </label>
        </div>
        <div className="input-field">
          <label htmlFor="isPreferred"  >
      Preferred:
            <br></br>
            <select  
              name="isPreferred"
              onChange={(event) => handleChange(event)}>
              <option value="false">False</option>
              <option value="true">True</option>
            </select>
          </label>
        </div>
        <div className="input-field">
          <label htmlFor="isWelcome"  >
      Welcome:
            <br></br>
            <select 
              name="isWelcome" 
              onChange={(event) => handleChange(event)}>
              <option value="false">False</option>
              <option value="true">True</option>
            </select>
          </label>
        </div>
        <div className="input-field">
          <label htmlFor="isComingSoon"  >
      Coming Soon:
            <br></br>
            <select  
              name="isComingSoon" 
              onChange={(event) => handleChange(event)}>
              <option value="false">False</option>
              <option value="true">True</option>
            </select>
          </label>
        </div>
        <div className="input-field">
          <label htmlFor="language"  >
      Target Language:
            <br></br>
            <select  
              name="language" 
              onChange={(event) => handleChange(event)}>
              <option value="Spanish">Spanish</option>
              <option value="English">English</option>
            </select>
          </label>
        </div>
        <div className="input-field">
          <label htmlFor="category"  >
      Category: 
            <input  
              name="category" 
              type="text" 
              className="input" 
              required
              onChange={(event) => handleChange(event)}/>
          </label>
        </div>
        <div className="input-field">
          <label htmlFor="unit_name"  >
      Unit Name: 
            <input  
              name="unitName" 
              type="text" 
              className="input" 
              required
              onChange={(event) => handleChange(event)}/>
          </label>
        </div>
        <div className="input-field">
          <label htmlFor="unit_number"  >
      Unit Number: 
            <input  
              name="unitNumber" 
              type="text" 
              className="input" 
              required
              onChange={(event) => handleChange(event)}/>
          </label>
        </div>
        <div className="input-field">
          <label htmlFor="lesson_name"  >
      Module Name: 
            <input  
              name="moduleName" 
              type="text" 
              className="input" 
              required
              onChange={(event) => handleChange(event)}/>
          </label>
        </div>
        <div className="input-field">
          <label htmlFor="lesson_number"  >
      Module Number: 
            <input  
              name="moduleNumber" 
              type="text"  
              className="input" 
              required
              onChange={(event) => handleChange(event)}/>
          </label>
        </div>
        <div className="input-field">
          <label htmlFor="unit_name"  >
            <span className="label">UniqueId:</span>
            <h1 className="read-only-input">
              {`${moduleData?.language}${moduleData?.category || "__"}${moduleData?.moduleNumber || "__"}${moduleData?.level || "__"}`}
            </h1>
          </label>
        </div>
        <div className="input-field">
          <label htmlFor="link"  >
      Google Slides URL (optional): 
            <input  
              name="googleSlidesURL" 
              type="text" 
              className="input"
              onChange={(event) => handleChange(event)}/>
          </label>
        </div>
        <div className="input-field">
          <label htmlFor="quizlet_url"  >
      Quizlet URL(optional): 
            <input 
              name="quizletURL" 
              type="text" 
              className="input"
              onChange={(event) => handleChange(event)}/>
          </label>
        </div>
        <div className="input-field">
          <label htmlFor="useSlidesDotCom"  >
      Use Slides.com:
            <br></br>
            <select  
              name="useSlidesDotCom"
              onChange={(event) => handleChange(event)}>
              <option value="true">True</option>
              <option value="false">False</option>
            </select>
          </label>
        </div>
        <div className="input-field">
          <label htmlFor="slidesDotComURL"  >
      slides.com URL (optional):
            <input  
              name="slidesDotComURL" 
              type="text"  
              className="input"
              onChange={(event) => handleChange(event)}/>
          </label>
        </div>
        <div className="input-field">
          <label htmlFor="questionsAndConversationSectionSlideIndex"  >
      Conversación Slide # (optional): 
            <input  
              name="questionsAndConversationSectionSlideIndex" 
              type="text" 
              className="input"
              onChange={(event) => handleChange(event)}/>
          </label>
        </div>
        <div className="input-field">
          <label htmlFor="publish_code">
      Publish Code (optional): 
            <input 
              name="publishCode" 
              type="text" 
              className="input" 
              onChange={(event) => handleChange(event)}/>
          </label>
        </div>
        <div className="input-field">
          <label htmlFor="topicCardImage">
          Topic Card Image (optional): 
            <input 
              name="topicCardImage" 
              type="text" 
              className="input" 
              onChange={(event) => handleChange(event)}/>
          </label>
        </div>
        <div className="input-field">
          <label htmlFor="youtubeURL">
          Youtube URL (optional): 
            <input 
              name="youtubeURL" 
              type="text" 
              className="input" 
              onChange={(event) => handleChange(event)}/>
          </label>
        </div>
        <div className="input-field">
          <label htmlFor="spotifyURL">
          Spotify URL (optional): 
            <input 
              name="spotifyURL" 
              type="text" 
              className="input" 
              onChange={(event) => handleChange(event)}/>
          </label>
        </div>
        <div className="input-field">
          <label htmlFor="transcriptURL">
          Transcript URL (optional): 
            <input 
              name="transcriptURL" 
              type="text" 
              className="input" 
              onChange={(event) => handleChange(event)}/>
          </label>
        </div>
        <div className="input-field">
          <label htmlFor="hideFromTopicTree">
          Hide from Topic Tree: 
            <select 
              value={moduleData.hideFromTopicTree} 
              name="hideFromTopicTree"
              onChange={(event) => {
                setModuleData({...moduleData, hideFromTopicTree: event.target.value});
                resetStatus();
              }}
            >
              <option value={false}>False</option>
              <option value={true}>True</option>
            </select>
          </label>
        </div>
        <div className="input-field">
          <label htmlFor="lessonEventTypeCategoryRestriction">
          Restrict this module to special lesson type: 
            <select 
              value={moduleData.lessonEventTypeCategoryRestriction} 
              name="lessonEventTypeCategoryRestriction"
              onChange={(event) => {
                setModuleData({...moduleData, lessonEventTypeCategoryRestriction: event.target.value});
                resetStatus();
              }}
            >
              <option value={""}>No restriction</option>
              <option value={"Podcast"}>Podcast</option>
              <option value={"Video"}>Video (Netflix)</option>
              <option value={"1on1"}>1on1 (New VIP)</option>
              <option value={"Welcome"}>Welcome (New Student Drill)</option>
            </select>
          </label>
        </div>
        <div className="input-field">
          <label htmlFor="cprompt-en">
          Conversation Prompt (English) (optional): 
            <input 
              name="cprompt-en" 
              type="text" 
              className="input" 
              value={moduleData.conversationPrompt.en}
              onChange={(event) => { 
                const prompt = {
                  en: event.target.value,
                  es: moduleData.conversationPrompt.es,
                };
                setModuleData({...moduleData, conversationPrompt: prompt});
                resetStatus();
              }}/>
          </label>
        </div>
        <div className="input-field">
          <label htmlFor="cprompt-es">
          Conversation Prompt (Spanish) (optional): 
            <input 
              name="cprompt-es" 
              type="text" 
              className="input" 
              value={moduleData.conversationPrompt.es}
              onChange={(event) => { 
                const prompt = {
                  en: moduleData.conversationPrompt.en,
                  es: event.target.value,
                };
                setModuleData({...moduleData, conversationPrompt: prompt});
                resetStatus();
              }}/>
          </label>
        </div>
        <br></br>
        <div>
          <input 
            className={disableSubmit ? "add-module-submit-button-disabled" : "add-module-submit-button"}
            type="submit" 
            value="Add Module"
            disabled={disableSubmit}>
          </input>
        </div>
      </form>
      {showSuccessMessage ? <p className="add-module-success-message">The module has been successfully added.</p> : null}
      {showErrorMessage ? <p className="add-module-error-message">The module you are attempting to add already exists.</p> : null}
    </div>
  );
}
