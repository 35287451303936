import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import AsyncButton from "./AsyncButton";

class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      errorMessage: "",
    };
    // use a ref so the email and password inputs are sensitive to key presses
    this.submitRef = React.createRef();
  }

  // update username when being typed in
  updateEmail = (event) => {
    this.setState({ email: event.target.value });
  };

  // update password when being typed in
  updatePassword = (event) => {
    this.setState({ password: event.target.value });
  };

  // handle submission/account creation
  onSubmit = () => {
    return new Promise((resolve) => {
      this.props.onSubmit(this.state.email, this.state.password, (success, error) => {
        resolve({ success, error });
      });
    });
  };

  displayErrorMessage = () => {
    if (this.state.errorMessage === null) {
      return (
        <div />
      );
    }
    else {
      return (
        <div className="incomplete-message">{this.state.errorMessage}</div>
      );
    }
  };

  render() {
    return (
      <div>
        <div className="SignUp">
          <div className="signup-field">
            <div> Email (lo mismo que use con Beepboop)</div>
            <input className="form"
              type="text"
              value={this.state.email}
              onChange={this.updateEmail}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  // submit the sign up form if the user presses enter when focused on email input
                  this.submitRef.current.focus();
                }
              }}
            />
          </div>
          <div className="signup-field">
            <div>Contraseña</div>
            <input className="form"
              type="password"
              value={this.state.password}
              onChange={this.updatePassword}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  // submit the sign up form if the user presses enter when focused on email input
                  this.submitRef.current.focus();
                }
              }}
            />
          </div>
          <div>{this.displayErrorMessage()}</div>
          <AsyncButton
            ref={this.submitRef}
            className="submit-button"
            initialText="Create account"
            loadingText="Creating account..."
            timeout={10000}
            completeFunction={() => {
              this.props.history.push("/");
            }}
            errorFunction={(e) => {
              // sign-up failed, show error message
              this.setState({ errorMessage: e });
            }}
            function={() => this.onSubmit()}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(SignUp);
