// This functionality really belongs on the server, but since we can't send functions as part of a JSON return, it's here, for now

// TODO: Incorporate this logic when updating lesson-fetching return as part of the "cut payload of api/getlessons way down" pulse

// Get instrcutor name, or "Unclaimed" if no instructor
const getInstructor = (lesson) => {
  return (lesson.claimed && lesson.instructor) ? lesson.instructor : "Unclaimed";
};

// Get time with seconds removed
const getTime = (lesson) => {
  return lesson.time.slice(0, -3);
};

// Get date formatted with written month name and no year
const getDate = (lesson, args = { withTime: false }) => {
  // Separate date string into month, date, and year
  const [month, date] = lesson.date.split("/");

  // Object to map month string to written month name
  const monthMap = {
    "01": "January",
    "02": "February",
    "03": "March",
    "04": "April",
    "05": "May",
    "06": "June",
    "07": "July",
    "08": "August",
    "09": "September",
    10: "October",
    11: "November",
    12: "December",
  };

  // Use monthMap and date to construct formatted date
  // (e.g. 01/01 => January 01)
  const dateString = `${monthMap[month]} ${date}`;

  // Return based on withTime argument
  return args.withTime ? `${dateString} at ${getTime(lesson)}` : dateString;
};

// Check attendance status of each student, and return total number attending
const getStudentCount = (lesson) => {
  // Counts all students whose attendance_status is "attending"
  return lesson.students.reduce((count, student) => (student.attendance_status.toLowerCase() === "attending" ? count + 1 : count), 0);
};

// Check attendance status of each student, and return total number NOT attending
const getNonAttendingStudentCount = (lesson) => {
  // Counts all students whose attendance_status is not "attending"
  return lesson.students.reduce((count, student) => (student.attendance_status.toLowerCase() === "attending" ? count : count + 1), 0);
};

// Get the number of VIPs attending the class
const getVipCount = (lesson) => {
  // Counts all students who are attending and have a valid VIP status
  return lesson.students.reduce((count, student) => (
    (student.attendance_status.toLowerCase() === "attending" && ["bronze", "silver", "gold", "platinum"].indexOf(student.student_type.toLowerCase()) !== -1) 
      ? count + 1 : count
  ), 0);
};

// Get formatted string showing number of students attending drill
// By default, return total count and VIP count
// Can optionally exclude VIP count
const getDecoratedStudentCount = (lesson, withVipCount = true) => {
  // Get student count
  const count = getStudentCount(lesson);
  // Format count in string
  const countString = `${count} student${count !== 1 ? "s" : ""}`;
  if (!withVipCount) {
    // If we only want total students, return 'em all!
    return countString;
  }
  // If we want VIPs, too, get that tally
  const vipCount = getVipCount(lesson);
  // Append the VIP info to countString
  const doubleCountString = `${countString} (${vipCount} VIP${vipCount !== 1 ? "s" : ""})`;
  return doubleCountString;
};

// Collect functions for export
const helpers = {
  getInstructor, getTime, getDate, getDecoratedStudentCount, getStudentCount, getVipCount, getNonAttendingStudentCount,
};

export default helpers;
