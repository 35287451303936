import React, { useEffect, useState } from "react";

import { useHolodeck } from "../Holodeck/Holodeck";

import "./waitingRoom.scss";

/**
 * Display an overlay blocking interaction with the Drill until 5 seconds after
 * joined-meeting event
 * @returns 
 */
const WaitingRoom = () => {
  const { 
    setAllowShortcuts,
    isConnectedToDaily, 
    makeFirstContact,
  } = useHolodeck();

  // Allow instructor to interact with Drill 5 secs after joining Daily room
  const [ canProceed, setCanProceed ] = useState(false);

  // De-render when instructor has proceeded
  const [ hasProceeded, setHasProceeded ] = useState(false);

  // Once in the room, wait 5 secs before allowing to continue
  useEffect(() => {
    let timeoutId;

    if (isConnectedToDaily) {
      timeoutId = setTimeout(() => setCanProceed(true), 5000);

      return function clearConnectionTimeout() {
        clearTimeout(timeoutId);
      };
    }
    
  }, [ isConnectedToDaily ]);

  // On button click, send messaging alerting students of presence and close WaitingRoom
  const proceed = () => {
    makeFirstContact();
    setAllowShortcuts(true);
    setHasProceeded(true);
  };

  return !hasProceeded
    ? (
      <div className="waitingRoom">
        {// Wait until after connected to Daily to allow to proceed
          canProceed
            ? (
              <div
                className="waitingRoom__proceedButton"
                onClick={proceed}
                role="button"
              >
                Let's Go!
              </div>
            )
            : "Connecting to Drill..."
        }
      </div>
    )
    : null;
};

export default WaitingRoom;
