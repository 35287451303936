import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { asyncGetLessonsByDate } from "../../../services/datastore";

import Modal from "../../modal";

const ClaimLessonModal = ({
  showModal,
  lesson,
  showSuccessMessage = false,
  closeModalFunc,
  claimLessonFunc,
}) => {
  // State to hold conflicting lesson times
  const [conflictingTimes, setConflictingTimes] = useState([]);

  // Loading indicator
  const [loading, setLoading] = useState(true);

  // On component mount, get lessons that are on the same day as clicked unclaimed lesson
  useEffect(() => {
    // Create async function to grab conflicting lessons and set in state
    const showLessonsOnClaimedDay = async () => {
      // Set loading flag to true
      setLoading(true);

      // Get lessons on same day as lesson we're attempting to claim
      // Return is object with one entry (an array of lessons on this day)
      const lessonsOnSameDayObject = await asyncGetLessonsByDate(lesson.date);

      // Pull the array of lessons out of the return object
      const lessonsOnSameDay = lessonsOnSameDayObject
        ? lessonsOnSameDayObject[Object.keys(lessonsOnSameDayObject)[0]]
        : [];

      // Add conflicting times to state
      setConflictingTimes(lessonsOnSameDay ? lessonsOnSameDay.map((l) => l.time) : []);

      // Set loading flag to false
      setLoading(false);
    };

    // Call the async function we just created
    showLessonsOnClaimedDay();
  }, [lesson]);

  // Depending on success message flag, generate array of content to display in modal
  const modalTextArr = showSuccessMessage
    // If we have a success message, display the content that says lesson is now claimed
    ? [
      `You are now teaching the lesson for ${lesson.date} at ${lesson.time}`,
      "If you go to upcoming lessons/future lessons, it will now show up there",
      "Thanks for being so flexible!",
    ]
    // If trying to claim the lesson, display instructions and potential conflicts
    : [
      `Estás reclamando una lección para ${lesson.date} a ${lesson.time} `,
      " Antes de reclamar la lección, añádela a tu calendario de Google para que no la olvides y asegúrate de que no tengas conflictos con ese horario.",
      " Si estás seguro de que puedes impartir la lección, haz clic en el botón de abajo para reclamar la lección!",
    ].concat(conflictingTimes);

  // Show different button text depending on if claiming modal or not
  const modalButtonText = showSuccessMessage ? "Back to Home" : "Claim Lesson";

  // Submit button func should change depending on if we're claiming modal or not
  const submitFunc = showSuccessMessage ? closeModalFunc : claimLessonFunc;

  // Grab history to use to force data re-fetch on successful lesson claim
  const history = useHistory();

  return showModal ? (
    <Modal
      showModal={showModal}
      modalTextArr={loading ? ["Loading..."] : modalTextArr}
      modalButtonText={modalButtonText}
      onXOutFunction={() => {
        closeModalFunc();
        // If we just claimed a lesson, refresh the page to re-fetch
        if (showSuccessMessage) {
          history.go(0);
        }
      }}
      onSubmitFunction={() => {
        submitFunc();
        // If we just claimed a lesson, refresh the page to re-fetch
        if (showSuccessMessage) {
          history.go(0);
        }
      }}
    />
  )
    : null;
};

export default ClaimLessonModal;
