import React, { useState, useEffect } from "react";

// Component to display a group of lessons
import LessonGroup from "../LessonGroup";

// Get current NYC time from context
import { getNYCtime } from "../../../services/time";

// Function to sort today's lessons into past, now, and future
import sortTodayLessons from "./sortTodayLessons";

// Get date in NYC and format it
import { getNYCdate } from "../../../services/time";

// Render lessons on today's date
const TodayLessons = ({
  lessons, userIsAdmin, allTeachers, todayDate, updateLessonTeacher,
}) => {
  // State to hold sorted lessons
  const [todayPreviousLessons, settodayPreviousLessons] = useState(false);
  const [todayCurrentLessons, setTodayCurrentLessons] = useState(false);
  const [todayFutureLessons, setTodayFutureLessons] = useState(false);

  // State to control whether or not lesson completion (PDN) flag is visible in Right Now lessons
  const [currentLessonCompletionFlag, setCurrentLessonCompletionFlag] = useState(false);

  // On component load and when lessons prop changes, set initial lessons state
  useEffect(() => {
    // Use helper to sort today's lessons
    updateTodayLessonsState();
  }, [lessons]);

  // Get current NYC time to use when sorting today's lessons
  const NYCtime = getNYCtime();

  // Fires every time the clock ticks (every second)
  useEffect(() => {
    // Only check if lessons should be re-sorted every 10 seconds
    if (NYCtime.slice(-1) === "0") {
      // Every 10 seconds, update today's lessons
      updateTodayLessonsState();
    }
  }, [NYCtime]);

  // Only sort today's lessons - used in NYCtime useEffect
  const updateTodayLessonsState = () => {
    // Sort today lessons into past, current, and future
    const todayLessonsSorted = sortTodayLessons(lessons, NYCtime);

    // In current implementation, only past lessons are today's lessons that already occurred
    // If there are no past lessons, set to false
    settodayPreviousLessons(todayLessonsSorted.past.length > 0 ? todayLessonsSorted.past : false);

    // Set current lessons (starting in 10 mins or ended 10 mins ago)
    // If there are no current lessons, set to false
    setTodayCurrentLessons(todayLessonsSorted.current.length > 0 ? todayLessonsSorted.current : false);

    // Set today future lessons
    // If there are no future lessons today, set to false
    setTodayFutureLessons(todayLessonsSorted.future.length > 0 ? todayLessonsSorted.future : false);

    // Use current lessons start time to set whether PDN flag should be visible
    if (
      todayLessonsSorted.current && todayLessonsSorted.current.length > 0
      // Remove seconds from times, and compare them
      && (todayLessonsSorted.current[0].time.slice(0, 5) < NYCtime.slice(0, 5))
    ) {
      // If lessons started 1 min ago or more, display PDN flag
      setCurrentLessonCompletionFlag(true);
    }
    else {
    // Otherwise, don't dislay PDN flag
      setCurrentLessonCompletionFlag(false);
    }
  };

  // Get today's date in NYC, formatted as "[weekday], [month] [date]"
  const todayDateFormatted = getNYCdate(todayDate, "weekday");

  return (
    <>
      <div className="todayLessons__past">
        {todayPreviousLessons
          ? (
            <LessonGroup
              lessons={todayPreviousLessons}
              header={(
                <>
                  <h2>Today's Previous Lessons</h2>
                  <div>{todayDateFormatted}</div>
                  <div className="nyc-reminder">All times in NYC</div>
                </>
              )}
              userIsAdmin={userIsAdmin}
              allTeachers={allTeachers}
              updateLessonTeacher={updateLessonTeacher}
              showCompletionStatus
            />
          )
          : <p>No hubo lecciones previas para hoy</p>}
      </div>
      <div className="todayLessons__current">
        {todayCurrentLessons
          ? (
            <LessonGroup
              lessons={todayCurrentLessons}
              header={(
                <>
                  <h2>Right Now Lessons</h2>
                  <div>{todayDateFormatted}</div>
                  <div className="nyc-reminder">All times in NYC</div>
                </>
              )}
              userIsAdmin={userIsAdmin}
              allTeachers={allTeachers}
              updateLessonTeacher={updateLessonTeacher}
              showCompletionStatus={currentLessonCompletionFlag}
            />
          )
          : <p>No hay lecciones sucediendo en este momento</p>}
      </div>
      <div className="todayLessons__future">
        {todayFutureLessons
          ? (
            <LessonGroup
              lessons={todayFutureLessons}
              header={(
                <>
                  <h2>Today's Upcoming Lessons</h2>
                  <div>{todayDateFormatted}</div>
                  <div className="nyc-reminder">All times in NYC</div>
                </>
              )}
              userIsAdmin={userIsAdmin}
              allTeachers={allTeachers}
              updateLessonTeacher={updateLessonTeacher}
            />
          )
          : <p>No quedan lecciones futuras para hoy</p>}
      </div>
    </>
  );
};

export default TodayLessons;
