import React from "react";
import { useHistory } from "react-router-dom";

import { unClaimLesson } from "../../../services/datastore";

import Modal from "../../../components/modal";

/**
 * Renders a modal used to give up the class (convert to unclaimed in Firestore)
 *
 * @param { CallableFunction } exitFunc - used to de-render this modal
 * @param { Object } lesson - that is being given up
 * @param { boolean } showGiveUpClassModal - true if this modal should be shown
 */
const GiveUpClassModal = ({
  exitFunc,
  lesson,
  showGiveUpClassModal,
}) => {

  const history = useHistory();

  const giveUpModalTextArr = [
    "Antes de soltar esta clase: ",
    "1. Asegúrese de que alguien más puede cubrirla. Envíe un mensaje al canal #drill-instructor-chat en Slack para solicitar a los demás instructores que le ayuden a cubrirla.",
    "2. Una vez que ya confirmó que tiene quien cubra su clase, presione el botón Confirm para soltarla.",
    "3. Asegúrese de orientar a este instructor para que pueda tomar la clase desde su cuenta de Teach App sin problema.",
    "En caso de no querer hacer esto, presione la X de la esquina de la izquierda para regresar a la página anterior.",
  ];

  return (
    <Modal
      showModal={ showGiveUpClassModal }
      modalTextArr={ giveUpModalTextArr }
      modalButtonText="Confirm"
      onXOutFunction={ exitFunc }
      onSubmitFunction={() => {
        unClaimLesson(
          lesson, // lesson to unclaim
          null, // function signature asks for (but never uses) a Date object
          history, // used to redirect to home page if un-claim succeeds
        );
      }}
    />
  );
};

export default GiveUpClassModal;
