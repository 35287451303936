import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Find icons to import at https://fontawesome.com/v5.15/icons
import {
  faComment,
  faCommentSlash,
  faVolumeUp,
  faVolumeMute,
} from "@fortawesome/free-solid-svg-icons";

import { useEventListener } from "../../../../../hooks/useEventListener";

import { recordTurn } from "../../../../../services/datastore";

import { useHolodeck } from "../../../Holodeck";

/**
 * Render one speaker who is currently in the class, and buttons to do things
 * to that speaker based on drill state
 * @param { Object } speaker - Daily Participant Object for this speaker
 * @param { Boolean } muted - False if speaker can be heard, otherwise false
 * @param { Integer } callCount - Total callcount for this speaker in this
 * session (since teacher loaded page)
 * @param { Boolean } isCurrent - True if this speaker is the current Speaker
 * @param { String } lessonId - of the current lesson
 * @param { String } shortcutKey - Key to use as shortcut for nominating this
 * student
 * @returns 
 */
const OnePresentSpeaker = ({
  speaker,
  muted,
  callCount,
  incrementCallCount,
  isCurrent,
  lessonId,
  shortcutKey,
}) => {

  const {
    allowShortcuts,
    broadcastingStudentIds,
    conversationMode,
    disableBroadcastForStudent,
    enableBroadcastForStudent,
    getParticipantName,
    nominateStudent,
  } = useHolodeck();

  // Check if this student is on broadcast-enabled list
  const studentCanBroadcast = broadcastingStudentIds.includes(speaker.user_id);

  /**
   * Adds or removes a participant's studentId from our list of participants who are
   * allowed to broadcast
   * 
   * @param { String } studentId of participant to either add or remove from list
   */
  const updateBroadcastingStudentIds = ( studentId ) => {
    // If studentId is in the list, remove it!
    if (broadcastingStudentIds.includes(studentId)) {
      disableBroadcastForStudent(studentId);
    }
    // If studentId isn't in the list, add it!
    else {
      enableBroadcastForStudent(studentId);
    }
  };

  // Helper function to nominate this student and increment their call count
  const callOnThisSpeaker = () => {
    // Increment call count for this Speaker
    incrementCallCount();
    
    // Nominate the Speaker as the current student
    nominateStudent(speaker);

    // Fire and forget async function to record that the student was selected in Firestore
    recordTurn({
      studentId: speaker.user_id,
      lessonId,
      lessonTreatmentType: "SPEAKER",
    });
  };


  // Set up keyboard shortcut to nominate this student
  useEventListener({
    listenerShouldFire: allowShortcuts,
    listener: (event) => {
      if ( event.key.toLowerCase() === shortcutKey ) {
        callOnThisSpeaker();
      }
    },
  });

  return (
    <div className="studentPresence__oneSpeaker" key={speaker.user_id}>
      <div className="studentPresence__muteIconContainer">
        <FontAwesomeIcon
          icon={muted ? faVolumeMute : faVolumeUp}
          className={`studentPresence__muteIcon${muted ? " negativeState" : ""}`}
        />
      </div>
      <div
        className={`oneSpeaker__name${isCurrent ? " isCurrent" : ""}`}
        onClick={ callOnThisSpeaker }
      >
        <span className={`oneSpeaker__shortcutHint ${conversationMode ? "inactive" : "active"}`}>
          { shortcutKey }
        </span>
        { getParticipantName(speaker) }
      </div>
      {// In convo mode, we want silence button. In drill mode, we want call count
        conversationMode
          ? (
            <div
              className={`studentPresence__button${studentCanBroadcast ? "" : " negativeState"}`}
              role="button"
              onClick={ () => updateBroadcastingStudentIds(speaker.user_id) }
            >
              <FontAwesomeIcon
                icon={studentCanBroadcast ? faComment : faCommentSlash}
                className={`studentPresence__broadcastIcon${studentCanBroadcast ? "" : " negativeState"}`}
              />
            </div>
          )
          : (
            <div className="oneSpeaker__callCount">
              { callCount }
            </div>
          )
      }
    </div>
  );
};

export default OnePresentSpeaker;
