import React from "react";
import {
  Link,
  useRouteMatch,
} from "react-router-dom";

import "./couldNotFetchLesson.scss";

/**
 * Renders a useful message if ClassDetail couldn't fetch a lesson object
 * matching the provided lessonId
 */
const CouldNotFetchLesson = () => {
  const match = useRouteMatch("/class/:lessonId");
  const { lessonId } = match?.params;
  return (
    <main className="classDetail__couldNotFetchLesson">
      <h1>¡Ups!</h1>
      <p>{`Could not fetch lesson with id "${ lessonId }"`}</p>
      <p>Try going back <Link to="/">home</Link> and accessing the lesson from there.</p>
      <p>If you continue to get this error and believe that the lesson should exist, please reach out to the engineering team in the #problemas-tecnológicos channel on Slack.</p>
    </main>
  );
};

export default CouldNotFetchLesson;
