import React, {useState, useEffect} from "react";
import { useLocation } from "react-router-dom";
import { getOneModule } from "../../../services/datastore";
import { editModule } from "../../../services/datastore";

export default function EditModule({ module }) {
  /*
   * HOOKS
   */

  // React-router hook to use location object
  const search = useLocation().search;

  /*
   * STATE
   */
  const [moduleData, setModuleData] = useState({
    conversationPrompt: {en: "", es: ""}, 
    lessonEventTypeCategoryRestriction: "", 
    hideFromTopicTree: false,
  });

  // Hanldes whether or not the error message is shown
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  // Error that displays when a user enters bad input into lesson Name
  const [lessonNameError, setLessonNameError] = useState(null);

  // Hanldes whether or not the success message is shown
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  // Controls whether or not the submit button is clickable
  const [disableSubmit, setDisableSubmit] = useState(false);
 
  /*
   * USE EFFECT
   */

  // On load, fetch module - or set flag, if no moduleId present
  useEffect(() => {
    // Try to get the moduleId from the query string
    const moduleIdFromQueryString = new URLSearchParams(search).get("moduleId");

    //If there is no moudleId in the query string, but the module Props exists, add the moduleId to the query string
    if (module.id && !moduleIdFromQueryString) {
      history.pushState(null, null, `/manage/modules?moduleId=${module.id}`);
    }
    
    //If we have a module from props, then no need to go fetch module
    if (module.id) {
      setDefaultState(module);
      setModuleData({
        ...module, 
        conversationPrompt: {
          en: module.conversationPrompt?.en || "", 
          es: module.conversationPrompt?.es || "",
        }, 
        lessonEventTypeCategoryRestriction: module.lessonEventTypeCategoryRestriction || "", 
        hideFromTopicTree: module.hideFromTopicTree || false,
      });
    }

    // If we get the module from the query string
    else if (moduleIdFromQueryString) {
      // Get the current module Data from the backend
      asyncFetchModule(moduleId);
    }

  }, []);

  // Handles fetching the module from the backend and populating state
  const asyncFetchModule = async (moduleId) => {
    const moduleData = await getOneModule(moduleId);
    setDefaultState(moduleData);
    setModuleData({
      ...moduleData, 
      conversationPrompt: {
        en: moduleData.conversationPrompt?.en || "", 
        es: moduleData.conversationPrompt?.es || "",
      }, 
      lessonEventTypeCategoryRestriction: moduleData.lessonEventTypeCategoryRestriction || "", 
      hideFromTopicTree: moduleData.hideFromTopicTree || false,
    });
  };

  // Sets all of the default state
  const setDefaultState = (moduleData) => {
    handleModuleDataBooleans(moduleData, "isWelcome");
    handleModuleDataBooleans(moduleData, "isPreferred");
    handleModuleDataBooleans(moduleData, "isComingSoon");
    handleModuleDataBooleans(moduleData, "useSlidesDotCom");
    handleModuleDataBooleans(moduleData, "hideFromTopicTree");

    // Add the old, unedited moduleId to moduleData
    // Technically, the old module Id should be the same as the new one b/c users are not permitted to change the module Id
    // But the backend looks for moduleData.uneditedModuleId, so we'll keep it
    moduleData["uneditedModuleId"] = moduleData.id;
  };

  // Handles submitting the form to add the module
  const handleSubmit =  async (event) => {
    setDisableSubmit(true);
    event.preventDefault();
    const result = await editModule(moduleData);
    result ? setShowSuccessMessage(true) : setShowErrorMessage(true);
  };

  const resetStatus = () => {
    setShowErrorMessage(false);
    setLessonNameError("");
    setShowSuccessMessage(false);
    setDisableSubmit(false);
  };

  const handleChange = (event) => {
    const moduleDataKey = event.target.name;
    const updatedModuleDataValue = event.target.value;
    // Ensure that the module name that the user inputted is not just a bunch of spaces with no text
    if (moduleDataKey === "lesson_name" && (updatedModuleDataValue.length !== 0 && updatedModuleDataValue.trim().length === 0)) {
      setDisableSubmit(true);
      setLessonNameError("The module name must not be blank or contain only spaces");

    }
    else {
      setModuleData({ ...moduleData, [moduleDataKey]: updatedModuleDataValue });
      resetStatus();
    }
  };


  const handleModuleDataBooleans = (moduleData, key) => {
    moduleData[key] = moduleData[key] === undefined ?  "false" : moduleData[key].toString();
  };

  const setBoolSelectDefault = (key) => {
    if (moduleData){
      return moduleData[key] === undefined ? "false" : moduleData[key].toString();
    }
  }; 

  return ( moduleData
    ? (
      <div className="moduleDetails">
        {showSuccessMessage ? <p className="add-module-success-message">The module has been successfully edited.</p> : null}
        {showErrorMessage ? <p className="add-module-error-message">The module you are attempting to edit does not exist.</p> : null}
        <form 
          className="moduleInformation"
          onSubmit={handleSubmit}>
          <div className="input-field">
            <label htmlFor="level" >
              <span className="label">Level</span> 
              <br></br>
              <h1 className="read-only-input">
                {moduleData.difficulty || ""} 
              </h1> 
            </label>
          </div>
          <div className="input-field">
            <label htmlFor="isPreferred"  >
              <span className="label">Preferred:</span> 
              <br></br>
              <select  
                name="isPreferred"
                onChange={(event) => handleChange(event)}>
                <option 
                  value="true"
                  selected={setBoolSelectDefault("isPreferred") === "true" ? "selected" : ""}>
                True
                </option>
                <option 
                  value="false"
                  selected={setBoolSelectDefault("isPreferred") === "false" ? "selected" : ""}>
                False
                </option>
              </select>
            </label>
          </div>
          <div className="input-field">
            <label htmlFor="isWelcome"  >
              <span className="label">Welcome:</span> 
              <br></br>
              <select 
                name="isWelcome" 
                onChange={(event) => handleChange(event)}>
                <option 
                  value="true"
                  selected={setBoolSelectDefault("isWelcome") === "true" ? "selected" : ""}>
                True
                </option>
                <option 
                  value="false"
                  selected={setBoolSelectDefault("isWelcome") === "false" ? "selected" : ""}>
                False
                </option>
              </select>
            </label>
          </div>
          <div className="input-field">
            <label htmlFor="isComingSoon"  >
              <span className="label">Coming Soon:</span> 

              <br></br>
              <select  
                name="isComingSoon" 
                onChange={(event) => handleChange(event)}>
                <option 
                  value="true"
                  selected={setBoolSelectDefault("isComingSoon") === "true" ? "selected" : ""}>
                True
                </option>
                <option 
                  value="false"
                  selected={setBoolSelectDefault("isComingSoon") === "false" ? "selected" : ""}>
                False
                </option>
              </select>
            </label>
          </div>
          <div className="input-field">
            <label htmlFor="language"  >
              <span className="label">Target Language:</span>   
              <h1 className="read-only-input">
                {moduleData.language || ""} 
              </h1> 
            </label>
          </div>
          <div className="input-field">
            <label htmlFor="category"  >
              <span className="label">Category:</span>   
              <h1 className="read-only-input">
                {moduleData.category || ""} 
              </h1> 
            </label>
          </div>
          <div className="input-field">
            <label htmlFor="unit_name"  >
              <span className="label">Unit Name:</span>       
              <h1 className="read-only-input">
                {moduleData.unit_name || ""} 
              </h1> 
            </label>
          </div>
          <div className="input-field">
            <label htmlFor="unit_number"  >
              <span className="label">Unit Number:</span>      
              <h1 className="read-only-input">
                {moduleData.unit_number || ""} 
              </h1> 
            </label>
          </div>
          <div className="input-field">
            <label htmlFor="lesson_name"  >
              <span className="label">Module Name:</span>
              <input
                name="lesson_name" 
                type="text" 
                className="input"
                value={moduleData.lesson_name || ""} 
                required
                onChange={(event) => handleChange(event)}/>
            </label>
          </div>
          {lessonNameError ? <p className="lesson-name-error-message">{lessonNameError}</p> : null}
          <div className="input-field">
            <label htmlFor="lesson_number"  >
              <span className="label">Module Number:</span>
              <h1 className="read-only-input">
                {moduleData.lesson_number || ""} 
              </h1> 
            </label>
          </div>
          <div className="input-field">
            <label htmlFor="unit_number"  >
              <span className="label">UniqueId:</span>
              <h1 className="read-only-input">
                {moduleData.id || ""}
              </h1>
            </label>
          </div>
          <div className="input-field">
            <label htmlFor="link"  >
              <span className="label">Google Slides URL (optional):</span>
              <input  
                name="link" 
                type="text" 
                className="input"
                value={moduleData.link || ""}
                onChange={(event) => handleChange(event)}/>
            </label>
          </div>
          <div className="input-field">
            <label htmlFor="quizlet_url"  >
              <span className="label">Quizlet URL(optional):</span> 
              <input 
                name="quizlet_url" 
                type="text" 
                className="input"
                value={moduleData.quizlet_url || ""}
                onChange={(event) => handleChange(event)}/>
            </label>
          </div>
          <div className="input-field">
            <label htmlFor="useSlidesDotCom"  >
              <span className="label">Use Slides.com</span> 
              <br></br>
              <select  
                name="useSlidesDotCom"
                onChange={(event) => handleChange(event)}>
                <option 
                  value="true"
                  selected={setBoolSelectDefault("useSlidesDotCom") === "true" ? "selected" : ""}>
                True
                </option>
                <option 
                  value="false"
                  selected={setBoolSelectDefault("useSlidesDotCom") === "false" ? "selected" : ""}>
                False
                </option>
              </select>
            </label>
          </div>
          <div className="input-field">
            <label htmlFor="slidesDotComURL"  >
              <span className="label">Slides.com URL (Optional)</span> 
              <input  
                name="slidesDotComURL" 
                type="text"  
                className="input"
                value={moduleData.slidesDotComURL || ""}
                onChange={(event) => handleChange(event)}/>
            </label>
          </div>
          <div className="input-field">
            <label htmlFor="questionsAndConversationSectionSlideIndex"  >
              <span className="label">Conversación Slide # (optional):</span>  
              <input  
                name="questionsAndConversationSectionSlideIndex" 
                type="text" 
                className="input"
                value={moduleData.questionsAndConversationSectionSlideIndex || ""}
                onChange={(event) => handleChange(event)}/>
            </label>
          </div>
          <div className="input-field">
            <label htmlFor="publish_code">
              <span className="label">Publish Code (optional):</span>
              <input 
                name="publish_code" 
                type="text" 
                className="input"
                value={moduleData.publish_code || ""}
                onChange={(event) => handleChange(event)}/>
            </label>
          </div>
          <div className="input-field">
            <label htmlFor="topicCardImage">
              <span>Topic Card Image (optional):</span>
              <input 
                name="topicCardImage" 
                type="text" 
                className="input" 
                value={moduleData.topicCardImage || ""}
                onChange={(event) => handleChange(event)}/>
            </label>
          </div>
          <div className="input-field">
            <label htmlFor="youtubeURL">
              <span>Youtube URL (optional): </span>
              <input 
                name="youtubeURL" 
                type="text" 
                className="input" 
                value={moduleData.youtubeURL || ""}
                onChange={(event) => handleChange(event)}/>
            </label>
          </div>
          <div className="input-field">
            <label htmlFor="spotifyURL">
              <span>Spotify URL (optional):</span>
              <input 
                name="spotifyURL" 
                type="text" 
                className="input" 
                value={moduleData.spotifyURL || ""}
                onChange={(event) => handleChange(event)}/>
            </label>
          </div>
          <div className="input-field">
            <label htmlFor="transcriptURL">
              <span>Transcript URL (optional):</span>
              <input 
                name="transcriptURL" 
                type="text" 
                className="input" 
                value={moduleData.transcriptURL || ""}
                onChange={(event) => handleChange(event)}/>
            </label>
          </div>
          <div className="input-field">
            <label htmlFor="hideFromTopicTree">
              <span>Hide from Topic Tree:</span>
              <br></br>
              <select 
                value={moduleData.hideFromTopicTree} 
                name="hideFromTopicTree"
                onChange={(event) => {
                  setModuleData({...moduleData, hideFromTopicTree: event.target.value});
                  resetStatus();
                }}
              >
                <option value={false}>False</option>
                <option value={true}>True</option>
              </select>
            </label>
          </div>
          <div className="input-field">
            <label htmlFor="lessonEventTypeCategoryRestriction">
              <span>Restrict this module to special lesson type:</span>
              <br></br>
              <select 
                name="lessonEventTypeCategoryRestriction"
                value={moduleData.lessonEventTypeCategoryRestriction} 
                onChange={(event) => {
                  setModuleData({...moduleData, lessonEventTypeCategoryRestriction: event.target.value});
                  resetStatus();
                }}
              >
                <option value={""}>No restriction</option>
                <option value={"Podcast"}>Podcast</option>
                <option value={"Video"}>Video (Netflix)</option>
                <option value={"1on1"}>1on1 (New VIP)</option>
                <option value={"Welcome"}>Welcome (New Student Drill)</option>
              </select>
            </label>
          </div>
          <div className="input-field">
            <label htmlFor="cprompt-en">
              <span>Conversation Prompt (English) (optional):</span>
              <input 
                name="cprompt-en" 
                type="text" 
                className="input" 
                value={moduleData.conversationPrompt.en}
                onChange={(event) => { 
                  const prompt = {
                    en: event.target.value,
                    es: moduleData.conversationPrompt.es,
                  };
                  setModuleData({...moduleData, conversationPrompt: prompt});
                  resetStatus();
                }}/>
            </label>
          </div>
          <div className="input-field">
            <label htmlFor="cprompt-es">
              <span>Conversation Prompt (Spanish) (optional):</span>
              <input 
                name="cprompt-es" 
                type="text" 
                className="input" 
                value={moduleData.conversationPrompt.es}
                onChange={(event) => { 
                  const prompt = {
                    en: moduleData.conversationPrompt.en,
                    es: event.target.value,
                  };
                  setModuleData({...moduleData, conversationPrompt: prompt});
                  resetStatus();
                }}/>
            </label>
          </div>
          <br></br>
          <div>
            <input 
              className={disableSubmit ? "add-module-submit-button-disabled" : "add-module-submit-button"}
              type="submit" 
              value="Edit Module"
              disabled={disableSubmit}>
            </input>
          </div>
        </form>
        {showSuccessMessage ? <p className="add-module-success-message">The module has been successfully edited.</p> : null}
        {showErrorMessage ? <p className="add-module-error-message">The module you are attempting to edit does not exist.</p> : null}
      </div> 
    )
    : <div> Loading... </div>
  );
}
