import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

import activebuildconfig from "../../../../configs/activebuildconfig.json";
const { SPEAK_ROOT_URL } = activebuildconfig;

const formatTypeRestriction = (lessonEventTypeCategoryRestriction) => {
  if (!lessonEventTypeCategoryRestriction) {
    return "No restrictions";
  }
  if (lessonEventTypeCategoryRestriction === "Podcast") {
    return "Podcast";
  }
  else if (lessonEventTypeCategoryRestriction === "Video") {
    return "Video (Netflix)";
  }
  else if (lessonEventTypeCategoryRestriction === "1on1") {
    return "1on1 (New VIP)";
  }
  else if (lessonEventTypeCategoryRestriction === "Welcome") {
    return "Welcome (New Student Drill)";
  }
  else {
    return lessonEventTypeCategoryRestriction;
  }
};

const ViewModule = ({ module }) => {

  /*
   * HOOKS
   */

  // React-router hook to use location object
  const search = useLocation().search;

  useEffect(() => {

    //If there is no moudleId in the query string, but the module Props exists, add the moduleId to the query string
    const moduleId = new URLSearchParams(search).get("moduleId");
    if (module.id && !moduleId) {
      history.pushState(null, null, `/manage/modules?moduleId=${module.id}`);
    }

  });
  return module.id === undefined ?
    <div> No Module Selected</div>
    : ( <>
      <h1 className="moduleDataColumn1">Unique Id:</h1>
      <p className="moduleDataColumn2">{module.id || ""}</p>
      <span className="cell" ></span>
      <h1 className="moduleDataColumn1">Level:</h1>
      <p className="moduleDataColumn2">{module.difficulty || ""}</p>
      <span className="cell" ></span>
      <h1 className="moduleDataColumn1">Preferred:</h1>
      <p className="moduleDataColumn2">{(module.isPreferred || false).toString()}</p>
      <span className="cell" ></span>
      <h1 className="moduleDataColumn1"> Welcome:</h1>
      <p className="moduleDataColumn2">{(module.isWelcome || false).toString()}</p>
      <span className="cell" ></span>
      <h1 className="moduleDataColumn1">Coming Soon:</h1>
      <p className="moduleDataColumn2">{(module.isComingSoon || false).toString()}</p>
      <span className="cell" ></span>
      <h1 className="moduleDataColumn1">Target Language:</h1>
      <p className="moduleDataColumn2">{module.language || ""}</p>
      <span className="cell" ></span>
      <h1 className="moduleDataColumn1">Category:</h1>
      <p className="moduleDataColumn2">{module.category || ""}</p>
      <span className="cell" ></span>
      <h1 className="moduleDataColumn1">Unit Name:</h1>
      <p className="moduleDataColumn2">{module.unit_name || ""}</p>
      <span className="cell" ></span>
      <h1 className="moduleDataColumn1">Unit Number:</h1>
      <p className="moduleDataColumn2">{module.unit_number || ""} </p>
      <span className="cell" ></span>
      <h1 className="moduleDataColumn1">Module Name:</h1>
      <p className="moduleDataColumn2">{module.lesson_name || ""}</p>
      <span className="cell" ></span>
      <h1 className="moduleDataColumn1">Module Number:</h1>
      <p className="moduleDataColumn2">{module.lesson_number || ""}</p>
      <span className="cell" ></span>
      <h1 className="moduleDataColumn1 centerLabel">Google Slides URL:</h1>
      <p className="moduleDataColumn2">{module.link || ""}</p>
      <span className="cell" ></span>
      <h1 className="moduleDataColumn1">Slides.com:</h1>
      <p className="moduleDataColumn2">{(module.useSlidesDotCom || false).toString()}</p>
      <span className="cell" ></span>
      <h1 className="moduleDataColumn1 centerLabel">Slides.com URL:</h1>
      <p className="moduleDataColumn2">{module.slidesDotComURL || ""}</p>
      <span className="cell" ></span>
      <h1 className="moduleDataColumn1">Conversación Slide #:</h1>
      <p className="moduleDataColumn2">{module.questionsAndConversationSectionSlideIndex || ""}</p>
      <span className="cell" ></span>
      <h1 className="moduleDataColumn1 centerLabel">Publish Code:</h1>
      <p className="moduleDataColumn2">{module.publish_code || ""}</p>
      <span className="cell" ></span>
      <h1 className="moduleDataColumn1">Topic Card Image</h1>
      <p className="moduleDataColumn2">{module.topicCardImage || ""}</p>
      <span className="cell" ></span>
      <h1 className="moduleDataColumn1">Youtube URL</h1>
      <p className="moduleDataColumn2">{module.youtubeURL || ""}</p>
      <span className="cell" ></span>
      <h1 className="moduleDataColumn1">Spotify URL</h1>
      <p className="moduleDataColumn2">{module.spotifyURL || ""}</p>
      <span className="cell" ></span>
      <h1 className="moduleDataColumn1">Transcript URL</h1>
      <p className="moduleDataColumn2">{module.transcriptURL || ""}</p>
      <span className="cell" ></span>
      <h1 className="moduleDataColumn1">Hide from Topic Tree</h1>
      <p className="moduleDataColumn2">{(module.hideFromTopicTree || false).toString()}</p>
      <span className="cell" ></span>
      <h1 className="moduleDataColumn1">Lesson Type Restriction</h1>
      <p className="moduleDataColumn2">{formatTypeRestriction(module.lessonEventTypeCategoryRestriction)}</p>
      <span className="cell" ></span>
      <h1 className="moduleDataColumn1">Conversation Prompt (English)</h1>
      <p className="moduleDataColumn2">{module.conversationPrompt?.en || ""}</p>
      <span className="cell" ></span>
      <h1 className="moduleDataColumn1">Conversation Prompt (Spanish)</h1>
      <p className="moduleDataColumn2">{module.conversationPrompt?.es || ""}</p>
      <span className="cell" ></span>
      <p className="moduleDataColumn1 centerLabel">Talkkaround Word Bundle:</p>
      <span className="linkToModule">
        {module?.wordBundleId ?
          <Link to={`/manage/wordBundles?id=${module.wordBundleId}`} style={{ textDecoration: "none" }}>
            <p>
            Word Bundle (Manage)
            </p>
          </Link> 
          : <p>No Word Bundles</p> }
      </span>
      <span className="cell" ></span>
      <p className="moduleDataColumn1 centerLabel">Vocabulary:</p>
      <span className="linkToModule">
        {module ?
          <Link to={`/manage/modules/vocabulary?moduleId=${module.id}`} style={{ textDecoration: "none" }}>
            <p>
            Vocabulary Page (Manage)
            </p>
          </Link> 
          : null }
      </span>
      <span className="cell" ></span>
      <p className="moduleDataColumn1 centerLabel">Study Page:</p>
      <span className="linkToModule">
        {module ?
          <p 
            className="modulePageLink" 
            onClick={() => window.location.href = `${SPEAK_ROOT_URL}/catalog/study/${module.id}`}
          >
          Study Page (Student)
          </p>
          : null }
      </span>
      <span className="cell" ></span>
      <p className="moduleDataColumn1 centerLabel">Drill Content:</p>
      <span className="linkToModule">
        {module ?
          <p 
            className="modulePageLink" 
            onClick={() => window.location.href = `${SPEAK_ROOT_URL}/catalog/study/viewDrillContent/${module.id}`}
          >
          Drill Content (Student)
          </p>
          : null }
      </span>
      <span className="cell" ></span>
      <p className="moduleDataColumn1 centerLabel"> Vocab Review:</p>
      <span className="linkToModule">
        {module ?
          <p 
            className="modulePageLink" 
            onClick={() => window.location.href = `${SPEAK_ROOT_URL}/catalog/study/vocabReview/${module.id}`}
          >
          Vocabulary Review (Student)
          </p>
          : null }
      </span>
    </>);
};

export default ViewModule;
