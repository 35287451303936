/**
 * Sort today's lessons into past (started more than lessonDurationMinutes min ago),
 * future (start in more than 5 mins), and now (in between), all based on
 * given time
 *
 * @param { array } lessons - Array of lesson objects
 * @param { String } time - Time string generated by time context getNYCTime function - represents right now in NYC time
 * @returns { Object } with past, future, and now arrays containing appropriately-sorted lessons
 */
const sortTodayLessons = (lessons, time) => {
  // Edge case -- we're after midnight NYC time and before 5 min before the first class starts
  if (time < addTime(lessons[0].time, -5)) {
    // Manually create a return object with all lessons in the future
    return {
      past: false,
      future: lessons,
      current: false,
    };
  }

  // Start time cutoff for classes starting in 5 mins or less
  const futureCutoff = addTime(time, 5);
  // default start time cutoff to 25 for classes that have ended
  let pastCutoff = addTime(time, -25);

  // Split lessons by relation of start time to current time
  const sortedLessons = lessons.reduce((acc, curr) => {
    // Get this lesson's start time
    const startTime = curr.time;

    // override pastCutoff with firestore understanding of how long class should be
    if (curr.lessonDurationMinutes) {
      pastCutoff = addTime(time, curr.lessonDurationMinutes * -1);
    }

    // If lesson started more than lessonDurationMinutes minutes ago, it's in the past
    if (startTime <= pastCutoff) {
      // Add lesson to past array and return accumulator object
      return { ...acc, past: acc.past.concat([curr]) };
    }
    // If lesson starts in more than 5 minutes, it's in the future
    else if (startTime > futureCutoff) {
      // Add lesson to future array and return accumulator object
      return { ...acc, future: acc.future.concat([curr]) };
    }
    // If lesson is in neither past nor future, must be now!
    else {
      // Add lesson to current array and return accumulator object
      return { ...acc, current: acc.current.concat([curr]) };
    }
  },
  // accumulator object with past, future, and now arrays
  {
    past: [],
    future: [],
    current: [],
  });

  // If any of the resulting arrays are empty, convert to false (because empty array is truthy)
  Object.keys(sortedLessons).forEach((key) => {
    if (sortedLessons[key].length === 0) {
      sortedLessons[key] = false;
    }
  });

  // return sorted lessons
  return sortedLessons;
};

export default sortTodayLessons;

/**
 * Adds given amount of minutes to given time string
 *
 * @param { String } origTime ("HH:MM:SS")- The time to add to
 * @param { Number } minsToAdd - how many minutes to add to origTime
 * @returns { String } - String with minutes added!
 */
const addTime = (origTime, minsToAdd) => {
  // Split origTime string into hours and mins
  let h = Number(origTime.slice(0, 2));
  let m = Number(origTime.slice(3, 5));

  // Just remember the seconds string, to pass back unchanged
  const s = origTime.slice(6, 8);

  // Add given value to minutes
  m += minsToAdd;

  // If adding minutes causes hours to roll over, add an hour!
  // It's possible that we'll need to do this multiple times
  while (m >= 60) {
    // increment hours
    h += 1;
    // decrement minutes
    m -= 60;
  }

  // If subtracting minutes causes them to go negative, adjust!
  while (m < 0) {
    // Add 60 min to get right minutes
    m += 60;
    // Decrement hour to adjust
    h -= 1;
  }

  // Don't adjust hours over 24 or under 0 -- avoids bugs with classes around midnight

  // Return string, adding leading zeroes if needed
  return `${h < 10 ? `0${h}` : h}:${m < 10 ? `0${m}` : m}:${s}`;
};
