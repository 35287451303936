import React, {
  useEffect,
  useState,
} from "react";
import { useLocation } from "react-router-dom";

import { getWordBundleSearchMap } from "../../../services/datastore";

import LoadingOverlay from "../../../components/LoadingOverlay";

import WordBundleSearch from "./WordBundleSearch";
import EditWordBundle from "./EditWordBundle";

import "./manageWordBundles.css";

/**
 * Parent component for wordBundle management screen. Renders WordBundleSearch
 * and EditWordBundle
 */
const ManageWordBundles = () => {

  /*
   * HOOKS
   */

  const { search } = useLocation();

  /*
   * STATE
   */

  const [ loading, setLoading ] = useState( true );

  // Store full search map from back end. Map is inverted -- keys are searchable strings, values are objects with bundle name and id
  const [ searchMap, setSearchMap ] = useState( null );

  // Bundle to edit - selected in WordBundleSearch, modified in EditWordBundle
  const [ selectedBundleId, setSelectedBundleId ] = useState( null );

  /*
   * USE EFFECT
   */

  // On load, grab search map for wordBundles, and check for query string
  useEffect(() => {
    asyncGetAndSetSearchMap();

    // If we have a non-empty string for the search key on location, there's a query string! Set it as selectedBundleId to prompt fetching
    if ( search ) {
      setSelectedBundleId( (new URLSearchParams(search)).get("id") );
    }
  }, []);

  /*
   * HELPERS
   */

  const asyncGetAndSetSearchMap = async () => {
    const map = await getWordBundleSearchMap();
    setSearchMap( map );
    setLoading( false );
  };

  /*
   * RENDER
   */

  return (
    <main className="manageWordBundles__container">
      <WordBundleSearch
        searchMap={ searchMap }
        selectedBundleId={ selectedBundleId }
      />
      <EditWordBundle bundleId={ selectedBundleId } />
      <LoadingOverlay isVisible={ loading } />
    </main>
  );
};

export default ManageWordBundles;
