import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Find icons to import at https://fontawesome.com/v5.15/icons
import {
  faVolumeMute,
  faVolumeUp,
} from "@fortawesome/free-solid-svg-icons";

import { useEventListener } from "../../../../../hooks/useEventListener";

import { useHolodeck } from "../../../Holodeck";

/**
 * Renders "Audience Turn" entry in PresentSpeakers list
 * Styling classes and markup are same as OnePresentSpeaker. Tread lightly
 * modifying the markup!
 * 
 * @param { Boolean } showAudienceTurnSpeaker - Indicating whether or not to
 * display this component (computed in PresentSpeakers)
 * @param { String } shortcutKey - Key to use as shortcut for nominating the
 * Audience Turn
 * @returns 
 */
const AudienceTurnSpeaker = ({
  showAudienceTurnSpeaker,
  shortcutKey,
}) => {

  /*
   * CONTEXT
   */
  const {
    allowShortcuts,
    candidateParticipants,
    conversationMode,
    currentIsAudience,
    currentSpeakerParticipant,
    startAudienceTurn,
  } = useHolodeck();

  /*
   * STATE
   */

  // Count number of "calls" (clicks) that the Audience has received
  const [ callCount, setCallCount ] = useState(0);

  // Keep mute icon next to audience turn up-to-date
  const [ audienceTurnIsMuted, setAudienceTurnIsMuted ] = useState(true);

  /*
   * USE EFFECT
   */

  // Keep mute icon by Audience Turn up to date
  useEffect(() => {

    // If current speaker isn't an audience member, show audience member as muted
    if ( !currentIsAudience ) {
      setAudienceTurnIsMuted( true );
    }

    // If current speaker is an audience member, check whether or not they're muted!
    else {
      setAudienceTurnIsMuted(() => (
        // Beacuse of how we propagate updates, currentSpeakerParticipant isn't updated when the speaker mutes. So we have to go find that person in candidateParticipants to get the updated mute status
        !candidateParticipants
          ?.find(p => p.user_id === currentSpeakerParticipant?.user_id)
          ?.audio
      ));
    }

  }, [
    candidateParticipants,
    currentIsAudience,
  ]);

  /*
   * HELPERS
   */

  // Start an Audience Turn
  const handleAudienceTurn = () => {

    // If the audience turn speaker isn't being displayed, don't trigger the audience turn! This would only happen via keyboard shortcut
    if ( !showAudienceTurnSpeaker ) {
      return;
    }

    // Increment callCount for Audience Turn
    setCallCount((prev) => (prev + 1));
    
    // Use Holodeck helper to select and nominate a Candidate for this Audience Turn
    startAudienceTurn();
  };

  /*
   * EVENT LISTENERS
   */

  // When appropriate key is pressed, start the audience turn!
  useEventListener({
    listenerShouldFire: allowShortcuts,
    listener: (event) => {
      if ( event.key.toLowerCase() === shortcutKey ) {
        handleAudienceTurn();
      }
    },
  });

  /*
   * RENDER
   */

  // If PresentSpeaker tells us not to display this component, don't!
  if ( !showAudienceTurnSpeaker ) {
    return null;
  }

  return (
    <div className="studentPresence__oneSpeaker">
      <div className="studentPresence__muteIconContainer">
        {/* Default to muted icon, since Observers normally can't be heard */}
        <FontAwesomeIcon
          icon={ audienceTurnIsMuted ? faVolumeMute : faVolumeUp }
          className={`studentPresence__muteIcon${
            audienceTurnIsMuted ? " negativeState" : ""
          }`}
        />
      </div>
      <div
        className={`oneSpeaker__name ${currentIsAudience ? "isCurrent" : ""}`}
        onClick={ handleAudienceTurn }
      >
        <span className={`oneSpeaker__shortcutHint ${conversationMode ? "inactive" : "active"}`}>
          { shortcutKey }
        </span>
        AudienceTurn
      </div>
      {/* Only displayed in Drill mode, so only need markup for call count */}
      <div className="oneSpeaker__callCount">
        { callCount }
      </div>
    </div>
  );
};

export default AudienceTurnSpeaker;
