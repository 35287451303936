import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { sendPasswordResetEmail } from "firebase/auth";

import { firebaseAuth } from "../services/base";

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      sent: false,
      error: false,
      errorMessage: "",
    };
  }

  // update username when being typed in
  updateEmail = (event) => {
    this.setState({ email: event.target.value });
  };

  resetPassword = () => {
    const emailAddress = this.state.email;

    sendPasswordResetEmail(firebaseAuth, emailAddress).then(() => {
      // Email sent.
      console.log("success! email sent");
      this.setState({ sent: true });
    }).catch((error) => {
      // An error happened.
      console.log("error: ", error);
      this.setState({ error: true, errorMessage: error.message });
    });
  };

  render() {
    if (this.state.error) {
      return (
        <div>
          <div className="reset-section">
            <div>Type in your email and press reset to send a reset link to your password</div>
            <div>
              <div>Email</div>
              <input className="form" type="text" value={this.state.email} onChange={this.updateEmail} />
              <div className="incomplete-message">Error: {this.state.errorMessage}</div>
            </div>
            <button className="submit-button" type="submit" onClick={this.resetPassword}>Reset</button>
          </div>
        </div>
      );
    }
    else if (!this.state.sent) {
      return (
        <div>
          <div className="reset-section">
            <div>Type in your email and press reset to send a reset link to your password</div>
            <div>
              <div>Email</div>
              <input className="form" type="text" value={this.state.email} onChange={this.updateEmail} />
            </div>
            <button className="submit-button" type="submit" onClick={this.resetPassword}>Reset</button>
          </div>
        </div>
      );
    }
    else {
      return (
        <div>
          <div className="reset-section">
            Email is sent! It should be in your inbox within the next 10 minutes!
          </div>
        </div>
      );
    }
  }
}

export default withRouter(ResetPassword);
