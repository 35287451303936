import React, {
  useEffect,
  useState,
} from "react";

import { updateOneWordBundle } from "../../../../services/datastore";
import { talkka } from "../../../../services/logging";

import LoadingOverlay from "../../../../components/LoadingOverlay";

/**
 * Renders form to update bundle info, and handles updates.
 * If passed an invalid id from parent component (EditWordBundle), fails
 * silently: Displays same text as if no bundle were selected
 *
 * @param { Object } bundleInfo - of bundle selected from WordBundleSearch
 * @param { Function } setBundleInfo - to update bundle info upstream after
 * Firestore update is complete
 */
const WordBundleEditForm = ({
  bundleInfo,
  setBundleInfo,
}) => {

  /*
   * STATE
   */

  const [ loading, setLoading ] = useState( false );

  // Track changes to bundleInfo in separate object
  const [ updatedBundleInfo, setUpdatedBundleInfo ] = useState( null );

  // State to keep track of info for new word to add to this bundle
  const [ newWord, setNewWord ] = useState({
    en: "",
    es: "",
    level: "",
  });

  // Flipped to true is form is submitted with any values as blank strings
  const [ hasMissingData, setHasMissingData ] = useState( false );

  /*
   * USE EFFECT
   */

  // When bundle info changes, create deep copy to start updatedBundleInfo
  useEffect(() => {
    if ( bundleInfo ) {
      // Only remember data we're going to modify in the form
      const allBundleInfo = JSON.parse(JSON.stringify(bundleInfo));
      const {
        name,
        isActive,
        words,
      } = allBundleInfo;

      setUpdatedBundleInfo({
        name,
        isActive,
        words,
      });
    }
    else {
      setUpdatedBundleInfo( null );
    }
  }, [ bundleInfo ]);

  /*
   * HELPERS
   */

  // Helper to update 1 word in the words array of updatedBundleInfo
  const updateWord = ( index, update ) => {
    // Copy existing words array
    const copiedWords = JSON.parse(JSON.stringify( updatedBundleInfo.words ));

    // Replace value at given index in copied array
    copiedWords[index] = {
      ...updatedBundleInfo.words[index],
      ...update,
    };

    setUpdatedBundleInfo( prev => ({...prev, words: copiedWords}) );
  };

  // Helper to delete 1 word from the words array of updatedBundleInfo
  const deleteWord = ( index ) => {
    // Copy existing words array
    const copiedWords = JSON.parse(JSON.stringify( updatedBundleInfo.words ));

    copiedWords.splice(index, 1);

    setUpdatedBundleInfo( prev => ({...prev, words: copiedWords}) );
  };

  // Helper to add newWord in state as word on updatedBundleInfo words array
  const addWord = () => {
    // TODO: Validate input

    // Update bundle
    setUpdatedBundleInfo( prev => ({
      ...prev,
      words: prev.words.concat([ newWord ]),
    }));

    // Clear new word info, so next one can be entered
    setNewWord({
      en: "",
      es: "",
      level: "",
    });
  };

  // Helper to submit form
  const submitForm = async ( event ) => {
    // No page reload
    event.preventDefault();

    setLoading( true );

    // Ensure that data (a non-empty string) is present in the updated bundle name, and in each field (en, es, and level) for every word
    if (
      !updatedBundleInfo?.name
      || !updatedBundleInfo?.words?.every(w => w.en && w.es && w.level)
    ) {
      setHasMissingData( true );
    }
    else {
      setHasMissingData( false );

      const updatedBundle = await updateOneWordBundle({
        bundleId: bundleInfo.id,
        update: updatedBundleInfo,
      });

      // TODO: Handle this failure gracefully
      if ( !updatedBundle ) {
        talkka.error("Failed to update wordBundle. Check CF logs.");
      }
      else {
        // Setting bundle info in parent component state effectively resets to same state as if we'd reloaded the page after the update
        setBundleInfo( updatedBundle );
      }
    }

    setLoading( false );
  };

  /*
   * RENDER
   */

  if ( !updatedBundleInfo ) {
    return <div className="wordBundleEditForm__noBundle">Select a Word Bundle on the left to edit.</div>;
  }

  return (
    <form
      className="wordBundleEditForm__container"
      onSubmit={ submitForm }
    >
      <h2 className="wordBundleEditForm__heading">Editing { bundleInfo.name }</h2>
      {// If tried to submit form with a blank field, notify
        hasMissingData
        && (
          <p className="wordBundleEditForm__missingDataNotification">
            Please make sure all fields have a value before submitting form
          </p>
        )
      }
      <input
        type="submit"
        value="Save Changes"
        className="wordBundleEditForm__submitButton"
      />
      <label htmlFor="bundleId">
        Bundle ID:&nbsp;
        <input
          readOnly
          name="bundleId"
          type="text"
          value={ bundleInfo.id }
        />
      </label>
      <label htmlFor="bundleName">
        Name:&nbsp;
        <input
          className={ !updatedBundleInfo.name ? "wordBundleEditForm__missingData" : ""}
          type="text"
          name="bundleName"
          value={ updatedBundleInfo.name }
          onChange={ (e) => {
            setUpdatedBundleInfo(prev => ({...prev, name: e.target.value}) );
          }}
        />
      </label>
      <label htmlFor="bundleIsActive">
        Active:&nbsp;
        <select
          name="bundleIsActive"
          type="text"
          value={ updatedBundleInfo.isActive }
          onChange={ (e) => {
            setUpdatedBundleInfo(prev => ({...prev, isActive: e.target.value === "true"}));
          }}
        >
          <option value="true">true</option>
          <option value="false">false</option>
        </select>
      </label>
      <label htmlFor="wordsList">
        Words:
        <ul
          name="wordsList"
          className="wordBundleEditForm__wordsList"
        >
          {updatedBundleInfo.words.map( (word, index) => (
            <li key={index}>
              {index + 1}:&nbsp;
              <label htmlFor={`${index}en`}>
                en:&nbsp;
                <input
                  className={ !word.en ? "wordBundleEditForm__missingData" : ""}
                  name={`${index}en`}
                  type="text"
                  value={word.en}
                  onChange={(e) => updateWord(index, { en: e.target.value })}
                />
              </label>
              <label htmlFor={`${index}es`}>
                es:&nbsp;
                <input
                  className={ !word.es ? "wordBundleEditForm__missingData" : ""}
                  name={`${index}es`}
                  type="text"
                  value={word.es}
                  onChange={(e) => updateWord(index, { es: e.target.value })}
                />
              </label>
              <label htmlFor={`${index}level`}>
                level:&nbsp;
                <input
                  className={ !word.level ? "wordBundleEditForm__missingData" : ""}
                  name={`${index}level`}
                  type="text"
                  value={word.level}
                  onChange={(e) => updateWord(index, { level: e.target.value })}
                />
              </label>
              <button onClick={ () => deleteWord(index) }>
                Delete word
              </button>
            </li>
          ))
          }
          <li className="wordBundleEditForm__addNewWord">
            NEW WORD:&nbsp;
            <label htmlFor="newWord-en">
              en:&nbsp;
              <input
                name="newWord-en"
                type="text"
                value={ newWord.en }
                onChange={(e) => setNewWord( prev => (
                  { ...prev, en: e.target.value }
                ))}
              />
            </label>
            <label htmlFor="newWord-es">
            es:&nbsp;
              <input
                name="newWord-es"
                type="text"
                value={ newWord.es }
                onChange={(e) => setNewWord( prev => (
                  { ...prev, es: e.target.value }
                ))}
              />
            </label>
            <label htmlFor="newWord-level">
            level:&nbsp;
              <input
                name="newWord-level"
                type="text"
                value={ newWord.level }
                onChange={(e) => setNewWord( prev => (
                  { ...prev, level: e.target.value }
                ))}
              />
            </label>
            <button onClick={ () => addWord() }>
              Add new word
            </button>
          </li>
        </ul>
      </label>
      <input
        type="submit"
        value="Save Changes"
        className="wordBundleEditForm__submitButton"
      />
      <LoadingOverlay isVisible={ loading } />
    </form>
  );
};

export default WordBundleEditForm;
