import React, { useState, useEffect } from "react";
import {
  useLocation,
  useHistory,
  withRouter,
} from "react-router-dom";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";

// Fetch lessons by date, and unclaimed lessons
import {
  asyncGetLessonsByDate,
  asyncGetAllLessonsWithSpecialStatus,
  asyncGetAllTeachers,
} from "../../services/datastore";

// Context to get current NYC time
import { getNYCdate } from "../../services/time";

// Context to get logged-in user info
import { getCurrentUser } from "../../services/user";

// Local components
import ClassFeedbackWidget from "../../components/ClassFeedbackWidget";
import UnclaimedLessons from "../../components/Home-Components/UnclaimedLessons";
import IncompleteLessons from "../../components/Home-Components/IncompleteLessons";
import LessonsFilter from "../../components/Home-Components/LessonsFilter";
import MyLessons from "../../components/Home-Components/MyLessons";

// Local styles
import "./home.scss";

/**
 * Does data fetching and rendering for homepage
 * No props -- all fetching moved out of app.js!
 * 
 * @returns markup for home page
 */
const Home = () => {
  /*
   * CONTEXT
   */

  // Get MM/DD/YYYY string for today date in NYC
  let todayDateString = getNYCdate();
  let futureDateString;

  // Get info for logged-in teacher
  const userInfo = getCurrentUser();

  /*
   * HOOKS 
   */
  // history from react-router-dom
  const history = useHistory();

  // Use location to read from query strings
  const location = useLocation();

  // grab querystring from location
  const querystring = new URLSearchParams(location.search);

  /*
   * STATE
   */

  // State to hold all lessons returned from back end
  const [allLessons, setAllLessons] = useState(false);

  // State to hold filtered lessons to display
  const [displayLessons, setDisplayLessons] = useState(false);

  // State to hold unclaimed and incomplete lessons returned from back end
  const [unclaimedLessons, setUnclaimedLessons] = useState(false);
  const [incompleteLessons, setIncompleteLessons] = useState(false);

  // Flag for whether component is loading
  const [loading, setLoading] = useState(true);

  // Date object representing first day in selected range
  const [lessonsStartDateObject, setLessonsStartDateObject] = useState(false);

  // Date object representing last day in selected range
  const [lessonsEndDateObject, setLessonsEndDateObject] = useState(false);

  // Error message if user picks invalid date range for lessons
  const [datepickerWarning, setDatepickerWarning] = useState(false);

  // State to hold fetched info for all teachers
  const [allTeachers, setAllTeachers] = useState(null);

  // Set teachers for whom to display lessons
  // Only used by admin when filtering lessons by instructor
  // Default value of false shows all lessons
  const [selectedTeachers, setSelectedTeachers] = useState(false);

  /**
   * HELPER
   * 
   */

  // Function to set the date objects based on the current querystring and
  // optionally update the querystring
  function setDateStateAndQuery(shouldUpdateQuerystring) {
    // get start and end date params from querystring 
    const startDateFromQuery = querystring.get("startDate");
    const endDateFromQuery = querystring.get("endDate");

    const startDateObj = new Date(startDateFromQuery);
    const endDateObj = new Date(endDateFromQuery);

    // Get difference between selected dates in milliseconds
    const millisDiff = endDateObj.getTime() - startDateObj.getTime();
    // Calculate the number of days between the new start and end dates
    // There are 86,400,000 milliseconds in a day
    const numDaysSelected = Math.floor(millisDiff / 86400000) + 1;

    // If the querystring has valid start and end date params, update filters
    if (startDateFromQuery && endDateFromQuery && numDaysSelected <= 14) {
      setLessonsStartDateObject(startDateObj);
      setLessonsEndDateObject(endDateObj);

      // Update todayDateString and futureDateString for fetching homepage data
      todayDateString = startDateFromQuery;
      futureDateString = endDateFromQuery;
    }
    // Default to filtering by current date to two days into the future
    else {
      // New date object to remember future date
      const future = new Date();

      // Set the date to 2 days in the future (will roll over for months)
      future.setDate(future.getDate() + 2);

      // Get the formatted date string for 2 days from now in NYC
      futureDateString = getNYCdate(future);

      // Set start date for calendar display to right now date in NYC
      setLessonsStartDateObject(new Date(todayDateString));

      // Set end date for calendar display to 2 days from now in NYC
      setLessonsEndDateObject(new Date(futureDateString));

      if (numDaysSelected > 14 && shouldUpdateQuerystring) {
        // Update querystring and browser history to reflect dates chosen
        updateQuerystringAndHistory("startDate", todayDateString, "endDate", futureDateString);
      }
    }
  }

  // Do the fetching in an async function
  const fetchHomepageData = async () => {
    // Await the result of all API calls to get lessons and teachers
    const [
      fetchedLessons,
      fetchedUnclaimedLessons,
      fetchedIncompleteLessons,
      fetchedTeachers,
    ] = await Promise.all([
      // Grab all lessons coming up in the next 2 days
      asyncGetLessonsByDate(todayDateString, futureDateString),
      // Grab all unclaimed lessons (regardless of date)
      asyncGetAllLessonsWithSpecialStatus("unclaimed"),
      // Grab all incomplete lessons (regardless of date)
      asyncGetAllLessonsWithSpecialStatus("incomplete"),
      // Grab email and name for all teachers
      asyncGetAllTeachers(),
    ]);

    // Store all fetched lessons in variable
    setAllLessons(fetchedLessons);

    // On initial render, we want to display all fetched lessons
    setDisplayLessons(fetchedLessons);

    // Store unclaimed lessons in state for passing to UnclaimedLessons
    setUnclaimedLessons(fetchedUnclaimedLessons);

    // Store incomplete lessons in state for passing to UnclaimedLessons
    setIncompleteLessons(fetchedIncompleteLessons);

    // Store teacher data in state
    setAllTeachers(fetchedTeachers);

    // Set loading flag to false to indicate completion
    setLoading(false);
  };

  /*
   * USE EFFECT
   */

  // On component load, 
  // Set selected teachers to array from querystring or to default all
  // Set initial date range for datepicker from querystring or to two day range starting from current day
  useEffect(() => {
    // get selectedTeachers param from querystring and update filters if it exists
    const selectedTeachersFromQuery = querystring.get("selectedTeachers");

    // If the querystring has selectedTeacher param and the user is an admin, update state object
    if (selectedTeachersFromQuery) {
      if (userInfo?.isAdmin) {
        // If selectedTeachers value is "false" string, set state value to boolean false
        selectedTeachersFromQuery === "false" ? 
          setSelectedTeachers(false) :
        // Otherwise set selectedTeachers to array of teachers from querystring
          setSelectedTeachers(selectedTeachersFromQuery.split(","));
      }
      // If user is not an admin but selectedTeachers is in querystring, remove it
      else {
        querystring.delete("selectedTeachers");
        updatePath(querystring);
      }
    }

    setDateStateAndQuery(true);

    // Call the async function to update data
    fetchHomepageData();
  }, []);

  // When selected teachers change, or new lessons are fetched, filter the lessons
  useEffect(() => {
    // If teachers filter is false, display all lessons
    if (!selectedTeachers) {
      setDisplayLessons(allLessons);
    }
    else {
      // Iterate through all days in allLessons
      // Remember that allLessons is a date-keyed object, with each value being an array of lessons on that date (or false if no lessons are on that date)
      const newDisplayLessons = Object.keys(allLessons).reduce((accObject, date) => {
        // Return an object to continue using in reducer
        return {
          // Spread accumulator object
          ...accObject,
          // Add new entry. Key is date, value is lessons for that date filtered by teacher
          [date]: allLessons[date].filter(l => selectedTeachers.includes((l.instructor))),
        };
      }, {});

      // Remove keys from newDisplayLessons that have no lessons in them
      for (const key in newDisplayLessons) {
        if (newDisplayLessons[key].length === 0) {
          delete newDisplayLessons[key];
        }
      }

      // Set display lessons in state
      setDisplayLessons(newDisplayLessons);
    }
  }, [selectedTeachers, allLessons]);

  // When the querystring is updated, set the date states and
  // update the data displayed
  useEffect(() => {
    // Set the date range objects for start and end, but don't update querystring
    setDateStateAndQuery(false);

    // Call the async function to update data
    fetchHomepageData();
  }, [location.search]);

  /*
   * HELPER FUNCTIONS
   *
   */
  function updatePath(querystring) {
    // create path
    const pathToReplace = "/" + "?" + querystring.toString();

    // if history.location.search contains querystring, then we don't want to overwrite browser history
    if (history.location.search) {
      // forward on to properly filtered in querystring (and keep current url in history)
      history.push(pathToReplace);
    }
    else {
      // forward on to properly filtered in querystring 
      // and overwrite homepage without querystring from history
      history.replace(pathToReplace);
    }
  }

  // Handler for when user selects dates in datepicker
  const handleDatepickerChange = async (newDateRange) => {
    // Set new start and end dates objects in state, so they'll display in the picker
    // Convert date objects to NYC time zone when setting
    setLessonsStartDateObject(new Date(getNYCdate(newDateRange[0])));
    setLessonsEndDateObject(new Date(getNYCdate(newDateRange[1])));

    // Get difference between selected dates in milliseconds
    const millisDiff = newDateRange[1].getTime() - newDateRange[0].getTime();
    // Calculate the number of days between the new start and end dates
    // There are 86,400,000 milliseconds in a day
    const numDaysSelected = Math.floor(millisDiff / 86400000) + 1;

    // Check if the user has selected more than 14 days of lessons to display
    if (numDaysSelected > 14) {
      // Display a warning message
      setDatepickerWarning("Please select a maximum of 14 days to display");

      // Stop execution of function, so we're not fetching lessons
      return;
    }

    // Set loading flag to true
    setLoading(true);

    // If we get here, we have no more datepicker warning!
    setDatepickerWarning(false);

    // Extract start and end date strings from Date objects created by date picker
    const newStartDate = getNYCdate(newDateRange[0]);
    const newEndDate = getNYCdate(newDateRange[1]);

    // Also extract strings from date objects stored in state
    const oldStartDate = getNYCdate(lessonsStartDateObject);
    const oldEndDate = getNYCdate(lessonsEndDateObject);

    // If either date is different than previous, fetch new lessons
    if (newStartDate !== oldStartDate || newEndDate !== oldEndDate) {
      // Grab the new lessons
      const newLessons = await asyncGetLessonsByDate(newStartDate, newEndDate);

      // Put the lessons into state
      setAllLessons(newLessons);

      // Update querystring and browser history to reflect dates chosen
      updateQuerystringAndHistory("startDate", newStartDate, "endDate", newEndDate);
    }

    // Fetch is done (or didn't happen), so reset loading flag
    setLoading(false);
  };

  // When an admin changes the teacher for a lesson, update the lesson in state
  const updateLessonTeacher = (lessonDate, lessonId, newTeacher) => {
    // Get the index of the target lesson object in array at the indicated date
    const targetIndex = allLessons[lessonDate].findIndex(l => l.id === lessonId);

    // Make a copy of the target lesson object, with fields updated
    const updatedLesson = { 
      ...allLessons[lessonDate][targetIndex],
      instructor: newTeacher.preferred_name,
      instructor_email: newTeacher.email,
    };

    // Now get a shallow copy of the entire allLessons object
    const updatedAllLessons = { ...allLessons };

    // Update the array item in the new copy of all lessons
    updatedAllLessons[lessonDate][targetIndex] = updatedLesson;

    // Save the update in state
    setAllLessons(updatedAllLessons);
  };

  // Update the URL querystring and browser history with a given key and value
  const updateQuerystringAndHistory = (key, value, secondKey = false, secondValue = false) => {
    // set key and value pair in the querystring
    querystring.set(key, value);

    // If we have a second key-value pair, also update it
    // (This is used for start + end date so a chnaged date range doesn't push to history twice)
    if (secondKey) {
      querystring.set(secondKey, secondValue);
    }

    updatePath(querystring);
  };

  // Get just teacher preferred_name for passing to filter
  const teacherNames = allTeachers ? allTeachers.map(t => t.preferred_name) : [];

  // Render stuff!
  return (userInfo === null || loading)
    ? (
      <div>
        Loading...
      </div>
    )
    : (
      <div>
        <ClassFeedbackWidget />
        <div className="home-text">
          <UnclaimedLessons lessons={unclaimedLessons} />
          <IncompleteLessons lessons={incompleteLessons} />
          <div className="home-filters">
            <div className="home-datepicker">
              <DateRangePicker
                className="home-datepicker__picker"
                value={[lessonsStartDateObject, lessonsEndDateObject]}
                onChange={handleDatepickerChange}
                clearIcon={null}
              />
              {datepickerWarning
                  && (
                    <div className="home-datepicker__warning">
                      {datepickerWarning}
                    </div>
                  )
              }
            </div>
            {// Only show additional filters for Admin users
              userInfo.isAdmin
              && 
              <LessonsFilter 
                allTeachers={ teacherNames } 
                selectedTeachers={ selectedTeachers } 
                setSelectedTeachers={ setSelectedTeachers } 
                updateQuerystringAndHistory = { updateQuerystringAndHistory } />
            }
          </div>
          <div className={"home-filterValues " + (
          // if we have filters applied, show them in a warning box
            ((lessonsStartDateObject && lessonsEndDateObject) || selectedTeachers) ?
              "home-filterValues--show" : "home-filterValues--hide"
          )}>
            {(lessonsStartDateObject && lessonsEndDateObject && !datepickerWarning) && 
              (
                <>
                  {"Filtering by dates: " + lessonsStartDateObject.toDateString() + " to " + lessonsEndDateObject.toDateString()}
                  <br />
                  <br />
                </>
              )
            }
            {selectedTeachers && 
              ("Filtering by teachers: " + selectedTeachers.toString().replaceAll(",", ", "))
            }
          </div>
          <MyLessons
            userIsAdmin={userInfo.isAdmin}
            allTeachers={allTeachers}
            lessons={displayLessons}
            todayDate={todayDateString}
            updateLessonTeacher={updateLessonTeacher}
          />
        </div>
      </div>
    );
};

export default withRouter(Home);
