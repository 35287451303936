import React, { useState } from "react";

import { uploadVocabularyForModule } from "../../../services/datastore";

import "./moduleVocabulary.scss";

// Allows admins to upload vocabulary for a specified module
// Takes in moduleId and setVocabulary setter function as props
const UploadVocabulary = ({moduleId, setVocabulary}) => {

  // Holds the vocabulary text that that admin puts into the input box to be uploaded 
  const [vocabularyText, setVocabularyText] = useState("");

  // Manages whether the upload button is enabled or disabled depending on the error status
  const [uploadDisabled, setUploadDisabled] = useState(true);

  // Holds the error message admins get when they try to upload bad vocabulary
  const [errorMessage, setErrorMessage] = useState(null);

  // Holds the success message that admins get when they successfully upload vocabulary
  const [successMessage, setSuccessMessage] = useState(null);

  // Sets vocabulary variable in state to be what the admin just put in the textbox
  const handleTextChange = (event) => {
    setVocabularyText(event.target.value);

    // Reset error messages whenever the admin makes a change to the input
    setErrorMessage(null);
    setSuccessMessage(null);

    // Get the number of characters in the input
    const numberOfChars = event.target.value.length;

    // Checks if admin inputs 0 characters. If they do, don't let them upload and display an error message
    if (numberOfChars === 0) {
      setUploadDisabled(true);
      setErrorMessage("You cannot upload empty vocabulary");
    }

    // Checks if the user tried to upload more than 20,000 characters If they did, don't let them upload
    else if (numberOfChars > 20000) {
      setUploadDisabled(true);
      setErrorMessage("You cannot upload more than 20,000 characters");
    }

    // When user fixes the character count (ie. (0 < chars <= 20,000)), enable the button again
    else if (uploadDisabled) {
      setUploadDisabled(false);
    }
  };

  // Called when the admin clicks on Upload Vocabulary button
  // Sends the vocabulary to the backend
  // Hands the new vocabulry to the parent component
  const handleUploadVocabulary = async () => {
    const uploadedVocabulary = await uploadVocabularyForModule(moduleId, vocabularyText);

    // If the function was successful, set the vocabulary  
    // Otherwise display the error that occured and disalbe upload 
    if (uploadedVocabulary) {

      // Get rid of all the text in the text box
      setVocabularyText("");
       
      // Set the vocabulary in the parent component and show the success message
      setVocabulary(uploadedVocabulary);
      setSuccessMessage("Your vocabulary was successfully uploaded!");

      // Disable the upload button again
      setUploadDisabled(true);
    } 

    // Uploading a vocabulary returned an error, so disable the upload button and display error message
    else {
      setUploadDisabled(true);
      setErrorMessage("The vocabulary text you uploaded was not properly formatted. There should only be one delimiter per line. Also check that there are no empty lines anywhere (including at the very end)");
    } 
  };

  return (
    <div>
      <p className="instructions" >Uploading new vocabulary will erase all existing vocabulary for this module.</p>
      <p className="instructions">Delimiter = &%&</p>
      <p className="instructions">MAKE SURE THERE ARE NO EXTRA LINES AT THE END</p>
      <form>
        <textarea 
          value={vocabularyText}
          type="text"
          className="upload-vocabulary-text-area"
          onChange={handleTextChange}>
        </textarea>
      </form>
      <input
        type="submit"
        value="Upload Vocabulary" 
        className={uploadDisabled ? "upload-vocabulary-button-disabled" : "upload-vocabulary-button-enabled"}
        onClick={handleUploadVocabulary}
        disabled={uploadDisabled}
      >
      </input>
      {errorMessage ? <p className="error-message">{errorMessage}</p> : null}
      {successMessage ? <p className="success-message">{successMessage}</p> : null}
    </div>)
  ;

};

export default UploadVocabulary;
