import React from "react";

// Component to display a group of lessons
import LessonGroup from "../LessonGroup";

// Get date in NYC and format it
import { getNYCdate } from "../../../services/time";

// Render lessons on past dates
const PastLessons = ({ lessons, userIsAdmin }) => (
  <div className="pastLessons">
    {Object.keys(lessons).map((day) => (
      <div className="pastLessons__day" key={`futureLessons-${day}`}>
        <LessonGroup
          lessons={lessons[day]}
          header={(
            <>
              <h3>{getNYCdate(day, "weekday")}</h3>
              <div className="nyc-reminder">All times in NYC</div>
            </>
          )}
          userIsAdmin={userIsAdmin}
          disableTeacherChange
          showCompletionStatus
        />
      </div>
    ))}
  </div>
);

export default PastLessons;
