import React from "react";
import { Route, Redirect } from "react-router-dom";
import { signOut } from "firebase/auth";

import { firebaseAuth } from "./base";
import { getCurrentUser } from "./user";

const PrivateRoute = ({ component: RouteComponent, ...rest }) => {
  const userInfo = getCurrentUser();

  // If user doesn't exist or can't log in, don't render private route!
  if (!userInfo?.canLogIn) {
    // Sign the user out -- automatically redirects to signin page
    signOut(firebaseAuth);
  }

  return (
    <Route
      {...rest}
      render={(routeProps) => (
        userInfo
          ? <RouteComponent {...routeProps} />
          : <Redirect to="/signIn" />
      )}
    />
  );
};

export default PrivateRoute;
